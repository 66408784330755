<template>
  <div class="common-wap-input-container">
    <div class="icon-box" v-if="iconClass">
      <div class="Required" v-if="iRequired">*</div>
      <div class="icon" :class="iconClass"></div>
    </div>
    <input
      v-on="$root.inputEvents"
      :type="viewPassword ? 'text' : htmlType"
      class="input"
      :autofocus="autoFocus"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div class="icon-box" v-if="htmlType == 'password'" @click="toggleViewPassword">
      <div class="view" :class="{close: viewPassword}"></div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';

@Options({
  props: {
    htmlType: String,
    placeholder: String,
    iconClass: String,
    autocomplete: String,
    modelValue: String,
    autoFocus: Boolean,
    iRequired: Boolean
  },
  emits: {
    'update:modelValue': String
  }
})
export default class Input extends Vue {
  viewPassword = false;
  val = '';
  toggleViewPassword() {
    this.viewPassword = !this.viewPassword;
  }
}
</script>

<style lang="scss" scoped>
.common-wap-input-container {
  width: 100%;
  height: 1.6rem;
  display: flex;
  align-items: center;
  background: #f6f7f8;
  border-radius: 8px;
  .icon-box {
    width: 1.6rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 0.64rem;
    height: 0.64rem;
    &.username {
      background-image: url('../../../assets/wap/user.png');
    }
    &.password {
      background-image: url('../../../assets/wap/lock.png');
    }
    &.realname {
      background-image: url('../../../assets/wap/register/realname.svg');
    }
    &.email {
      background-image: url('../../../assets/wap/register/email.svg');
    }
    &.phone {
      background-image: url('../../../assets/wap/register/phone.svg');
    }
    &.QQ {
      background-image: url('../../../assets/wap/register/QQ.svg');
    }
    &.skype {
      background-image: url('../../../assets/wap/register/skype.svg');
    }
    &.wechat {
      background-image: url('../../../assets/wap/register/wechat.svg');
    }
    &.zalo {
      background-image: url('../../../assets/wap/register/zalo.svg');
    }
  }
  .input {
    display: flex;
    flex: 1;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.56rem;
    border: none;
    background-color: transparent;
    outline: none;
    margin-right: 0.32rem;
    &::placeholder {
      color: #cccccc;
    }
  }
  .view {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('../../../assets/wap/view.png');
    width: 0.64rem;
    height: 0.64rem;
    @extend .clickAction;
    &.close {
      background-image: url('../../../assets/wap/close-eye.png');
    }
  }
}
.Required {
  color: red;
}
</style>
