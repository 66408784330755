import {basic} from '@/config';
import doFetch from '../../fetch';
import fetch3 from '../../fetch3';

/**
 * get today overview
 */
const getTodayOverview = async <T>() =>
  doFetch<T>(`/api/agent/v1/Users/today_overview`, 'POST', undefined);

/**
 * get finacial overview
 */
const getFinacialOverview = async <T>(data: any) =>
  doFetch<T>(`/api/agent/v1/Finance/financial_overview`, 'POST', data);

/**
 * get finacial reward
 */
const getFinacialReward = async <T>(data: any) =>
  doFetch<T>(`/api/agent/v1/Finance/get_reward_detail`, 'POST', data);

/**
 *
 * 推广管理添加用户
 * @param data
 */
const setMemer = async <T>(data: any) =>
  doFetch<T>(`/api/acc/front/v1/Agent/create_user`, 'POST', data);

/**
 *
 * 链接开户
 * @param data
 */
const linkMember = async <T>() =>
  await doFetch<T>(`/api/agent/v1/Users/subdomain_list`, 'POST', {});

/**
 *
 * 產生代理域名
 * @param data
 */
const addSubdomain = async <T>(data: any) =>
  await doFetch<T>(`/api/agent/v1/Users/add_subdomain`, 'POST', data);

/**
 *
 * 編輯代理域名備註
 * @param data
 */
const editSubdomain = async <T>(data: any) =>
  await doFetch<T>(`/api/agent/v1/Users/edit_subdomain_note`, 'POST', data);

/**
 * 取得活動列表
 * @param data
 * @returns promise
 */
const activityList = async <T>() =>
  await doFetch<T>(`/api/agent/v1/Activity_define/get_activity_by_category`, 'POST', {});

/**
 *
 * 直播地址
 * @param data
 */
const liveLink = async <T>() =>
  await fetch3<T>(`${basic['918Url']}/api/match/getMatchList`, 'POST-JSON', {
    size: 100,
    page: 1,
    version: '1.0.0'
  });

/**
 *
 * 新直播地址
 * @param data
 */
const newLiveLink = async <T>(data: object) =>
  await fetch3<T>(`${basic['918Url']}/api/bti/getLeiSuMatchList`, 'POST-JSON', {
    ...data,
    version: '1.0.0'
  });

// 上传图片
const uploadLivePic = async <T>(data: object) =>
  await fetch3<T>(`${basic['918Url']}/api/bti/setTencentVideo`, 'POST-JSON', data);

// 结束直播
const endLive = async <T>(data: object) =>
  await fetch3<T>(`${basic['918Url']}/api/bti/delTencentVideo`, 'POST-JSON', data);

/**
 *
 * bti直播地址
 * @param data
 */
const btiLiveLink = async <T>(data: object) =>
  await fetch3<T>(`${basic['918Url']}/api/bti/getFootballMatchList`, 'POST-JSON', {
    ...data,
    version: '1.0.0'
  });

export default {
  getTodayOverview,
  getFinacialOverview,
  getFinacialReward,
  setMemer,
  linkMember,
  addSubdomain,
  editSubdomain,
  activityList,
  liveLink,
  btiLiveLink,
  newLiveLink,
  uploadLivePic,
  endLive
};
