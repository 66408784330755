export interface IMatchType {
  code: string;
  name: string;
  children: Array<IMatchType>;
}
export interface IMatchResult {
  current_page: number;
  data: IMatchList[];
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}
export interface IMatchList {
  actual: number;
  away: string;
  away_code: string;
  away_name: string;
  code: string;
  estimate: number;
  has_animation: number;
  has_video: number;
  home: string;
  home_code: string;
  home_name: string;
  league_code: string;
  league_name: string;
  stats: IMatchStats[];
  status: number;
}
interface IMatchStats {
  away: number;
  home: number;
  type: number;
}

export interface IMatchDetail {
  actual: number;
  animation_url: string;
  away: string;
  away_domain: string;
  away_formation: string;
  away_icon: string;
  away_lineup: string;
  away_score: string;
  code: string;
  estimate: number;
  group: string;
  has_animation: number;
  has_video: number;
  home: string;
  home_domain: string;
  home_formation: string;
  home_icon: string;
  home_lineup: string;
  home_score: string;
  league: string;
  round: string;
  season: string;
  status: number;
  video_url: string;
  event: Array<IDetailEvent>;
  stats: Array<IDetailStats>;
}
interface IDetailEvent {
  data: string;
  position: number;
  time: string;
  type: number;
}
interface IDetailStats {
  away: number;
  home: number;
  type: number;
}

export class MatchStatus {
  static matchStats() {
    //弃用，请移至多语言文件
    return {
      1: '进球',
      2: '角球',
      3: '黄牌',
      4: '红牌',
      5: '界外球',
      6: '任意球',
      7: '球门球',
      8: '点球',
      9: '换人',
      10: '比赛开始',
      11: '中场',
      12: '结束',
      13: '半场比分',
      15: '两黄变红',
      16: '点球未进',
      17: '乌龙球',
      18: '助攻',
      19: '伤停补时',
      21: '射正',
      22: '射偏',
      23: '进攻',
      24: '危险进攻',
      25: '控球率(%)',
      26: '加时赛结束',
      27: '点球大战结束',
      28: 'VAR(视频助理裁判)'
    };
  }
  static matchStatus(t: Function) {
    return {
      1: t('match.status1'), //"上半场",
      2: t('match.status2'), //"中场",
      3: t('match.status3'), //"下半场",
      4: t('match.status4'), //"加时赛",
      5: t('match.status5'), //"加时赛(弃用)",
      6: t('match.status6'), //"点球决战",
      7: t('match.status7'), //"未开赛",
      8: t('match.status8'), //"推迟",
      9: t('match.status9'), //"中断",
      10: t('match.status10'), //"腰斩",
      11: t('match.status11'), //"取消",
      12: t('match.status12'), //"待定",
      13: t('match.status13'), //"比赛异常",
      14: t('match.status14') //"完场"
    };
  }
  static matchInitDetail() {
    return {
      actual: 0,
      animation_url: '',
      away: '',
      away_domain: '',
      away_formation: '',
      away_icon: '',
      away_lineup: '',
      away_score: '',
      code: '',
      estimate: 0,
      group: '',
      has_animation: 0,
      has_video: 0,
      home: '',
      home_domain: '',
      home_formation: '',
      home_icon: '',
      home_lineup: '',
      home_score: '',
      league: '',
      round: '',
      season: '',
      status: 0,
      video_url: '',
      event: [],
      stats: []
    } as IMatchDetail;
  }
}
