<template>
  <el-card class="detail">
    <el-page-header @back="$emit('update:modelValue', false)" :content="$t(navTitle)" title="" />
    <slot v-if="isSlot"></slot>
    <template v-else>
      <el-skeleton :rows="4" animated v-if="!loaded" />
      <div class="detail-content" v-else>
        <h6>{{ title }}</h6>
        <p class="time">{{ time }}</p>
        <div class="content" v-html="content"></div>
        <div v-if="cover && cover.length > 0" class="coverList">
          <img :src="v" alt="" v-for="v in cover" :key="v" />
        </div>
      </div>
    </template>
  </el-card>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';

@Options({
  props: {
    title: {
      type: String
    },
    time: {
      type: String
    },
    content: {
      type: String
    },
    navTitle: {
      type: String,
      default: 'navDetail'
    },
    loaded: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Boolean
    },
    cover: {
      type: Array,
      default: []
    }
  },
  computed: {
    isSlot() {
      return !!this.$slots.default;
    }
  }
})
export default class Index extends Vue {}
</script>
<style lang="scss" scoped>
@import '../../../common/styles/element.scss';
.detail {
  :deep(.el-page-header__content) {
    font-size: 14px;
  }
}
.detail-content {
  h6 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 10px;
  }
  .time {
    color: #999;
    font-size: 12px;
    text-align: center;
  }
  .content {
    font-size: 14px;
    line-height: 1.5;
    text-indent: 2em;
    margin-top: 25px;
  }
}
.time-line {
  padding: 0 5px;
}
.coverList {
  img {
    max-width: 100%;
    width: 100%;
    object-fit: contain;
  }
}
</style>
