import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router';
import Home from '@/web/layout/index.vue';
import Login from '@/web/common/login/login.vue';
import {App, Server} from '@/services';
import {IUserLoginResult} from '@/model/user';
import homeChildren from '@/web/layout/route';
import {loadLanguageAsync} from '@/common/wap/locale/language';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: homeChildren
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      noNeedLogin: true
    },
    component: Login
  },
  {
    path: '/forget',
    name: 'forget',
    meta: {
      noNeedLogin: true
    },
    component: () => import('@/web/common/login/forget.vue')
  },
  {
    path: '/register/:linkId',
    name: 'registerId',
    props: true,
    component: () => import(/* webpackChunkName: "register" */ '@/web/common/login/register.vue'),
    meta: {
      noNeedLogin: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      noNeedLogin: true
    },
    component: () => import('@/web/common/login/register.vue')
  },
  {
    path: '/securityCenter',
    name: 'SecurityCenter',
    component: () =>
      import(/* webpackChunkName: "SecurityCenter" */ '@/web/operation/security/index.vue')
  },
  {
    path: '/securityCenter/information',
    name: 'PersonalInformation',
    component: () =>
      import(
        /* webpackChunkName: "PersonalInformation" */ '@/web/operation/security/information/index.vue'
      )
  },
  {
    path: '/securityCenter/phoneVerification',
    name: 'PhoneVerification',
    component: () =>
      import(/* webpackChunkName: "PhoneVerification" */ '@/web/operation/security/phone/index.vue')
  },
  {
    path: '/securityCenter/bankcard',
    name: 'BankCard',
    component: () =>
      import(/* webpackChunkName: "BankCard" */ '@/web/operation/security/bankcard/index.vue')
  },
  {
    path: '/securityCenter/bankcard/add',
    name: 'AddBankCard',
    component: () =>
      import(
        /* webpackChunkName: "AddBankCard" */ '@/wap/operation/security/bankcard/add/index.vue'
      )
  },
  {
    path: '/securityCenter/password',
    name: 'Password',
    component: () =>
      import(/* webpackChunkName: "Password" */ '@/wap/operation/security/password/index.vue')
  }
];
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

const getParams = async () => {
  let params: {
    token?: string;
    lang?: string;
  } = {};
  if (location.search.substr(1)) {
    const searchs = location.search.substr(1).split(/&|=/);
    searchs.forEach((item, index) => {
      if (!(index % 2)) {
        params = {
          [item]: searchs[index + 1]
        };
      }
    });
    // query 含例外名稱不做 config
    const token = params.token;
    localStorage.setItem('token', token || '');
    if (params['lang']) {
      localStorage.setItem('language', params.lang);
    }
    // query 含例外名稱不做 config
  }

  if (location.hash) {
    const searchs = location.hash.substr(2).split(/\?|&|=/);
    searchs.shift();
    searchs.forEach((item, index) => {
      if (!(index % 2)) {
        params = {
          [item]: searchs[index + 1]
        };
      }
    });
    const token = params.token;
    localStorage.setItem('token', token || '');
    if (params['lang']) {
      localStorage.setItem('language', params.lang);
    }
  }
  return params;
};

const changeLang = async (params: any) => {
  const lang =
    params['lang'] || localStorage.getItem('language') || window.defaultLangCode || 'zh-CN';
  const obj = new Map([
    ['zh-CN', 'zh'],
    ['en', 'en'],
    ['vn', 'vn'],
    ['th', 'th']
  ]);

  await loadLanguageAsync(obj.get(lang) || 'zh');
};
router.beforeEach(async (to, from, next) => {
  // if (to.name !== 'Login' && !App.getIsLogedIn()) next({ name: 'Login' });
  // else next();
  const needLogin = !to.meta || !to.meta.noNeedLogin;
  const params = await getParams();
  const token = params['token'] || '';
  console.log('token: ', token);
  if (token) {
    localStorage.setItem('token', token);
    console.log(Server.Web.Common, 'Server');
    const res = await Server.Web.Common.getUserinfo<IUserLoginResult>({});
    console.log('res: ', res);
    if (!(res instanceof Error)) {
      const userinfoStr = localStorage.getItem('user_info');
      const userInfo = userinfoStr && JSON.parse(userinfoStr);
      localStorage.setItem('user_info', JSON.stringify({...userInfo, ...res.list[0]}));
    }
    App.updateGlobal();
  }
  await changeLang(params);

  if (needLogin && !App.getIsLogedIn()) {
    next({name: 'Login'});
  } else if (to.path === '/') {
    next({name: 'personalInformation'});
  } else {
    next();
  }
});

export default router;
