
import {Options, Vue} from 'vue-class-component';
import {Watch} from 'vue-property-decorator';

@Options({
  props: {
    position: {
      type: String,
      default: 'center'
    },
    show: {
      type: Boolean
    }
  }
})
export default class Popup extends Vue {
  ani = false;

  @Watch('show')
  onChildChanged(val: boolean) {
    setTimeout(() => {
      this.ani = val;
      const status = val ? 'hidden' : 'auto';
      document.querySelector('body')?.setAttribute('style', `overflow:${status}`);
    }, 0);
  }

  unmounted() {
    document.querySelector('body')?.removeAttribute('style');
  }
}
