<template>
  <el-dropdown class="">
    <div class="lang-style flex flex-center" @click="showLangActionSheet">
      <div class="flag" :class="lang"></div>
      <div class="name">{{ name }}</div>
      <div class="arrow"></div>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item v-for="(item, index) in actions" :key="index" @click="onSelect(item)">
          <div class="flex flex-center">
            <div class="flag" :class="item.key"></div>
            {{ item.name }}
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {ActionSheet} from 'vant';
import {i18n} from '@/common/wap';
import {loadLanguageAsync} from '@/common/web/locale/language';
import locale from 'element-plus/lib/locale';
import eventBus from '@/web/eventBus';

@Options({
  components: {
    ActionSheet,
    i18n
  },
  props: {
    onToggle: Function
  }
})
export default class LoginPanel extends Vue {
  showLang = false;
  actions: {
    name: string;
    key: string;
    className?: string;
  }[] = [];
  name = '简体中文';
  lang = i18n.global.locale;
  async mounted() {
    const handleLangs = new Map([
      ['zh_cn', 'zh-CN'],
      ['vn', 'vn'],
      ['en_us', 'en'],
      ['th_th', 'th']
    ]);
    // eslint-disable-next-line
    if (window.hasOwnProperty('LANGLIST') && Array.isArray(window.LANGLIST)) {
      // eslint-disable-next-line
      window.LANGLIST.map((lang: {text: string; key: string}) => {
        const key = handleLangs.get(lang.key) || 'zh-CN';
        const item: {
          name: string;
          key: string;
          className: string;
        } = {
          name: lang.text,
          key,
          className: key === this.lang ? 'current-ajskfjaslkfj' : ''
        };
        this.actions.push(item);
      });
    }
    this.lang = localStorage.getItem('language') || window.defaultLangCode;
    const obj = new Map([
      ['zh_cn', 'zh-CN'],
      ['zh', 'zh-CN'],
      ['en', 'en'],
      ['vn', 'vn'],
      ['en_us', 'en'],
      ['th_th', 'th'],
      ['th', 'th']
    ]);
    this.name = this.actions.filter(v => v.key === (obj.get(this.lang) || this.lang))[0]
      ? this.actions.filter(v => v.key === (obj.get(this.lang) || this.lang))[0].name
      : '简体';

    await this.onSelect({key: obj.get(this.lang) || this.lang, name: this.name});
  }
  showLangActionSheet() {
    this.actions = this.actions.map(x => {
      if (x.key === this.lang) {
        x.className = 'current-ajskfjaslkfj';
      } else {
        x.className = '';
      }
      return x;
    });
    this.showLang = true;
  }

  async onSelect(item: {name: string; key: string}) {
    console.log('item: ', item);
    this.showLang = false;
    await loadLanguageAsync(item.key);
    const elLangMap = new Map([
      ['zh-CN', 'zh-cn'],
      ['en', 'en'],
      ['vn', 'vi'],
      ['th', 'th']
    ]);
    const lang = await import(
      `element-plus/lib/locale/lang/${elLangMap.get(item.key) || 'zh-cn'}.js`
    );
    console.log('lang: ', lang);
    locale.use(lang.default);
    i18n.global.locale = item.key;
    this.lang = item.key;
    this.name = item.name;
    localStorage.setItem('language', item.key);
    eventBus.bus$emit('langChange', item.key);
  }
}
</script>
<style lang="scss" scope>
// .u_mw--105{
//   min-width: 105px;
// }
.lang-style {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  min-width: 105px;
  @extend .clickAction;
  .name {
    height: 0.56rem;
    color: #999999;
    line-height: 0.56rem;
    font-size: 0.96rem;
  }
  // .arrow {
  //   width: 0.48rem;
  //   height: 0.48rem;
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   background-position: center;
  //   background-image: url('gray-arrow-down.png');
  // }
}
.flag {
  width: 1.6rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 0.16rem;
}
.en {
  background-image: url('US.svg');
}
.zh-CN {
  background-image: url('CH.svg');
}
.vn {
  background-image: url('VN.svg');
}
.th {
  background-image: url('TH.png');
}
.current-ajskfjaslkfj {
  color: $bg;
}
</style>
