export interface IPromotedForm {
  is_member: string | number;
  user_account: string;
  password: string;
}
export class Promoted {
  static validateInput = (rule: any, value: string, callback: Function) => {
    const regu = /^\w{6,12}$/;
    const re = new RegExp(regu);
    if (!re.test(value)) {
      callback(new Error('6~12不含特殊字符'));
    } else {
      callback();
    }
  };
}
