import {basic} from '@/config';
import fetch3 from '../../fetch3';

// 设置直播
const setWebSiteInfo = async <T>(data: object) =>
  await fetch3<T>(`${basic['998Url']}/api/agentWeb/setWebSiteInfo`, 'POST-JSON', data);

// 获取直播信息
const getWebSiteInfoByAgentId = async <T>(data: object) =>
  await fetch3<T>(`${basic['998Url']}/api/agentWeb/getWebSiteInfoByAgentId`, 'POST-JSON', data);

// 获取直播信息
const getAllImageList = async <T>() =>
  await fetch3<T>(`${basic['mainlandUrl']}/api/getAllImageList`, 'POST-JSON', {
    type_id: 170
  });

export default {
  setWebSiteInfo,
  getWebSiteInfoByAgentId,
  getAllImageList
};
