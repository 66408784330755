<template>
  <el-menu
    :uniqueOpened="true"
    :default-active="`${menuActive + 1}`"
    class="el-menu-vertical-demo menu"
    @open="handleOpen"
    @close="handleClose"
    background-color="#1f55f0"
    text-color="#f1f1f1"
    active-text-color="#fff"
  >
    <div>
      <el-dialog
        v-model="openAccountTakeCash"
        :close="closebtn"
        :show-close="false"
        custom-class="takecash"
        :append-to-body="true"
        :style="{
          borderRadio: '10px'
        }"
      >
        <template #title>
          <div class="dialog-content" v-if="openAccountTakeCash">
            <div class="dialog_head">
              <div class="u_m--l50 u_m--t20">{{ $t('btn_withdraw') }}</div>
              <button
                type="button"
                aria-label="Close"
                class="el-dialog__headerbtn header_close u_p--r50 u_m--t20"
                @click="closebtn"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g id="icon/24/X" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g
                      id="编组"
                      mask="url(#mask-2)"
                      stroke="#333333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    >
                      <g
                        transform="translate(11.500000, 12.500000) rotate(-315.000000) translate(-11.500000, -12.500000) translate(0.000000, 1.000000)"
                      >
                        <line x1="0" y1="11.5" x2="23" y2="11.5" id="路径-18"></line>
                        <line x1="11.5" y1="0" x2="11.5" y2="23" id="路径-19"></line>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </template>
        <AccountTakeCash
          @close="openAccountTakeCash = false"
          :passWallet="wallet"
          class="acc_min_width"
          v-if="openAccountTakeCash"
        />
      </el-dialog>
      <div class="flex flex-center flex-justify-center header">
        <span class="main-menu-header__name text-day">{{ userInfo.userAccount }}</span>
      </div>
      <div class="flex flex-center flex-row flex-justify-around menu-tooptips">
        <!-- phone -->
        <el-tooltip class="tooltip-item" effect="dark" content="" placement="bottom-end">
          <template #content>
            <prompt
              @click="!userInfo.phoneNumber.verification ? goToLink('UserInfo') : () => {}"
              :name="
                !userInfo.phoneNumber.verification
                  ? 'ui_none_binding_phone_v2'
                  : 'ui_binding_phone_v2'
              "
            />
          </template>
          <el-button
            ><div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                width="10"
                viewBox="0 0 12 19"
                id="icon-icon_phone_v2"
              >
                <path
                  d="M10.2 0c.99 0 1.8.8 1.8 1.781V17.22c0 .98-.81 1.781-1.8 1.781H1.8C.81 19 0 18.2 0 17.219V1.78C0 .801.81 0 1.8 0h8.4zM4.303.791C4.135.791 4 .97 4 1.187c0 .218.136.395.304.395h3.394c.168 0 .304-.177.304-.395 0-.218-.136-.396-.304-.396H4.303zM6 18.21c.663 0 1.2-.532 1.2-1.187 0-.657-.537-1.187-1.2-1.187-.663 0-1.2.532-1.2 1.187S5.337 18.21 6 18.21zM1.55 14.25h9V2.375h-9v11.876z"
                ></path>
              </svg>
              <div class="main-menu-header__service_tip" v-if="userInfo.phoneNumber.verification">
                <svg
                  width="12px"
                  height="12px"
                  viewBox="0 0 12 12"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="icon/12/展开备份-2"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <circle
                      id="椭圆形"
                      stroke="#1F55F0"
                      fill="#FFCF65"
                      cx="6"
                      cy="6"
                      r="5.5"
                    ></circle>
                    <polyline
                      id="路径"
                      stroke="#1F55F0"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      transform="translate(6.128427, 4.828427) rotate(-315.000000) translate(-6.128427, -4.828427) "
                      points="7.62842712 2.32842712 7.62842712 7.32842712 4.62842712 7.32842712"
                    ></polyline>
                  </g>
                </svg>
              </div>
            </div>
          </el-button>
        </el-tooltip>
        <!-- mail -->
        <el-tooltip class="tooltip-item" effect="dark" content="" placement="bottom">
          <template #content>
            <prompt
              @click="!userInfo.email.verification ? goToLink('UserInfo') : () => {}"
              :name="
                !userInfo.email.verification ? 'ui_none_binding_email_v2' : 'ui_binding_email_v2'
              "
            />
          </template>
          <el-button>
            <div class="fig">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                viewBox="0 0 21 16"
                id="icon-icon_email_v2"
              >
                <path
                  d="M2.876.003h15.898c1.086 0 1.974.871 1.987 1.944l-9.93 5.494L.898 1.952c.01-1.076.89-1.95 1.978-1.95zM.898 4.055l-.009 9.72c0 1.082.895 1.967 1.987 1.967h15.898c1.093 0 1.987-.885 1.987-1.968V4.05l-9.698 5.239a.495.495 0 0 1-.476 0L.898 4.055z"
                ></path>
              </svg>
            </div>
            <div class="main-menu-header__service_tip" v-if="userInfo.email.verification">
              <svg
                width="12px"
                height="12px"
                viewBox="0 0 12 12"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="icon/12/展开备份-2"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <circle
                    id="椭圆形"
                    stroke="#1F55F0"
                    fill="#FFCF65"
                    cx="6"
                    cy="6"
                    r="5.5"
                  ></circle>
                  <polyline
                    id="路径"
                    stroke="#1F55F0"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    transform="translate(6.128427, 4.828427) rotate(-315.000000) translate(-6.128427, -4.828427) "
                    points="7.62842712 2.32842712 7.62842712 7.32842712 4.62842712 7.32842712"
                  ></polyline>
                </g>
              </svg>
            </div>
          </el-button>
        </el-tooltip>
        <!-- card -->
        <el-tooltip class="tooltip-item" effect="dark" content="" placement="bottom">
          <template #content>
            <prompt
              @click="cardList.length < 1 ? goToLink('AddCard') : () => {}"
              :name="cardList.length < 1 ? 'ui_none_binding_card_v2' : 'ui_binding_card_v2'"
            />
          </template>
          <el-button>
            <div class="fig">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                viewBox="0 0 20 16"
                id="icon-icon_wallet_v2"
              >
                <g>
                  <path
                    d="M10.47 7.771c.115-1.769 1.637-3.104 3.376-3.104h2.5c.174 0 .34-.07.462-.196A.673.673 0 0 0 17 4V1.333C17 .6 16.412 0 15.692 0H1.308C.588 0 0 .6 0 1.333v13.334C0 15.4.588 16 1.308 16h14.384c.72 0 1.308-.6 1.308-1.333V12a.673.673 0 0 0-.192-.471.648.648 0 0 0-.462-.196h-2.615c-1.877 0-3.39-1.62-3.262-3.562z"
                  ></path>
                  <path d="M14 6a2 2 0 1 0 0 4h4a2 2 0 1 0 0-4h-4z"></path>
                </g>
              </svg>
              <div class="main-menu-header__service_tip" v-if="cardList.length >= 1">
                <svg
                  width="12px"
                  height="12px"
                  viewBox="0 0 12 12"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="icon/12/展开备份-2"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <circle
                      id="椭圆形"
                      stroke="#1F55F0"
                      fill="#FFCF65"
                      cx="6"
                      cy="6"
                      r="5.5"
                    ></circle>
                    <polyline
                      id="路径"
                      stroke="#1F55F0"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      transform="translate(6.128427, 4.828427) rotate(-315.000000) translate(-6.128427, -4.828427) "
                      points="7.62842712 2.32842712 7.62842712 7.32842712 4.62842712 7.32842712"
                    ></polyline>
                  </g>
                </svg>
              </div>
            </div>
          </el-button>
        </el-tooltip>
        <!-- realname -->
        <el-tooltip class="tooltip-item" effect="dark" placement="bottom-start">
          <template #content>
            <prompt
              @click="userInfo.realName ? goToLink('UserInfo') : () => {}"
              :name="userInfo.realName ? 'ui_binding_name_v2' : 'ui_none_binding_name_v2'"
            />
          </template>
          <el-button>
            <div class="fig">
              <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="12" viewBox="0 0 16 19">
                <path
                  d="M7.804 0c2.587 0 4.683 2.127 4.683 4.75S10.391 9.5 7.804 9.5c-2.586 0-4.682-2.127-4.682-4.75C3.122 2.126 5.219 0 7.804 0zm0 0c2.587 0 4.683 2.127 4.683 4.75S10.391 9.5 7.804 9.5c-2.586 0-4.682-2.127-4.682-4.75C3.122 2.126 5.219 0 7.804 0zM6.05 11.083H9.95c3.341 0 6.049 2.747 6.049 6.135v.396C16 18.949 13.292 19 9.951 19H6.05C2.708 19 0 18.998 0 17.614v-.396c0-3.388 2.708-6.135 6.049-6.135z"
                ></path>
              </svg>
              <div class="main-menu-header__service_tip" v-if="userInfo.realName">
                <svg
                  width="12px"
                  height="12px"
                  viewBox="0 0 12 12"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="icon/12/展开备份-2"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <circle
                      id="椭圆形"
                      stroke="#1F55F0"
                      fill="#FFCF65"
                      cx="6"
                      cy="6"
                      r="5.5"
                    ></circle>
                    <polyline
                      id="路径"
                      stroke="#1F55F0"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      transform="translate(6.128427, 4.828427) rotate(-315.000000) translate(-6.128427, -4.828427) "
                      points="7.62842712 2.32842712 7.62842712 7.32842712 4.62842712 7.32842712"
                    ></polyline>
                  </g>
                </svg>
              </div>
            </div>
          </el-button>
        </el-tooltip>
      </div>
      <!-- 资产区 -->
      <div class="main-menu-header__bottom">
        <div class="main-menu-header__balance">
          <span class="main-menu-header__balance-title u_c--pointer">
            {{ filterConlon($t('ui_total_amount')) }}
          </span>
          <div class="main-menu-header__balance-main">
            <span class="main-menu-header__money u_c--pointer">
              <Wallet @finish-wallet="handleWallet" :isFetch="isFetch">
                <count-to
                  :duration="2000"
                  ref="counts"
                  :startVal="0 === computedAmount ? 100 : 0"
                  :endVal="computedAmount"
                  :decimals="2"
                ></count-to>
              </Wallet>
            </span>
            <div
              class="u_c--pointer"
              :class="[rotate ? 'go' : 'back']"
              @click.stop="reloadTotalAmount"
            >
              <i class="el-icon-refresh" />
            </div>
          </div>
        </div>
      </div>
      <div class="btn-controller">
        <div class="flex flex-row flex-justify-around border-white">
          <!-- <router-link class="btnbox3 hairline" :to="{name: 'Recharge'}">
            <span class="deposit" />
            <span class="btn_text"> {{ $t('btn_dep') }}</span>
          </router-link> -->
          <div class="btnbox3 hairline" @click="openAccountTakeCash = true">
            <!-- :class="{ actived: AccountTakeCash}" -->
            <span class="withdraw" />
            <span class="btn_text u_c--pointer">{{ $t('btn_withdraw') }}</span>
          </div>
        </div>
      </div>
    </div>
    <template v-for="(v, i) in side" :key="v.meta.name">
      <el-menu-item :index="`${i + 1}`" @click="goToLink(v.name)" v-if="!v.meta.hide">
        <template #title>
          <div
            class="flex flex-row flex-center box"
            :class="nowLang === 'zh-CN' ? 'u_flex--center' : 'u_flex--left'"
          >
            <div class="circle">
              <img class="icon" :src="getIcon(v.name)" @error="onError($event)" />
            </div>
            <span class="u_t-transfrom-cap ">{{ upperCase($t(v.meta.name)) }}</span>
            <div v-show="v.meta.info_red">
              <em v-show="unread" class="notice-card__icon" />
            </div>
          </div>
          <div class="menu_border"></div>
        </template>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import leftSide from '@/web/layout/route';
import {Watch} from 'vue-property-decorator';
import {App, Format} from '@/services';
import Prompt from './prompt.vue';
import {ElButton, ElDialog} from 'element-plus';
import {userCard} from '@/services/server/web/bank';
import {ICard, ICardResult} from '@/model/bank';
import {filterConlon} from '@/web/plugins/changeCase';
import Wallet from '@/common/web/wallet/index.vue';
import {IWallet} from '@/model/transfer';
import countTo from '@/common/web/countTo/index.vue';
import eventBus from '@/web/eventBus';
import AccountTakeCash from '@/web/operation/takeCash/index.vue';

interface LoginParamsType {
  username: '';
  password: '';
}
@Options({
  components: {Prompt, ElButton, Wallet, AccountTakeCash, ElDialog, countTo}
})
export default class Left extends Vue {
  userInfo = App.getUserinfo();
  side = leftSide;
  menuActive = 0;
  nowLang = localStorage.getItem('language') || 'th';
  cardList: ICard[] = [];
  reloadMenu = true;
  rotate = false;
  isFetch = false;
  wallet: IWallet = {
    centerWallet: '',
    gameBalance: '',
    lockBalance: '',
    savingBalance: '',
    sportBalance: '',
    totalAmount: ''
  };
  openAccountTakeCash = false;
  unread = 0;
  defaultActive = '1';
  @Watch('$route')
  changeRouter() {
    this.fixSide();
  }
  get computedAmount() {
    return (
      Math.floor(
        (+this.wallet.totalAmount.replace(/,/g, '') +
          +this.wallet.savingBalance.replace(/,/g, '') +
          +this.wallet.lockBalance.replace(/,/g, '')) *
          100
      ) / 100
    );
  }
  created() {
    eventBus.bus$on('publish-unread', (val: number) => {
      this.unread = val;
    });
    eventBus.bus$on('langChange', () => {
      this.nowLang = localStorage.getItem('language') || 'th';
    });
    // 储存一下用户名  只有这些用户我们才显示任务列表页面
    const names = ['dnick001', 'ook0909', 'ProdAgent30', 'Sportpt01', 'Sportpt02'];
    const filterSide = leftSide.filter(
      item =>
        (item.name === 'tasklist' && names.find(_item => _item === this.userInfo?.userAccount)) ||
        item.name !== 'tasklist'
    );
    this.side = filterSide;
    this.fixSide();
  }

  beforeDestroy() {
    eventBus.bus$off('langChange');
    eventBus.bus$off('publish-unread');
  }

  async mounted() {
    const res = await userCard.read<ICardResult>({});
    console.log('res: ', res);
    if (res instanceof Error) {
      return res;
    } else {
      this.cardList = res.list;
    }
  }
  filterConlon = filterConlon;
  formatNumber = Format.formatNumber;
  upperCase = Format.upperCase;
  fixSide() {
    this.menuActive = this.side.findIndex(v => {
      return v.name === this.checkMenu();
    });
  }
  goToLink(name: string) {
    console.log('name: ', name);
    console.log(this.$router, this.$route);
    this.$router.push({name});
  }
  handleOpen() {
    console.log(123);
  }
  handleClose() {
    console.log(123);
  }
  reloadTotalAmount() {
    this.$nextTick(() => {
      eventBus.bus$emit('fetchWallet', true);
      this.isFetch = true;
    });
    this.rotate = !this.rotate;
  }
  handleWallet(wallet: IWallet) {
    this.wallet = wallet;
    this.isFetch = false;
  }
  onError(evt: any) {
    console.log({evt});
    const el = evt.target;
    el.style.display = 'none';
  }
  getIcon(name: string) {
    try {
      return require(`../../assets/web/image/usermenu/${name}.svg`);
    } catch (err) {
      return 'javascript:void(0);';
    }
  }
  activeRouter(menu: {name: string}) {
    this.$router.push({name: menu.name});
  }
  checkMenu() {
    // return this.$route.matched.find(val => {
    let _name = this.$route.name;
    switch (this.$route.name) {
      case 'CreateUser':
      case 'LinkedUser':
        _name = 'CreateUser';
        break;
      case 'Notice':
      case 'NoticeDetail':
      case 'Inbox':
      case 'InboxDetail':
      case 'NewPlacardDetail':
        _name = 'NewPlacard';
        break;
      case 'ProxyReport':
      case 'ProxyReigster':
      case 'ProxyEverday':
      case 'ProxyOldBoy':
        _name = 'RedEnvelope';
        break;
      case 'topicList':
      case 'participateUser':
        _name = 'askSpread';
        break;
    }
    return _name;
    // });
  }
  closebtn() {
    this.openAccountTakeCash = false;
  }
}
</script>

<style lang="scss" scoped>
.u_flex {
  display: flex;
  &--left {
    justify-content: flex-start;
    flex: 1;
  }
  &--center {
    justify-content: center;
  }
}
.menu {
  border-right: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
  height: 100%;
  text-align: center;
  :deep(.el-menu-item) {
    height: 52px;
    line-height: 52px;
    &:hover {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFIAAAAyCAMAAAD4BnzzAAAAkFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+WABnwAAAAMHRSTlMAAQIDBAUGBwgJCgsMDQ4PEBESExQVFhcYGRobHB0eHyAhIiMkJSYnKCkqKywtLi+snwlaAAACWElEQVRYw72X6XaCMBBGEcGFACHsasHaqhW17fu/XbNIwDrxdPKjeYB7Zi4h843jOM6EH9d1p9Op53m+78/4mc/ni8ViuVwGAQmjOKYJS7MsL8qyqlfr9ealadvt9nW3e3vfH47Hj1N3Pl8u18/Pr2/HkUx3jPQl8Q5JBTIVyEogNxr5JpAHgewE8tojOVQSB+S4yuChypVANrrKPYi8MXXjQ5WiTBICjTe/Gu/uGr9BDS45kpAY6bKHwi5lmWFIGc7l7bhTyKUokzMjinOpoR7kUhDDKIpYjnI5QCGXChnHrEC51FAfcqmQMU0rlMsBCrlUSErTGuVyBAVcxpKZJNkK5VJDZ5BLUWXCT75GuRygkEvFZCxfo1zqyz+DXCokS/MNyuUY+uhSEPnLlBYblMsBGgAuFTLLiheUyxE0hBoXSP6PNsjGeygxIvO8bA54JD8+AVxmklkUZXvEuBxDH12KKjmTQ08WSA4NwcYlsqzaE7JxdbzQiOSPc3uyQEroo8sbsqrbzgIpoKYqq7qut50NkjxDtmebxqkZadG4T8B7qT+P7SVKzJfI9qqDjbcf1j8kiNQ/pNWzAbgcPRuIx834XsrHDfteTnx49oyeYPygMM2eflDskeMMnOPKZT/OELOnH7oEnD1q6KLm+BANIJd9NEDMcR1gwDl+H2D2yJgFuMxqZL6UYdCYL3kYLNH5kufgmTlfshKZ1VWwNmV1HawxWV3Ff1NWp9i9Z1hS4KxOGXrvmZh2SLWeoXdIuZuZdsjg98L3J5eu+2SHhJfn5y7/ex8n2H38B2BT9q31a6BtAAAAAElFTkSuQmCC);
      background-position: 100%;
      background-repeat: no-repeat;
      color: #fff;
    }
  }
  :deep(.is-active) {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFIAAAAyCAMAAAD4BnzzAAAAkFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+WABnwAAAAMHRSTlMAAQIDBAUGBwgJCgsMDQ4PEBESExQVFhcYGRobHB0eHyAhIiMkJSYnKCkqKywtLi+snwlaAAACWElEQVRYw72X6XaCMBBGEcGFACHsasHaqhW17fu/XbNIwDrxdPKjeYB7Zi4h843jOM6EH9d1p9Op53m+78/4mc/ni8ViuVwGAQmjOKYJS7MsL8qyqlfr9ealadvt9nW3e3vfH47Hj1N3Pl8u18/Pr2/HkUx3jPQl8Q5JBTIVyEogNxr5JpAHgewE8tojOVQSB+S4yuChypVANrrKPYi8MXXjQ5WiTBICjTe/Gu/uGr9BDS45kpAY6bKHwi5lmWFIGc7l7bhTyKUokzMjinOpoR7kUhDDKIpYjnI5QCGXChnHrEC51FAfcqmQMU0rlMsBCrlUSErTGuVyBAVcxpKZJNkK5VJDZ5BLUWXCT75GuRygkEvFZCxfo1zqyz+DXCokS/MNyuUY+uhSEPnLlBYblMsBGgAuFTLLiheUyxE0hBoXSP6PNsjGeygxIvO8bA54JD8+AVxmklkUZXvEuBxDH12KKjmTQ08WSA4NwcYlsqzaE7JxdbzQiOSPc3uyQEroo8sbsqrbzgIpoKYqq7qut50NkjxDtmebxqkZadG4T8B7qT+P7SVKzJfI9qqDjbcf1j8kiNQ/pNWzAbgcPRuIx834XsrHDfteTnx49oyeYPygMM2eflDskeMMnOPKZT/OELOnH7oEnD1q6KLm+BANIJd9NEDMcR1gwDl+H2D2yJgFuMxqZL6UYdCYL3kYLNH5kufgmTlfshKZ1VWwNmV1HawxWV3Ff1NWp9i9Z1hS4KxOGXrvmZh2SLWeoXdIuZuZdsjg98L3J5eu+2SHhJfn5y7/ex8n2H38B2BT9q31a6BtAAAAAElFTkSuQmCC);
    background-position: 100%;
    background-repeat: no-repeat;
    color: #fff;
    background-color: hsla(0, 0%, 100%, 0.1) !important;
  }
  .header {
    margin-top: 36px;
  }
  .box {
    // justify-content: center;
    display: flex;
    align-items: center;
    height: 52px;
    line-height: 52px;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    .circle {
      width: 24px;
      height: 24px;
      position: relative;
      margin-right: 10px;
      .icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 15px;
      }
    }
  }
}
.notice-card__icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 5px;
  background: red;
  position: relative;
  top: -5px;
}
.text-day {
  color: $cf;
  font-size: 20px;
}
.tooltip-item {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $cf;
  padding: 0 !important;
  position: relative;
}
.fig {
  width: 15px;
  height: 15px;
}
.main-menu-header__service_tip {
  position: absolute;
  bottom: 5px;
  right: -5px;
  width: 14px;
  height: 14px;
  line-height: 12px;
  z-index: 1;
}
.el-button + .el-button {
  margin-left: 0;
}
.menu-tooptips {
  width: 60%;
  margin: 9px auto 0;
  padding-bottom: 25px;
  border-bottom: 1px dashed hsla(0, 0%, 100%, 0.3);
}
.main-menu-header__bottom {
  padding-top: 30px;
  width: 76%;
  margin-top: 0;
  margin-right: 30px;
  margin-left: 30px;
}
.main-menu-header__balance {
  color: $cf;
  margin-bottom: 18px;
  cursor: pointer;
  text-align: center;
  .main-menu-header__balance-title {
    font-size: 16px;
    color: $cf;
    margin-bottom: 10px;
  }
  .main-menu-header__balance-main {
    font-size: 16px;
    padding-top: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.main-menu-header__money {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $cf;
  font-size: 20px;
  margin: 0 10px;
}
.back,
.go {
  transition: all 2s;
  cursor: pointer;
}
.btn-controller {
  margin: 0 20px 30px;
  > div {
    border: 1px solid transparent;
    border-radius: 8px;
  }
  > .border-white {
    border-color: hsla(0, 0%, 100%, 0.3);
  }
}
.btnbox3 {
  width: 33.33333%;
  height: 40px;
  padding: auto 5px;
  font-size: 14px;
  background-color: $bg;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $cf;
  cursor: pointer;
  flex: 1;
  &:hover {
    opacity: 0.5;
  }
  .hairline {
    border-right: 1px solid hsla(0, 0%, 100%, 0.3);
  }
}
.u_t-transfrom-cap {
  text-transform: capitalize;
}
.u_p--r50 {
  padding-right: 50px;
}
.u_m--t20 {
  margin-top: 20px;
}
.acc_min_width {
  width: 820px;
  margin: 0 auto;
}
</style>
<style lang="scss">
.takecash {
  border-radius: 10px !important;
  min-width: 1000px;
  .dialog_head {
    font-size: 24px;
    line-height: 48px;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-weight: 500;
  }
  .el-dialog__header {
    padding: 20px 40px 10px;
  }
}
.go {
  transform: rotate(0deg);
}
.back {
  transform: rotate(180deg);
}
</style>
