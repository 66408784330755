<template>
  <div
    class="popup"
    :class="`${ani ? 'ani' : ''}`"
    v-if="show"
    @click="$emit('update:show', false)"
  >
    <div :class="`${position} ${ani ? 'ani' : ''}`" @click.stop>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Watch} from 'vue-property-decorator';

@Options({
  props: {
    position: {
      type: String,
      default: 'center'
    },
    show: {
      type: Boolean
    }
  }
})
export default class Popup extends Vue {
  ani = false;

  @Watch('show')
  onChildChanged(val: boolean) {
    setTimeout(() => {
      this.ani = val;
      const status = val ? 'hidden' : 'auto';
      document.querySelector('body')?.setAttribute('style', `overflow:${status}`);
    }, 0);
  }

  unmounted() {
    document.querySelector('body')?.removeAttribute('style');
  }
}
</script>
<style lang="scss" scoped>
.popup {
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.6);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  opacity: 0;
  &.ani {
    transition: 0.3s;
    opacity: 1;
  }
}
.center {
  padding: $pdc;
  background: #fff;
  @extend .ptc;
  opacity: 0;
  &.ani {
    transition: 0.3s;
    opacity: 1;
  }
}
.left {
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  transform: translateX(-100vw);
  &.ani {
    transition: 0.3s;
    transform: translateX(0);
  }
}
.right {
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  transform: translateX(100vw);
  &.ani {
    transition: 0.3s;
    transform: translateX(0);
  }
}
.top {
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  transform: translateY(-100vh);
  &.ani {
    transition: 0.3s;
    transform: translateY(0);
  }
}
.bottom {
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  transform: translateY(100vw);
  &.ani {
    transition: 0.3s;
    transform: translateY(0);
  }
}
</style>
