import {keyConst} from '@/config';
import {App, Server, Storage} from '@/services';
/* eslint-disable */
export interface IUser {
  availableBalance: string;
  bankBalance: string;
  bind_price_status: number;
  binding: boolean;
  birth: string;
  blockTime: string;
  cashBalance: string;
  category: number;
  createTime: string;
  createType: number;
  create_type: number;
  download_link: string;
  email: {
    text?: string;
    verification?: boolean;
  };
  gameBalance: string;
  hasSecurity: number;
  hasSetQa: number;
  iconId: number;
  introducer_id: string;
  introducer_link: string;
  khId: string;
  kh_gc_url: string;
  lastLoginTime: string;
  lastUserIp: string;
  levelId: string;
  levelText: string;
  lockBalance: string;
  loginTime: string;
  memberLevel: number;
  nickName: string;
  phoneNumber: {
    text?: string;
    verification?: boolean;
  };
  platform_code: string;
  qq: string;
  realName: string;
  rewardMonth: string;
  rewardPrice: string;
  savingBalance: string;
  status: number;
  statusText: string;
  system_gift: boolean;
  token: string;
  transfer_wallet_setting: number;
  type: number;
  typeText: string;
  uggId: number;
  userAccount: string;
  userId: number;
  userIp: string;
  vipLevel: number;
  wechat: string;
  withdrawFrequency: string;
  withdrawUpperLimit: string;
  lastName?: string;
  firstName?: string;
  domain: string;
  promote_code: string;
}

export interface IUserLoginResult {
  list: Array<IUser>;
  message: string;
}

export interface ILastFundChange {
  amount: number;
  category: string;
  id: string;
  order_type_id: string;
  text: string;
  times: string;
}

export interface ITotalAssets{
  centerWallet: string;
gameBalance: string;
lockBalance: string;
savingBalance: string;
sportBalance: string;
totalAmount: string;
}

export class User {
  static async login(userName: string, password: string): Promise<Error | boolean> {
    const data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_account: userName,
      password
    };

    const result = await Server.Wap.Common.login<IUserLoginResult>(data);
    if (result instanceof Error) {
      return result;
    }
    Storage.set(keyConst.token, result.list[0].token);
    Storage.set(keyConst.userInfo, result.list[0]);
    App.updateGlobal();
    return true;
  }
  static async register(userName: string, password: string): Promise<Error | boolean> {
    const data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_account: userName,
      password
    };

    // const result = await Server.Wap.Common.register<IUserLoginResult>(data);

    // if (result instanceof Error) {
    //   return result;
    // }
    // Storage.set(keyConst.token, result.list[0].token);
    // Storage.set(keyConst.userInfo, result.list[0]);
    // App.updateGlobal();
    return true;
  }
}
