<template>
  <div class="container-wrap">
    <el-dropdown>
      <div class="left-icon-flag flex flex-row flex-center" @click="toggleDropdown">
        <div :class="`iti__flag iti__${info.phoneObject.flag}`" />
        <div class="dropdown-arrow">▼</div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="(item, index) in countryCodes"
            :key="index"
            @click="choose(item)"
          >
            {{ item.text }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-input
      class="tel-input small"
      type="tel"
      :placeholder="info.loadingRegex ? $t('ui_loading') : $t(placeholder)"
      :disabled="info.loadingRegex"
      :maxlength="20"
      :required="required"
      v-model="info.phoneObject.number.input"
    >
    </el-input>
  </div>
</template>

<script>
import {ElInput} from 'element-plus';

import PhoneNumber from 'awesome-phonenumber';
import allCountries from './allCountries';

import 'intl-tel-input/build/css/intlTelInput.min.css';

/**
 * 手机输入验证模组
 * preferredCountries: 选择限制区域国码
 * value 输入值
 * placeholder 显示空值占位字符
 * required 是否必填
 */
export default {
  name: 'telInput',
  components: {
    ElInput
  },
  props: {
    preferredCountries: {
      type: Array,
      default() {
        return ['us'];
      }
    },
    /** v-model */
    value: {
      type: [String, Number],
      default: ''
    },
    /** v-model */
    placeholder: {
      type: String,
      default: 'ui_please_enter_phone_number'
    },
    required: {
      type: Boolean,
      default: false
    }
    // showLang: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      showLang: false,
      countryCodescopy: [],
      info: {
        showCountryCodes: false,
        countryCodesInput: '',
        index: -1,
        loadingRegex: false,
        phoneObject: {
          number: {
            e164: '',
            international: '',
            national: '',
            RFC3966: '',
            input: ''
          },
          iso2: '+86',
          flag: 'cn',
          isValid: false,
          phoneUtil: {}
        }
      }
    };
  },
  watch: {
    /** v-model */
    value(val, oldVal) {
      val !== oldVal && (this.info.phoneObject.number.input = val);
    },
    'info.phoneObject.number.input'(val) {
      const check = (val.match(/^\+?[\d\s]*/g) || []).join('');
      if (val !== check) {
        this.info.phoneObject.number.input = check;
      } else if (val && val.length > 1) {
        const phoneObject = this.info.phoneObject;
        const phoneUtil = phoneObject.phoneUtil;
        if (!phoneUtil) {
          // this.activedIndex = this.activedIndex;
          return;
        }
        const number = phoneUtil(val, phoneObject.iso2.toUpperCase());
        phoneObject.number.e164 = number.getNumber('e164');
        phoneObject.number.international = number.getNumber('international');
        phoneObject.number.national = number.getNumber('national');
        phoneObject.number.RFC3966 = number.getNumber('rfc3966');
        phoneObject.isValid = number.isValid();
      } else if (!val) {
        const phoneObject = this.info.phoneObject;
        const phoneUtil = phoneObject.phoneUtil;
        if (!phoneUtil) {
          // this.activedIndex = this.activedIndex;
          return;
        }
        phoneObject.number.e164 = '';
        phoneObject.number.international = '';
        phoneObject.number.national = '';
        phoneObject.number.RFC3966 = '';
        phoneObject.isValid = false;
      }
    },
    'info.phoneObject.isValid'(val, oldVal) {
      if (val !== oldVal) {
        this.$emit('validate', this.info.phoneObject);
      }
    },
    'info.index'(val) {
      console.log('val: ', val);
      this.checkPhoneLib(val);
    }
  },

  async mounted() {
    this.info.loadingRegex = true;
    const result = await Promise.all([
      fetch('https://ip2c.org/s', {
        method: 'GET'
      }).then(response => response.text())
    ]);
    const iso2 = (result[0] || ';').split(';')[1].toLowerCase();
    console.log('iso2: ', iso2);
    console.log(this.sortedCountries.findIndex(v => v.iso2 === iso2));
    const index = this.sortedCountries.findIndex(v => v.iso2 === iso2) || 0;
    if (index !== -1) {
      this.info.index = index;
    } else {
      this.info.index = 0;
    }
    console.log({this: this}, 'phoneObject');
    // console.log(PhoneNumber(), 'PhoneNumber');
    this.info.loadingRegex = false;
    // console.log(`debug: mounted -> result`, result)
  },
  methods: {
    // chooseflag(item) {
    //   this.info.countryCodesInput = item.code;
    //   console.log(this.info.countryCodesInput);
    //   this.info.phoneObject.flag = item.iso2;
    // },

    toggleDropdown() {
      this.showLang = true;
    },

    cleanVal() {
      this.info.phoneObject.number.input = '';
    },
    async checkPhoneLib(val) {
      this.info.phoneObject.iso2 = this.sortedCountries[val].iso2;
      this.info.phoneObject.phoneUtil = PhoneNumber;
    },
    choose(item) {
      const index = this.sortedCountries.findIndex(v => v.iso2 === item.iso2);
      index !== -1 && (this.info.index = index);
      this.info.countryCodesInput = '';
      this.info.phoneObject.number.input = '';
      this.info.phoneObject.flag = item.iso2;
    }
  },
  computed: {
    countryCodes() {
      const list = this.sortedCountries.map(v => {
        return {
          text: `+${v.dialCode} ${v.name}`,
          code: `+${v.dialCode}`,
          className: `vti__flag`,
          ...v
        };
      });
      // return list.filter(v =>
      //   v.text.toLowerCase().includes(this.info.countryCodesInput.toLowerCase())
      return list.filter(v => v.text.toLowerCase());
    },
    sortedCountries() {
      const list = [...allCountries];
      const preferred = [...this.preferredCountries].reverse();
      const realList = [];
      preferred.map(v => {
        const item = list.find(u => u.iso2 === v.toLowerCase());
        item && realList.push(item);
      });
      console.log(realList, 'realList');
      return realList.reverse();
    },
    activedIndex() {
      return this.info.index || 0;
    }
  }
};
</script>
<style lang="scss" scoped>
.container-wrap {
  background-color: #f6f7f8;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0 8px;
  :deep(.el-input__inner) {
    border: none;
    background-color: transparent;
  }
}
.el-dropdown-menu {
  height: 230px;
  overflow: auto;
}

.left-icon-flag {
  padding: 8px 0;
}
</style>
