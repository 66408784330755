
import {Options, Vue} from 'vue-class-component';

@Options({
  props: {
    htmlType: String,
    placeholder: String,
    iconClass: String,
    autocomplete: String,
    modelValue: String,
    autoFocus: Boolean,
    iRequired: Boolean
  },
  emits: {
    'update:modelValue': String
  }
})
export default class Input extends Vue {
  viewPassword = false;
  val = '';
  toggleViewPassword() {
    this.viewPassword = !this.viewPassword;
  }
}
