import {keyConst} from '@/config';
import {App, Server, Storage} from '@/services';

export interface IUser {
  availableBalance: string;
  bankBalance: string;
  bind_price_status: number;
  binding: boolean;
  birth: string;
  blockTime: string;
  cashBalance: string;
  category: number;
  createTime: string;
  createType: number;
  create_type: number;
  download_link: string;
  email: string | object;
  gameBalance: string;
  hasSecurity: number;
  hasSetQa: number;
  iconId: number;
  introducer_id: string;
  introducer_link: string;
  khId: string;
  kh_gc_url: string;
  lastLoginTime: string;
  lastUserIp: string;
  levelId: string;
  levelText: string;
  lockBalance: string;
  loginTime: string;
  memberLevel: number;
  nickName: string;
  phoneNumber: string | object;
  platform_code: string;
  qq: string;
  realName: string;
  rewardMonth: string;
  rewardPrice: string;
  savingBalance: string;
  status: number;
  statusText: string;
  system_gift: boolean;
  token: string;
  transfer_wallet_setting: number;
  type: number;
  typeText: string;
  uggId: number;
  userAccount: string;
  userId: number;
  userIp: string;
  vipLevel: number;
  wechat: string;
  withdrawFrequency: string;
  withdrawUpperLimit: string;
  domain: string;
  promote_code: string;
}

export interface IUserLoginResult {
  list: Array<IUser>;
  message: string;
}

export class GetUser {
  static async action(): Promise<Error | boolean> {
    const result = await Server.Wap.Common.getUserinfo<IUserLoginResult>({});
    const cacheUserinfo = App.getUserinfo() || {};
    if (result instanceof Error) {
      return result;
    }
    Storage.set(keyConst.userInfo, {...cacheUserinfo, ...result.list[0]});
    App.updateGlobal();
    return true;
  }

  static async edit(payload = {}): Promise<Error | IUserLoginResult> {
    const result = await Server.Wap.Common.editUserinfo<IUserLoginResult>(payload);
    if (result instanceof Error) {
      return result;
    }
    return result;
  }
}
