
import {Options, Vue} from 'vue-class-component';

@Options({
  props: {
    title: {
      type: String
    },
    time: {
      type: String
    },
    content: {
      type: String
    },
    navTitle: {
      type: String,
      default: 'navDetail'
    },
    loaded: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Boolean
    },
    cover: {
      type: Array,
      default: []
    }
  },
  computed: {
    isSlot() {
      return !!this.$slots.default;
    }
  }
})
export default class Index extends Vue {}
