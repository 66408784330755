<template>
  <div class="body">
    <div class="main">
      <h1 class="title">{{ $t('login__login') }}</h1>
      <el-form :model="modelRef" ref="formRef">
        <el-form-item label="" prop="username">
          <el-input
            v-model="modelRef.username"
            :placeholder="$t('login__usernamePlaceholder')"
            @keydown.enter="handleSubmit"
          >
            <template #prefix><i class="el-icon-user el-input__icon"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input
            v-model="modelRef.password"
            type="password"
            :placeholder="$t('login__passwordPlaceholder')"
            @keydown.enter="handleSubmit"
          >
            <template #prefix><i class="el-icon-unlock el-input__icon"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="submit" @click="handleSubmit" :loading="submitLoading">
            {{ $t('login__login') }}
          </el-button>
          <div class="disFlexb">
            <!-- <div class="link" @click="$router.push('/register')">
              {{ $t('login__registerNew') }}
            </div> -->
            <div class="link" @click="$router.push('/forget')">
              {{ $t('login__forgetPassword') }}?
            </div>
          </div>
        </el-form-item>
        <!-- <Forget>
          <div class="forget-title">{{ _$t('ui_forget_pwd') }}</div>
        </Forget> -->
        <el-dropdown ref="dropdown">
          <span class="el-dropdown-link">
            {{ $t(name) }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu v-model="lang">
              <el-dropdown-item v-for="lang in actions" :key="lang.id" @click="onSelect(lang)">{{
                lang.name
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {ILoginParams, User} from '@/model';
import {i18n} from '@/common/web';
import {ElMessage, ElDropdown, ElDropdownMenu, ElDropdownItem} from 'element-plus';
import {loadLanguageAsync} from '@/common/web/locale/language';
@Options({
  components: {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem
  }
})
export default class Login extends Vue {
  submitLoading = false;
  modelRef: ILoginParams = {
    username: '',
    password: ''
  };
  dropdown = {};
  actions: {
    name: string;
    key: string;
    className?: string;
  }[] = [];
  name = '简体中文';
  lang = i18n.global.locale;
  async mounted() {
    localStorage.removeItem('activedMenu');
    const handleLangs = new Map([
      ['zh_cn', 'zh-CN'],
      ['vn', 'vn'],
      ['en_us', 'en'],
      ['th_th', 'th']
    ]);
    // eslint-disable-next-line
    if (window.hasOwnProperty('LANGLIST') && Array.isArray(window.LANGLIST)) {
      // eslint-disable-next-line
      window.LANGLIST.map((lang: {text: string; key: string}) => {
        const key = handleLangs.get(lang.key) || 'zh-CN';
        const item: {
          name: string;
          key: string;
          className: string;
        } = {
          name: lang.text,
          key,
          className: key === this.lang ? 'current-ajskfjaslkfj' : ''
        };
        this.actions.push(item);
      });
    }
    const cacheLang = localStorage.getItem('language');
    const setLangName = (lang: string) => {
      this.lang = lang;
      const langObj = this.actions.filter(v => v.key === (handleLangs.get(lang) || lang));
      console.log('langObj: ', langObj);
      this.name = langObj[0] ? langObj[0].name : '简体中文';
    };
    // console.log(this.dropdown, 'dropdown');
    if (cacheLang) {
      setLangName(cacheLang || window.defaultLangCode);
    } else {
      const site = window.location.hostname;
      const langConfig: any = {
        'www.aia11110.com': 'vn',
        'www.aia988.com': 'th',
        'www.aia968.com': 'th'
      };
      setLangName(langConfig[site] || window.defaultLangCode);
    }
    await this.onSelect({key: handleLangs.get(this.lang) || this.lang, name: this.name});
  }
  async onSelect(item: {name: string; key: string}) {
    console.log('item: ', item);
    // this.showLang = false;
    await loadLanguageAsync(item.key);
    i18n.global.locale = item.key;
    this.lang = item.key;
    this.name = item.name;
    this.$forceUpdate();
    localStorage.setItem('language', item.key);
  }
  async handleSubmit() {
    this.submitLoading = true;
    const result = await User.login(this.modelRef.username, this.modelRef.password);
    if (result instanceof Error) {
      this.submitLoading = false;
      ElMessage(result.message);
    } else if (result) {
      this.$router.push({name: 'personalInformation'});
    }
    return false;
  }
}
</script>
<style lang="scss" scoped>
.body {
  background: url('../../../assets/web/bglogin.jpg');
  width: 100vw;
  height: 100vh;
}
.main {
  min-width: 368px;
  padding: 36px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .title {
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 30px;
    text-align: center;
    color: #ffffff;
    /* background-image:-webkit-linear-gradient(right,#FFFFFF,#0f47e6, #FFFFFF);
        -webkit-background-clip: text;
        -webkit-text-fill-color:transparent; */
  }
  .submit {
    width: 100%;
    margin: 0;
  }
  .link {
    color: #fff;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.back,
.go {
  transition: all 2s;
  cursor: pointer;
}
</style>
