export default [
  {
    path: '/personal/information',
    name: 'personalInformation', // 个人资料
    meta: {
      function: 'UserInfo',
      show: true,
      name: 'personal_information'
    },
    component: () =>
      import(
        /* webpackChunkName: "personalInformation" */ '@/web/operation/security/information/index.vue'
      )
  },
  {
    path: '/personal/addCard',
    name: 'AddCard', // 个人资料
    meta: {
      hide: true,
      name: 'btn_add_bank_card'
    },

    component: () =>
      import(/* webpackChunkName: "AddCard" */ '@/web/operation/security/bankcard/add/index.vue')
  },
  {
    path: '/userlist',
    name: 'userlist', // 用户列表
    meta: {
      function: 'userlist',
      show: true,
      name: 'memberlist__title'
    },
    component: () =>
      import(/* webpackChunkName: "userlist" */ '@/web/operation/memberList/index.vue')
  },
  {
    path: '/userlist/deail/:userName?',
    name: 'listdetail',
    meta: {
      hide: true,
      name: 'listdetail'
    },
    component: () => import('@/web/operation/memberList/transfer/index.vue')
    // props: (route) => ({ ...route.query }),
    // props: {userName: String}
  },
  {
    path: '/userlist/game/:id/detail',
    name: 'MemberGameRecord',
    meta: {
      hide: true
    },
    component: () =>
      import(
        /* webpackChunkName: "MemberGameRecord" */ '@/web/operation/memberList/gameRecord/index.vue'
      )
  },
  {
    path: '/tasklist',
    name: 'tasklist', // 任务列表
    meta: {
      function: 'tasklist',
      show: true,
      name: 'tasklist__title'
    },
    component: () => import(/* webpackChunkName: "userlist" */ '@/web/operation/taskList/index.vue')
  },
  {
    path: '/tasklist/detail',
    name: 'taskDetail',
    meta: {
      hide: true,
      name: 'taskDetail'
    },
    component: () => import('@/web/operation/taskList/Detail.vue')
    // props: (route) => ({ ...route.query }),
    // props: {userName: String}
  },
  {
    path: '/memberList/game/:id/:companyId/:user',
    name: 'MemberGameRecordDetail',
    meta: {
      hide: true
    },
    component: () =>
      import(
        /* webpackChunkName: "MemberGameRecord" */ '@/web/operation/memberList/gameRecord/detail/index.vue'
      )
  },
  {
    path: '/news/list',
    name: 'newslist',
    meta: {
      name: 'nav__news'
    },
    component: () => import(/* webpackChunkName: "newslist" */ '@/web/operation/news/list.vue')
  },
  {
    path: '/information',
    name: 'Information',
    meta: {
      name: 'nav__information'
    },
    component: () =>
      import(/* webpackChunkName: "Information" */ '@/web/operation/information/list.vue')
  },
  {
    path: '/sportsInformation',
    name: 'sportsInformation',
    meta: {
      name: 'nav__sportsInformation'
    },
    component: () =>
      import(/* webpackChunkName: "Information" */ '@/web/operation/sportsInformation/index.vue')
  },
  {
    path: '/picMaterial',
    name: 'picMaterial',
    meta: {
      name: 'promotional_picture_materials'
    },
    component: () =>
      import(/* webpackChunkName: "Information" */ '@/web/operation/picMaterial/index.vue')
  },
  {
    path: '/league/list',
    name: 'leaguelist',
    meta: {
      name: 'nav__league'
    },
    component: () => import('@/web/operation/league/list.vue')
  },
  {
    path: '/match/list',
    name: 'matchlist',
    meta: {
      name: 'nav__match'
    },
    component: () => import('@/web/operation/match/list.vue')
  },
  {
    path: '/team/createUser',
    name: 'teamCreateUser',
    meta: {
      name: 'nav__team'
    },
    component: () => import(/* webpackChunkName: "Team" */ '@/web/operation/team/createUser.vue')
  },
  // 问答
  {
    path: '/askSpread',
    name: 'askSpread',
    meta: {
      name: 'askSpread'
    },
    redirect: {
      name: 'topicList'
    },
    component: () =>
      import(/* webpackChunkName: "askSpread" */ '@/web/operation/askSpread/index.vue'),
    children: [
      {
        path: 'topicList',
        name: 'topicList',
        component: () =>
          import(
            /* webpackChunkName: "topicList" */ '@/web/operation/askSpread/topicList/index.vue'
          )
      },
      {
        path: 'participateUser',
        name: 'participateUser',
        component: () =>
          import(
            /* webpackChunkName: "topicList" */ '@/web/operation/askSpread/participateUser/index.vue'
          )
      }
    ]
  },
  {
    path: 'RedEnvelope',
    name: 'RedEnvelope', // 紅利
    meta: {
      name: 'ui_luckymoney'
    },
    redirect: {
      name: 'ProxyReport'
    },
    component: () =>
      import(/* webpackChunkName: "RedEnvelope" */ '@/web/operation/proxy/index.vue'),
    children: [
      {
        path: 'list',
        name: 'ProxyReport',
        component: () =>
          import(/* webpackChunkName: "ProxyReport" */ '@/web/operation/proxy/report/index.vue')
      },
      {
        path: 'reigster',
        name: 'ProxyReigster',
        component: () =>
          import(/* webpackChunkName: "ProxyReigster" */ '@/web/operation/proxy/reigster/index.vue')
      },
      {
        path: 'everday',
        name: 'ProxyEverday',
        component: () =>
          import(/* webpackChunkName: "ProxyEverday" */ '@/web/operation/proxy/everday/index.vue')
      },
      {
        path: 'oldBoy',
        name: 'ProxyOldBoy',
        component: () =>
          import(/* webpackChunkName: "ProxyOldBoy" */ '@/web/operation/proxy/oldBoy/index.vue')
      }
    ]
  },
  {
    path: '/report/index',
    name: 'Report',
    meta: {
      name: 'nav__report'
    },
    component: () =>
      import(/* webpackChunkName: "ReportStatics" */ '@/web/operation/report/index.vue')
  },

  {
    path: '/Report/Commission',
    name: 'ReportCommission', // 傭金報表
    meta: {
      name: 'ui_text_005'
    },

    component: () => import('@/web/operation/commission/index.vue')
  },

  {
    path: '/Report/Trade',
    name: 'TradeReport', // 資金明細
    meta: {
      name: 'ui_transaction_record_v2'
    },

    component: () => import('@/web/operation/report/tradeReport/index.vue')
  },
  {
    path: '/msg',
    name: 'Msg',
    meta: {
      name: 'nav__msg',
      info_red: true
    },
    component: () => import(/* webpackChunkName: "Msg" */ '@/web/operation/msg/msg.vue')
  },
  {
    path: '/',
    name: 'Logout',
    meta: {
      name: 'ui_logout'
    },
    redirect: {
      name: 'Login'
    }
  }
];
