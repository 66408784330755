import doFetch from '../../fetch';
const ACCPATH = '/api/acc/front';

//  /** 已綁訂的卡號列表 */
//  read: ApiFn('user_card/list_info'),
//  /** 綁訂卡片 */
//  create: ApiFn('user_card/binding_step'),
//  /** 驗證最後綁卡資料 */
//  update: ApiFn('user_card/verify_latest_one'),
//  /** 銀行卡卡號刪除 */
//  delete: ApiFn('user_card/delete_bank_card'),
/**
 *  user card info
 * @param data
 */
const actions = {
  read: async <T>(data: object) =>
    await doFetch<T>(`${ACCPATH}/v1/user_card/list_info`, 'POST', data),
  created: async <T>(data: object) =>
    await doFetch<T>(`${ACCPATH}/v1/user_card/binding_step`, 'POST', data),
  updated: async <T>(data: object) =>
    await doFetch<T>(`${ACCPATH}/v1/user_card/verify_latest_one`, 'POST', data),
  deleted: async <T>(data: object) =>
    await doFetch<T>(`${ACCPATH}/v1/user_card/delete_bank_card`, 'POST', data),
  info: async <T>() => await doFetch<T>(`${ACCPATH}/v1/ui/bank_can_binding`, 'POST', {}),
  infoSettings: async <T>(data: object) =>
    await doFetch<T>(`${ACCPATH}/v1/setting_env_config/get_values`, 'POST', data)
};

export const userCard = actions;
