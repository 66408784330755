import {keyConst} from '@/config';
import {App, Server, Storage} from '@/services';

export interface IConfig {
  btn_help: string;
  game_company: string;
  ui_add_agent: string;
  ui_cd_sys: string;
  ui_contact_cs: string;
  ui_dir_reg_recommended_id_mobile: string;
  ui_dir_reg_recommended_id_web: string;
  ui_direct_register_code: string;
  ui_football_btn: string;
  ui_login_no_verify_code: string;
  ui_login_page: string;
  ui_member_reg: string;
  ui_msg_allow_c2b: string;
  ui_register_no_verify_code: string;
  ui_registration_contact_info: string;
  ui_try_free: string;
  ui_wap_page_guide_download_app_cover_page: string;
  ui_withdraw_time: string;
  withdrawals_check_email: string;
  withdrawals_check_funds_password: string;
  withdrawals_check_phone_number: string;
  currency?: {
    id: string;
    code: string;
    text: string;
  };
  vip?: string;
  dollarSign?: string;
}

export interface IConfigResult {
  currency: {
    id: string;
    code: string;
    text: string;
  };
  vip: string;
  list: Array<IConfig>;
  message: string;
}

export class Config {
  static async action(payload: {device: string; keys?: string[]}): Promise<Error | boolean> {
    const result = await Server.Wap.Common.getConfig<IConfigResult>(payload);
    if (result instanceof Error) {
      return result;
    }
    // 越南环境
    // result.currency = {
    //   code: "VND",
    //   id: "2",
    //   text: "ภาษาเวียดนาม USB"
    // };
    const dollarSignMap = new Map([
      ['CNY', '¥'],
      ['USD', '$'],
      ['USDT', '$'],
      ['VND', '₫'],
      ['THB', '฿']
    ]);
    Storage.set(keyConst.userConfig, {
      ...result.list[0],
      vip: result.vip,
      currency: result.currency,
      dollarSign: dollarSignMap.get(result.currency.code || 'CNY')
    });
    App.updateGlobal();
    return true;
  }
}
