
import {Options, Vue} from 'vue-class-component';
import {ActionSheet} from 'vant';
import {i18n} from '@/common/wap';
import {loadLanguageAsync} from '@/common/web/locale/language';
import locale from 'element-plus/lib/locale';
import eventBus from '@/web/eventBus';

@Options({
  components: {
    ActionSheet,
    i18n
  },
  props: {
    onToggle: Function
  }
})
export default class LoginPanel extends Vue {
  showLang = false;
  actions: {
    name: string;
    key: string;
    className?: string;
  }[] = [];
  name = '简体中文';
  lang = i18n.global.locale;
  async mounted() {
    const handleLangs = new Map([
      ['zh_cn', 'zh-CN'],
      ['vn', 'vn'],
      ['en_us', 'en'],
      ['th_th', 'th']
    ]);
    // eslint-disable-next-line
    if (window.hasOwnProperty('LANGLIST') && Array.isArray(window.LANGLIST)) {
      // eslint-disable-next-line
      window.LANGLIST.map((lang: {text: string; key: string}) => {
        const key = handleLangs.get(lang.key) || 'zh-CN';
        const item: {
          name: string;
          key: string;
          className: string;
        } = {
          name: lang.text,
          key,
          className: key === this.lang ? 'current-ajskfjaslkfj' : ''
        };
        this.actions.push(item);
      });
    }
    this.lang = localStorage.getItem('language') || window.defaultLangCode;
    const obj = new Map([
      ['zh_cn', 'zh-CN'],
      ['zh', 'zh-CN'],
      ['en', 'en'],
      ['vn', 'vn'],
      ['en_us', 'en'],
      ['th_th', 'th'],
      ['th', 'th']
    ]);
    this.name = this.actions.filter(v => v.key === (obj.get(this.lang) || this.lang))[0]
      ? this.actions.filter(v => v.key === (obj.get(this.lang) || this.lang))[0].name
      : '简体';

    await this.onSelect({key: obj.get(this.lang) || this.lang, name: this.name});
  }
  showLangActionSheet() {
    this.actions = this.actions.map(x => {
      if (x.key === this.lang) {
        x.className = 'current-ajskfjaslkfj';
      } else {
        x.className = '';
      }
      return x;
    });
    this.showLang = true;
  }

  async onSelect(item: {name: string; key: string}) {
    console.log('item: ', item);
    this.showLang = false;
    await loadLanguageAsync(item.key);
    const elLangMap = new Map([
      ['zh-CN', 'zh-cn'],
      ['en', 'en'],
      ['vn', 'vi'],
      ['th', 'th']
    ]);
    const lang = await import(
      `element-plus/lib/locale/lang/${elLangMap.get(item.key) || 'zh-cn'}.js`
    );
    console.log('lang: ', lang);
    locale.use(lang.default);
    i18n.global.locale = item.key;
    this.lang = item.key;
    this.name = item.name;
    localStorage.setItem('language', item.key);
    eventBus.bus$emit('langChange', item.key);
  }
}
