<template>
  <router-view v-slot="{Component}">
    <transition name="el-fade-in-linear" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {App} from '@/services';
import {Config} from '@/model';
import {GetUser} from '@/model/tokenGetUser';

import eventBus from '@/web/eventBus';

@Options({})
export default class AppRoot extends Vue {
  unitMap = new Map([
    ['USD', 'ui_dollar_usd'],
    ['VND', 'ui_dollar_vnd'],
    ['THB', 'ui_dollar_thb'],
    ['CNY', 'ui_dollar_cny']
  ]);
  config = {
    currency: {
      id: '',
      code: 'CNY',
      text: ''
    },
    dollarSign: '¥'
  };
  mounted() {
    this.init();
    eventBus.bus$on('langChange', () => {
      this.init();
    });
  }

  beforeDestroy() {
    eventBus.bus$off('langChange');
  }
  async init() {
    // 取得設定
    await Promise.all([
      Config.action({
        device: 'pc',
        keys: [
          'btn_help',
          'game_company',
          'ui_add_agent',
          'ui_cd_sys',
          'ui_contact_cs',
          'ui_dir_reg_recommended_id_mobile',
          'ui_dir_reg_recommended_id_web',
          'ui_direct_register_code',
          'ui_football_btn',
          'ui_login_no_verify_code',
          'ui_login_page',
          'ui_member_reg',
          'ui_msg_allow_c2b',
          'ui_register_no_verify_code',
          'ui_registration_contact_info',
          'ui_try_free',
          'ui_wap_page_guide_download_app_cover_page',
          'ui_withdraw_time',
          'withdrawals_check_email',
          'withdrawals_check_funds_password',
          'withdrawals_check_phone_number',
          'currency',
          'vip',
          'dollarSign'
        ]
      }),
      GetUser.action()
    ]);
    this.config = {...this.config, ...App.getConfig()};
  }
}
</script>

<style lang="scss">
html,
body {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
}
.el-message-box {
  text-align: center;
  .el-message-box__btns {
    .el-button {
      flex: 1;
      border-radius: 8px;
    }
  }
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1f55f0;
}
.el-button--primary.is-active,
.el-button--primary:active {
  background-color: #1f55f0;
  border-color: #1f55f0;
}
.el-message-box__btns {
  display: flex;
  justify-content: center;
}
.el-button--primary {
  background-color: $bg;
  border-color: $bg;
  border-radius: 8px;
  flex: 1;
  margin: 0 10px;
}
.el-dialog {
  border-radius: 10px;
}
.el-input__inner::placeholder {
  color: #c0c4cc;
}
.el-input__inner:valid {
  border-color: transparent;
  color: #666666;
}
.el-input__inner {
  border-color: transparent;
  background-color: #f5f5f5;
}
.el-input-group__append,
.el-input-group__prepend {
  border: none;
}
.c_input--longest .el-input__inner:valid {
  border-color: transparent;
  color: #666666;
}
.el-date-editor .el-range-input {
  background-color: #f5f5f5;
  color: #666666;
}
.c_input--longest .el-input__inner {
  border-color: transparent;
  background-color: #f5f5f5;
}

.primary-picker :deep(.el-month-table td.in-range div),
.primary-picker :deep(.el-month-table td.in-range div:hover) {
  background-color: transparentize($bg, 0.1) !important;
}

.el-month-table td.in-range div,
.el-month-table td.in-range div:hover {
  background-color: transparentize($bg, 0.7);
}
.el-month-table td.end-date .cell,
.el-month-table td.start-date .cell {
  background-color: $bg;
}
.primary-picker :deep(.el-month-table td.end-date .cell),
.primary-picker :deep(.el-month-table td.start-date .cell) {
  background-color: $bg;
}

/**
* 自定義scorllbar
*/
::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 0.5em;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5em;
  background-color: $bg;
}
</style>
