import doFetch from '../../fetch';

/**
 * get today overview
 */
const getTodayOverview = async <T>() =>
  doFetch<T>(`/api/agent/v1/Users/today_overview`, 'POST', undefined);

/**
 * get finacial overview
 */
const getFinacialOverview = async <T>(data: any) =>
  doFetch<T>(`/api/agent/v1/Finance/financial_overview`, 'POST', data);

/**
 * get finacial reward
 */
const getFinacialReward = async <T>(data: any) =>
  doFetch<T>(`/api/agent/v1/Finance/get_reward_detail`, 'POST', data);
/**
 *游戏商输赢总览
 */
const BetProfitList = async <T>(data: any) =>
  doFetch<T>(`/api/agent/v1/Finance/bet_profit_list`, 'POST', data);

/**
 *
 * 推广管理添加用户
 * @param data
 */
const setMemer = async <T>(data: any) =>
  doFetch<T>(`/api/acc/front/v1/Agent/create_user`, 'POST', data);
/**
 *
 * 链接开户
 * @param data
 */
const linkMember = async <T>() =>
  await doFetch<T>(`/api/agent/v1/Users/subdomain_list`, 'POST', {});

/**
 *
 * 產生代理域名
 * @param data
 */
const addSubdomain = async <T>(data: any) =>
  await doFetch<T>(`/api/agent/v1/Users/add_subdomain`, 'POST', data);

/**
 * 取得活動列表
 * @param data
 * @returns promise
 */
const activityList = async <T>() =>
  await doFetch<T>(`/api/agent/v1/Activity_define/get_activity_by_category`, 'POST', {});

/**
 *
 * 編輯代理域名備註
 * @param data
 */
const editSubdomain = async <T>(data: any) =>
  await doFetch<T>(`/api/agent/v1/Users/edit_subdomain_note`, 'POST', data);

export default {
  getTodayOverview,
  getFinacialOverview,
  getFinacialReward,
  setMemer,
  linkMember,
  addSubdomain,
  editSubdomain,
  BetProfitList,
  activityList
};
