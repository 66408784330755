import {createI18n} from 'vue-i18n';
import zh from './zh/zh.json';
import enLocale from 'element-plus/lib/locale/lang/en';
import zhLocale from 'element-plus/lib/locale/lang/zh-cn';
import thLocale from 'element-plus/lib/locale/lang/th';
import ElementLocale from 'element-plus/lib/locale';

const language = localStorage.getItem('language');
const i18n = createI18n({
  locale: language || 'zh-CN',
  messages: {
    'zh-CN': {
      el: zhLocale.el,
      ...zh
    }
  },
  sync: true,
  silentTranslationWarn: true
});
ElementLocale.i18n(i18n.global.t);
const loadedLanguages = ['zh-CN']; // 我们的预装默认语言

const setI18nLanguage = (lang: string) => {
  i18n.global.locale = lang;
  return lang;
};

export const loadLanguageAsync = (lang: string) => {
  if (!loadedLanguages.includes(lang) || !Object.keys(i18n.global.messages).includes(lang)) {
    console.log(`./${lang}/${lang}.json`, '`./${lang}/${lang}.json`');
    return import(/* webpackChunkName: "lang-[request]" */ `./${lang}/${lang}.json`)
      .then(msgs => {
        console.log(i18n, msgs);
        const local = lang === 'th' ? thLocale : enLocale;
        i18n.global.setLocaleMessage(lang, {el: local.el, ...msgs});
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
      })
      .catch(err => {
        console.error(err, 'err');
      });
  }
  return Promise.resolve(setI18nLanguage(lang));
};

export default i18n;
