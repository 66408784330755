import {createApp} from 'vue';
import {App} from '@/services';
import {i18n} from '@/common/web';
import Root from './App.vue';
import router from '../config/router/web';
import installElementPlus from './plugins/element';
import '@/common/styles/reset.css';
import 'element-plus/lib/theme-chalk/index.css';

App.updateGlobal();
const app = createApp(Root);
installElementPlus(app);
app.use(i18n);
app.use(router);
app.mount('#app');
