<template>
  <div>
    <div class="top-common-tab pdc disFlex" :class="{white: theme === 'white'}">
      <div class="action-box">
        <div>
          <div class="icon-box" v-if="left === 'menu'" @click="showMenu = true">
            <img src="@/assets/wap/menu.png" alt="" class="c-icons" />
          </div>
          <div class="icon-box" v-if="left === 'back'" @click="goBack">
            <img src="@/assets/wap/back.png" alt="" class="c-icons" v-if="theme === 'blue'" />
            <img
              src="@/assets/wap/left-arrow.png"
              alt=""
              class="c-icons"
              v-if="theme === 'white'"
            />
          </div>
        </div>
        <div>
          <slot name="right">
            <div class="icon-box" v-if="right === 'Home'" @click="goHome">
              <img src="@/assets/wap/Home.png" alt="" class="c-icons" />
            </div>
          </slot>
        </div>
      </div>

      <div class="cf fl title" :class="{white: theme === 'white'}">{{ title }}</div>
    </div>
    <Popup v-model:show="showMenu" position="left">
      <Menu></Menu>
    </Popup>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import Popup from '../popup/index.vue';
import Menu from './menu.vue';

@Options({
  props: {
    title: {
      type: String,
      default: ''
    },
    /**
     * blue or white
     */
    theme: {
      type: String,
      default: 'blue'
    },
    /**
     * back or menu
     */
    left: {
      type: String,
      default: 'back'
    },
    right: {
      type: String,
      default: 'back'
    }
  },
  components: {
    Popup,
    Menu
  },
  methods: {
    asdas() {
      console.log(123);
    }
  }
})
export default class NavBar extends Vue {
  text = '123121233';

  showMenu = false;

  goBack() {
    this.$router.back();
  }
  goHome() {
    this.$router.push({path: '/'});
  }
}
</script>
<style lang="scss" scoped>
.top-common-tab {
  height: $c-ic;
  background: $bg;
  position: relative;
  &.white {
    background-color: white;
  }
  .title {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &.white {
      color: #333333;
    }
  }
  .action-box {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-box {
      padding: 0.16rem 0.32rem;
      @extend .clickAction;
    }
  }
}
</style>
