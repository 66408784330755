
import {Options, Vue} from 'vue-class-component';
import {ILoginParams, User} from '@/model';
import {i18n} from '@/common/web';
import {ElMessage, ElDropdown, ElDropdownMenu, ElDropdownItem} from 'element-plus';
import {loadLanguageAsync} from '@/common/web/locale/language';
@Options({
  components: {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem
  }
})
export default class Login extends Vue {
  submitLoading = false;
  modelRef: ILoginParams = {
    username: '',
    password: ''
  };
  dropdown = {};
  actions: {
    name: string;
    key: string;
    className?: string;
  }[] = [];
  name = '简体中文';
  lang = i18n.global.locale;
  async mounted() {
    localStorage.removeItem('activedMenu');
    const handleLangs = new Map([
      ['zh_cn', 'zh-CN'],
      ['vn', 'vn'],
      ['en_us', 'en'],
      ['th_th', 'th']
    ]);
    // eslint-disable-next-line
    if (window.hasOwnProperty('LANGLIST') && Array.isArray(window.LANGLIST)) {
      // eslint-disable-next-line
      window.LANGLIST.map((lang: {text: string; key: string}) => {
        const key = handleLangs.get(lang.key) || 'zh-CN';
        const item: {
          name: string;
          key: string;
          className: string;
        } = {
          name: lang.text,
          key,
          className: key === this.lang ? 'current-ajskfjaslkfj' : ''
        };
        this.actions.push(item);
      });
    }
    const cacheLang = localStorage.getItem('language');
    const setLangName = (lang: string) => {
      this.lang = lang;
      const langObj = this.actions.filter(v => v.key === (handleLangs.get(lang) || lang));
      console.log('langObj: ', langObj);
      this.name = langObj[0] ? langObj[0].name : '简体中文';
    };
    // console.log(this.dropdown, 'dropdown');
    if (cacheLang) {
      setLangName(cacheLang || window.defaultLangCode);
    } else {
      const site = window.location.hostname;
      const langConfig: any = {
        'www.aia11110.com': 'vn',
        'www.aia988.com': 'th',
        'www.aia968.com': 'th'
      };
      setLangName(langConfig[site] || window.defaultLangCode);
    }
    await this.onSelect({key: handleLangs.get(this.lang) || this.lang, name: this.name});
  }
  async onSelect(item: {name: string; key: string}) {
    console.log('item: ', item);
    // this.showLang = false;
    await loadLanguageAsync(item.key);
    i18n.global.locale = item.key;
    this.lang = item.key;
    this.name = item.name;
    this.$forceUpdate();
    localStorage.setItem('language', item.key);
  }
  async handleSubmit() {
    this.submitLoading = true;
    const result = await User.login(this.modelRef.username, this.modelRef.password);
    if (result instanceof Error) {
      this.submitLoading = false;
      ElMessage(result.message);
    } else if (result) {
      this.$router.push({name: 'personalInformation'});
    }
    return false;
  }
}
