/* eslint-disable @typescript-eslint/no-explicit-any */
const nullOrUndef = (source: any): boolean => source === undefined || source === null;

const safeRender = (source: any, path: string | string[], replacer: any) => {
  if (nullOrUndef(source)) {
    return replacer;
  }
  if (typeof path === 'string') {
    if (nullOrUndef(source[path])) {
      return replacer;
    }
    return source[path];
  }
  const tmp = source;
  let key: string | undefined;
  for (let i = 0; i < path.length; i++) {
    key = path[i];
    if (nullOrUndef(source)) {
      return replacer;
    }
  }
  if (key === undefined) {
    return replacer;
  }
  return tmp[key];
};

const padZero = (n: number): string => {
  if (n < 10) {
    return `0${n.toString()}`;
  }
  return n.toString();
};

const second2HMS = (second: number): string => {
  let sec = second;
  const MINUTE_SECONDS = 60;
  const HOUR_SECONDS = MINUTE_SECONDS * 60;
  // const DAY_SECONDS = HOUR_SECONDS * 24;
  const hours = Math.floor(sec / HOUR_SECONDS);
  sec -= hours * HOUR_SECONDS;
  const minutes = Math.floor(sec / MINUTE_SECONDS);
  sec -= minutes * MINUTE_SECONDS;
  console.log('TIME:', hours, minutes, second);

  return `${padZero(hours)}:${padZero(minutes)}:${padZero(sec)}`;
};

/**
 * format to 16:00
 * @param date date
 */
const formatHM = (date: Date) => {
  if (nullOrUndef(date)) {
    return '';
  }
  return `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
};

/**
 * YYYY-MM-DD
 * @param date date
 */
const formatYMDLine = (date: Date) => {
  return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
};

/**
 * YY/MM/DD
 * @param date date
 */
const formatYMDShotLine = (date: Date) => {
  return `${date
    .getFullYear()
    .toString()
    .substr(2, 2)}/${padZero(date.getMonth() + 1)}/${padZero(date.getDate())}`;
};

/**
 * YYYY.MM.DD
 * @param date date
 */
const formatYMDDot = (date: Date) => {
  return `${date.getFullYear()}.${padZero(date.getMonth() + 1)}.${padZero(date.getDate())}`;
};

/**
 * YYYY-MM-DD HH:MM:SS
 * @param date date
 */
const formatYMDHMS = (date: Date) => {
  return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
    date.getDate()
  )} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
};

/**
 * YYYY-MM
 * @param date date
 */
const formatYM = (date: Date) => {
  return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}`;
};

/**
 * YYYY.MM.DD HH:MM
 * @param date date
 */
const formatYMDHM = (date: Date) => {
  return `${date.getFullYear()}.${padZero(date.getMonth() + 1)}.${padZero(
    date.getDate()
  )} ${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
};

/**
 * TimeHandle format getDate and subtract
 * get 取得現在時間
 * getDate 取得字串時間 Date物件
 * subtract 退時
 *  - number 幾個單位
 *  - pick 單位 {second, minute, hour, date, month, year}
 * format
 *  - format 格式化時間字串
 */
const TimeHandle = {
  get: function(date: Date = new Date()) {
    return new Date(date);
  },
  subtract: function(date: Date, number: number, pick: string) {
    const _number = number || 0;
    const _pick = pick || 'date';
    const tempdate = new Date();

    const pickMap = new Map([
      ['second', tempdate.setSeconds(date.getSeconds() - _number)],
      ['minute', tempdate.setMinutes(date.getMinutes() - _number)],
      ['hour', tempdate.setHours(date.getHours() - _number)],
      ['date', tempdate.setDate(date.getDate() - _number)],
      ['month', tempdate.setMonth(date.getMonth() - _number)],
      ['year', tempdate.setFullYear(date.getFullYear() - _number)]
    ]);
    return new Date(pickMap.get(_pick) || +new Date());
  },
  format: function(date: Date, format: string) {
    const _format = format || 'YYYY-MM-DD';
    let _date = '';
    switch (_format) {
      case 'YYYY-MM':
        _date = formatYM(date);
        break;
      case 'YYYY-MM-DD':
        _date = formatYMDLine(date);
        break;
      case 'YYYY-MM-DD HH:MM:SS':
        _date = formatYMDHMS(date);
        break;
      case 'YY/MM/DD':
        _date = formatYMDShotLine(date);
        break;
      case 'YYYY.MM.DD':
        _date = formatYMDDot(date);
        break;
      case 'YYYY.MM.DD HH:MM':
        _date = formatYMDHM(date);
        break;
      case 'HH:MM':
        _date = formatHM(date);
        break;
    }
    return _date;
  },
  isLeapYear: function(date: Date = new Date()) {
    const year = date.getFullYear();
    return !(year % 4 && year % 100) || !(year % 400);
  }
};

/**
 * generate random string
 * @param length string length
 */
const randomString = (length: number) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

/**
 * keep one point, return 0 if num is null or undefined
 * @param num number
 */
const toFix1 = (num: number) => {
  if (num === null || num === undefined) {
    return 0;
  }
  return num.toFixed(1);
};

/**
 * 千分號逗點/小數點最多四位
 * @param {string|number} number 金額
 * @param {number} digits 小數點下位數 default 4
 */
export const formatNumber = (number: string | number, digits = 4): string => {
  number = number || 0;
  // 修正原始數據有千分號會錯誤的問題
  const aIntNum = number
    .toString()
    .replace(/,/, '')
    .split('.');
  // 判斷是否有小數位
  // 判斷小數位是否足夠長度 超出截斷 不足補上
  const floatPart =
    aIntNum.length > 1
      ? aIntNum[1].length > digits
        ? `.${aIntNum[1].substr(0, digits)}`
        : `.${aIntNum[1] + '0'.repeat(digits - aIntNum[1].length)}`
      : digits
      ? '.' + '0'.repeat(digits)
      : '';
  const pattern = /(-?\d+)(\d{3})/;
  let num = aIntNum[0];
  while (pattern.test(num)) {
    num = num.replace(pattern, '$1,$2');
  }
  return num === 'NaN' ? '' : floatPart === '.' ? `${num}` : `${num}${floatPart}`;
};
/**
 * how many minutes to now start from startTime
 * @param startTime
 */
const minuteFrom = (startTime: number): number => {
  const now = new Date();
  const startMinute = Math.floor(startTime / 60);
  const nowMinute = Math.floor(now.getTime() / 1000 / 60);
  return nowMinute - startMinute;
};

/**
 * if there is no async copy api use document.execCommand
 * @param text
 */
function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let flag = false;
  try {
    flag = document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
  return flag;
}
/**
 * copy text to clipboard
 * @param text
 */
async function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  try {
    navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    return false;
  }
}

const upperCase = (options: string) => {
  return options.replace(/^\w/, function(match) {
    return match.toUpperCase();
  });
};

const snakeCase = (options: string) => {
  return options.replace(/([a-z])([A-Z])/g, function(match, p1, p2) {
    return p1 + '_' + p2.toLowerCase();
  });
};
export default {
  safeRender,
  nullOrUndef,
  second2HMS,
  formatHM,
  formatYMDDot,
  formatYMDLine,
  randomString,
  toFix1,
  formatYMDHMS,
  minuteFrom,
  formatYMDShotLine,
  formatYMDHM,
  copyTextToClipboard,
  upperCase,
  snakeCase,
  formatNumber,
  formatYM,
  TimeHandle
};
