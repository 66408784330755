
import {Server} from '@/services';
import {Options, Vue} from 'vue-class-component';
import Left from './left.vue';
import {ITodayOverview} from '@/model';
import {App, Format} from '@/services';
import Languague from '../languague/index.vue';
import eventBus from '@/web/eventBus';
import Wallet from '@/common/web/wallet/index.vue';
import {IWallet} from '@/model/transfer';
import countTo from '@/common/web/countTo/index.vue';

interface LoginParamsType {
  username: '';
  password: '';
}
@Options({
  components: {
    Left,
    Wallet,
    Languague,
    countTo
  }
})
export default class Login extends Vue {
  userInfo = App.getUserinfo();
  mconfig = App.getConfig() || {
    dollarSign: '¥'
  };
  unread = 0;
  scroll = 0;
  result = {};
  wallet: IWallet = {
    centerWallet: '',
    gameBalance: '',
    lockBalance: '',
    savingBalance: '',
    sportBalance: '',
    totalAmount: ''
  };
  rotate = false;
  isFetch = false;
  formatNumber = Format.formatNumber;
  handleWallet(wallet: IWallet) {
    this.wallet = wallet;
    this.isFetch = false;
  }
  get computedAmount() {
    return (
      Math.floor(
        (+this.wallet.totalAmount.replace(/,/g, '') +
          +this.wallet.savingBalance.replace(/,/g, '') +
          +this.wallet.lockBalance.replace(/,/g, '')) *
          100
      ) / 100
    );
  }
  reloadTotalAmount() {
    this.$nextTick(() => {
      eventBus.bus$emit('fetchWallet', true);
      this.isFetch = true;
    });
    this.rotate = !this.rotate;
  }
  created() {
    document.addEventListener('scroll', this.listenScroll);
    this.getUnRead();
    this.todayoverview();
  }
  async getUnRead() {
    const res = await Server.Web.Common.unread<any>();
    if (!(res instanceof Error)) {
      const result = res.list[0];
      this.unread = 0;
      for (const i in result) {
        this.unread += parseInt(result[i]);
      }
      eventBus.bus$emit('publish-unread', this.unread);
    }
  }
  async todayoverview() {
    const result = await Server.Web.Operation.getTodayOverview<ITodayOverview>();
    this.result = result;
  }
  listenScroll() {
    this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
  }
  beforeUnmount() {
    this.scroll = 0;
    window.removeEventListener('scroll', this.listenScroll);
  }
}
