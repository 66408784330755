
import {Options, Vue} from 'vue-class-component';
import Service from '@/services/server/web';
import {IMatchType} from '@/model';

@Options({})
export default class Index extends Vue {
  //联级选择显示
  selectValues = [];
  //联级选择获取数据
  //联级配置
  selectProps = {label: 'name', value: 'code', multiple: false};
  //联级数据
  selectOptions: Array<IMatchType> = [];

  created() {
    this.getType();
  }

  /**
   * 获取联级选择
   */
  async getType() {
    const res = await Service.Common.getLeagueCodeName<Array<IMatchType>>();
    if (!(res instanceof Error)) {
      this.selectOptions = res;
    }
  }

  /**
   * 联级选择操作
   */
  handleChange(e: Array<string>) {
    this.$emit(
      'change',
      e
        .map(v => {
          return v[v.length - 1];
        })
        .join(',')
    );
  }
}
