
import {Options, Vue} from 'vue-class-component';
import leftSide from '@/web/layout/route';
import {Watch} from 'vue-property-decorator';
import {App, Format} from '@/services';
import Prompt from './prompt.vue';
import {ElButton, ElDialog} from 'element-plus';
import {userCard} from '@/services/server/web/bank';
import {ICard, ICardResult} from '@/model/bank';
import {filterConlon} from '@/web/plugins/changeCase';
import Wallet from '@/common/web/wallet/index.vue';
import {IWallet} from '@/model/transfer';
import countTo from '@/common/web/countTo/index.vue';
import eventBus from '@/web/eventBus';
import AccountTakeCash from '@/web/operation/takeCash/index.vue';

interface LoginParamsType {
  username: '';
  password: '';
}
@Options({
  components: {Prompt, ElButton, Wallet, AccountTakeCash, ElDialog, countTo}
})
export default class Left extends Vue {
  userInfo = App.getUserinfo();
  side = leftSide;
  menuActive = 0;
  nowLang = localStorage.getItem('language') || 'th';
  cardList: ICard[] = [];
  reloadMenu = true;
  rotate = false;
  isFetch = false;
  wallet: IWallet = {
    centerWallet: '',
    gameBalance: '',
    lockBalance: '',
    savingBalance: '',
    sportBalance: '',
    totalAmount: ''
  };
  openAccountTakeCash = false;
  unread = 0;
  defaultActive = '1';
  @Watch('$route')
  changeRouter() {
    this.fixSide();
  }
  get computedAmount() {
    return (
      Math.floor(
        (+this.wallet.totalAmount.replace(/,/g, '') +
          +this.wallet.savingBalance.replace(/,/g, '') +
          +this.wallet.lockBalance.replace(/,/g, '')) *
          100
      ) / 100
    );
  }
  created() {
    eventBus.bus$on('publish-unread', (val: number) => {
      this.unread = val;
    });
    eventBus.bus$on('langChange', () => {
      this.nowLang = localStorage.getItem('language') || 'th';
    });
    // 储存一下用户名  只有这些用户我们才显示任务列表页面
    const names = ['dnick001', 'ook0909', 'ProdAgent30', 'Sportpt01', 'Sportpt02'];
    const filterSide = leftSide.filter(
      item =>
        (item.name === 'tasklist' && names.find(_item => _item === this.userInfo?.userAccount)) ||
        item.name !== 'tasklist'
    );
    this.side = filterSide;
    this.fixSide();
  }

  beforeDestroy() {
    eventBus.bus$off('langChange');
    eventBus.bus$off('publish-unread');
  }

  async mounted() {
    const res = await userCard.read<ICardResult>({});
    console.log('res: ', res);
    if (res instanceof Error) {
      return res;
    } else {
      this.cardList = res.list;
    }
  }
  filterConlon = filterConlon;
  formatNumber = Format.formatNumber;
  upperCase = Format.upperCase;
  fixSide() {
    this.menuActive = this.side.findIndex(v => {
      return v.name === this.checkMenu();
    });
  }
  goToLink(name: string) {
    console.log('name: ', name);
    console.log(this.$router, this.$route);
    this.$router.push({name});
  }
  handleOpen() {
    console.log(123);
  }
  handleClose() {
    console.log(123);
  }
  reloadTotalAmount() {
    this.$nextTick(() => {
      eventBus.bus$emit('fetchWallet', true);
      this.isFetch = true;
    });
    this.rotate = !this.rotate;
  }
  handleWallet(wallet: IWallet) {
    this.wallet = wallet;
    this.isFetch = false;
  }
  onError(evt: any) {
    console.log({evt});
    const el = evt.target;
    el.style.display = 'none';
  }
  getIcon(name: string) {
    try {
      return require(`../../assets/web/image/usermenu/${name}.svg`);
    } catch (err) {
      return 'javascript:void(0);';
    }
  }
  activeRouter(menu: {name: string}) {
    this.$router.push({name: menu.name});
  }
  checkMenu() {
    // return this.$route.matched.find(val => {
    let _name = this.$route.name;
    switch (this.$route.name) {
      case 'CreateUser':
      case 'LinkedUser':
        _name = 'CreateUser';
        break;
      case 'Notice':
      case 'NoticeDetail':
      case 'Inbox':
      case 'InboxDetail':
      case 'NewPlacardDetail':
        _name = 'NewPlacard';
        break;
      case 'ProxyReport':
      case 'ProxyReigster':
      case 'ProxyEverday':
      case 'ProxyOldBoy':
        _name = 'RedEnvelope';
        break;
      case 'topicList':
      case 'participateUser':
        _name = 'askSpread';
        break;
    }
    return _name;
    // });
  }
  closebtn() {
    this.openAccountTakeCash = false;
  }
}
