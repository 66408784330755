<template>
  <div class="wrapper-center background-day">
    <wallet-view v-if="!stepEnd" @finish-wallet="handleWallet" />

    <div v-if="promptPage === 'check4'">
      <div class="gy_list-desc">
        <div class="gy_list-desc__title" v-if="!['H3030'].includes(info.errorMessage['code'])">
          <span v-text="info.errorMessage.message"></span>
        </div>
        <div class="gy_list-desc__title" v-else>
          <span>{{ $t('btn_go_to_bind_card') }}</span>
        </div>
      </div>

      <div
        class="u_t--center u_m--t50 u_m--b50"
        v-if="
          !['H7019'].includes(info.errorMessage['code']) &&
            !['H7022'].includes(info.errorMessage['code'])
        "
        style="width: 60%;margin: auto;"
      >
        <button type="button" class="main-btn" @click="goErrorStep" style="cursor:pointer;">
          <span class="c_button__text">{{
            $t(info.errorMessage['code'] === 'H7002' ? 'btn_go_to_bind_card' : 'btn_add_binding')
          }}</span>
        </button>
      </div>
    </div>
    <div v-if="promptPage === 'check5'">
      <div class="">
        <div class="gy_list-desc__title u_w--100p">
          <p>> {{ $t('tip_withdraw_limit') }}</p>
        </div>
      </div>
      <div class="gy_list-detail__form u_m--b10">
        <div class="gy_form__field">
          <div class="gy_form__title">{{ $t('ui_withdraw_amount_allow_colon') }}</div>
          <div>{{ formatNumber(wallet.centerWallet, 0) }}</div>
        </div>
        <div class="gy_form__field">
          <div class="gy_form__title">{{ $t('ui_min_withdraw_colon') }}</div>
          <div>{{ info.loadMin }}</div>
        </div>
      </div>
    </div>
    <div v-if="promptPage === 'check7'">
      <!-- promptPage === 'check7 -->
      <div class="gy_list-desc">
        <div class="gy_list-desc__title u_w--100p u_fw--bold u_fz--medium">
          > <span v-html="frequencyNoteText"></span>
        </div>
      </div>
      <div class="gy_list-detail__form u_w--100p">
        <div class="gy_form__field">
          <div class="gy_form__title">{{ $t('ui_user_acc_colon') }}</div>
          <div>{{ userinfo.userAccount }}</div>
        </div>
        <!-- <div class="gy_form__field" v-if="config.dollarSign == '¥'">
          <div class="gy_form__title">{{ $t('ui_open_acc_name_colon') }}</div>
          <div>
            <el-input
              class="c_input--longest"
              v-model="info.realName"
              :placeholder="$t('ui_please_enter_the_account_name')"
            ></el-input>
          </div>
        </div> -->
        <!-- <div class="gy_form__field" v-if="(config.dollarSign != '¥') & (nowLang == 'zh-CN')">
          <div class="gy_form__title">{{ $t('ui_last_name') }} :&nbsp;&nbsp;</div>
          <div>
            <el-input
              class="c_input--longest"
              v-model="info.lastName"
              :placeholder="$t('ui_input_last_name')"
            ></el-input>
          </div>
        </div>
        <div class="gy_form__field" v-if="config.dollarSign != '¥'">
          <div class="gy_form__title">{{ $t('ui_first_name') }} :&nbsp;&nbsp;</div>
          <div>
            <el-input
              class="c_input--longest"
              v-model="info.firstName"
              :placeholder="$t('ui_input_first_name')"
            ></el-input>
          </div>
        </div> -->
        <!-- <div class="gy_form__field" v-if="(config.dollarSign != '¥') & (nowLang != 'zh-CN')">
          <div class="gy_form__title">{{ $t('ui_last_name') }} :&nbsp;&nbsp;</div>
          <div>
            <el-input
              class="c_input--longest"
              v-model="info.lastName"
              :placeholder="$t('ui_input_last_name')"
            ></el-input>
          </div>
        </div> -->
        <div class="gy_form__field">
          <div class="gy_form__title">{{ $t('ui_withdraw_amount_allow_colon') }}</div>
          <div>{{ formatNumber(wallet.centerWallet, 0) }}</div>
        </div>
        <div class="gy_form__field">
          <div class="gy_form__title">{{ $t('ui_hint_withdraw_amount') }}：</div>
          <div>
            <el-input class="c_input--longest" type="text" v-model="info.amount" />
            <!-- <span>{{$t('ui_dollar')}}</span> -->
            <el-button
              class="u_main--btn u_m--l20"
              type="info"
              v-text="$t('ui_maximum')"
              @click="setMax"
            ></el-button>
          </div>
        </div>
        <div class="gy_form__field">
          <div class="gy_form__title"></div>
          <div>
            <span class="u_o--05" v-html="noteText"></span>
          </div>
        </div>
        <div class="gy_form__field">
          <div class="gy_form__title">{{ $t('ui_withdraw_card_colon') }}</div>
          <div>
            <el-select
              class="c_input--longest"
              v-model="info.selectBankId"
              @change="changeBank(info.selectBankId)"
              style="display: block;"
            >
              <el-option
                v-for="item in info.columns"
                :key="item.cardId"
                :value="item.cardId"
                :label="item.bindingBank + item.accountNumber"
              />
            </el-select>
          </div>
        </div>
        <template v-if="verifyCode">
          <div class="gy_form__field">
            <div class="gy_form__title">{{ $t('ui_mobile_phone_number') }}</div>
            <div class="u_p--l15" v-if="userinfo.phoneNumber.verification">
              {{ userinfo.phoneNumber.text }}
            </div>
            <tel-input
              v-else
              ref="tel"
              :value="info.phone"
              :preferredCountries="['VN', 'TH']"
              @validate="handeValidate"
              class="j_background--day-t5 telinput c_input--longest"
            ></tel-input>
            <div class="u_m--l10">
              <el-button
                round
                class=""
                icon=""
                :type="'info'"
                size="small"
                :hairline="true"
                @click="beforeCheckSms"
                v-text="(info.verPhoneWaitTime || $t('ui_send_verification')) + ''"
                :disabled="+info.verPhoneWaitTime > +0"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-button>
            </div>
          </div>
          <div class="gy_form__field inboder">
            <div class="gy_form__title">{{ $t('ui_sms_verification_code') }}</div>
            <el-input
              :class="[!info.phoneCode ? ' noneinput ' : ' hasinput']"
              class="c_input--longest clearpadding"
              v-model="info.phoneCode"
              clearable
              type="text"
              :placeholder="'! ' + $t('ui_please_enter_phone_verification_code')"
              :error-message="$t('')"
              oninput="value=value.replace(/[^\d]/g,'')"
            >
            </el-input>
          </div>
        </template>
        <div class="gy_form__field u_t--center u_m--t50 u_m--b50">
          <div class="gy_form__title"></div>
          <button
            type="button"
            class=" main-btn u_m-t38"
            v-loading="buttonloading"
            @click="goNextStep"
          >
            <span class="c_button__text">{{ $t('btn_next') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="stepEnd">
      <div class="gy_list-detail__form u_m--t20 u_w--100p">
        <div class="gy_form__field">
          <div class="gy_form__title u_p--l10 u_p--t10">
            {{ $t('ui_user_acc_colon') }}
          </div>
          <div class="u_p--l10 u_p--t10">{{ userinfo.userAccount }}</div>
        </div>
        <div class="gy_form__field">
          <div class="gy_form__title u_p--l10">
            {{ $t('ui_withdraw_amount_colon') }}
          </div>
          <div class="u_p--l10">{{ info.amount }}</div>
        </div>
        <div class="gy_form__field">
          <div class="gy_form__title u_p--l10">
            {{ $t('ui_open_acc_bank_colon') }}
          </div>
          <div class="u_p--l10">{{ info.selectBank.bindingBank }}</div>
        </div>
        <div class="gy_form__field">
          <div class="gy_form__title u_p--l10 u_p--b10">
            {{ $t('ui_bank_acc_colon') }}
          </div>
          <div class="u_p--l10 u_p--b10">{{ info.selectBank.accountNumber }}</div>
        </div>
      </div>
      <div class="u_t--center u_m--t50 u_m--b50 controller-btn">
        <button type="button" class="btn-bg--base main-btn" @click="checkback">
          <span class="c_button__text">{{ $t('btn_back') }}</span>
        </button>
        <button type="submit" class=" main-btn" @click="submitRecharge">
          <span class="c_button__text">{{ $t('btn_check_withdraw') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {App, Format, Storage} from '@/services';
import {GetUser} from '@/model/tokenGetUser';
import {GetCards, IBindingCard} from '@/model/bank';
import {Config} from '@/model/config';
import {IWithdrawalsResult} from '@/model/takecash';
import {IWallet} from '@/model/transfer';
import {Prop} from 'vue-property-decorator';
import eventBus from '@/web/eventBus';
import {takecash} from '@/services/server/web/takecash';
import {ElMessage, ElMessageBox, ElNotification} from 'element-plus';
import walletView from '@/common/web/wallet/wallet.vue';
import {IMessage} from '@/model/bank';
import {phoneVerification} from '@/services/server/web/phone-verification';
import TelInput from '@/common/web/telInput/index.vue';

let timer: any;

const unitMap = new Map([
  ['USD', 'ui_dollar_usd'],
  ['VND', 'ui_dollar_vnd'],
  ['THB', 'ui_dollar_thb'],
  ['CNY', 'ui_dollar_cny']
]);

@Options({
  components: {
    walletView,
    TelInput
  },
  watch: {
    errorMessage(error) {
      console.log('debug: errorMessage -> val', error);
      error.message && ElMessage.error(error.message);
    }
  },
  methods: {
    handeValidate(val: {isValid: boolean}) {
      this.errors.phoneNumber = !val.isValid;
    },
    async submitRecharge() {
      console.log('submitRecharge: ');
      if (this.info.errorMsg) {
        return;
      }
      this.info.loading = true;

      this.info.loadingText = 'ui_sending'; // 传送中

      if (this.validated) {
        const params = {
          user_bank_id: this.info.bankId,
          amount: `${this.info.amount}`,
          real_name: ''
        };
        const handleName = new Map([['¥', this.info.realName]]);
        /* eslint-disable-next-line */
        params.real_name =
          handleName.get(this.config.dollarSign) || `${this.info.firstName} ${this.info.lastName}`;
        const data = await takecash.updated<{
          list: [];
          message: string;
        }>(params);
        if (data instanceof Error) {
          ElNotification({type: 'error', message: data.message});
          this.info.loading = false;
          return;
        } else if (data.message) {
          ElNotification({type: 'success', message: data.message});
        }
      }
      this.resetData();
      this.$nextTick(() => {
        // this.$router.replace({name: 'Home'});
        this.closepop();
        // this.$emit('close');
      });
    },
    resetData() {
      this.info = {
        ...this.info,
        sshowBanks: false,
        columns: [],
        bankName: '',
        bankCode: '',
        bankId: '',
        realName: '',
        firstName: '',
        lastName: '',
        dayQuota: '',
        initiatedQuota: '',
        initiatedFrequency: '',
        dayFrequency: '',
        showButton: false,
        errorMsg: '',
        buttonText: 'ui_loading',
        loading: false,
        loadingText: 'ui_loading',
        errorMessage: {
          code: '',
          message: ''
        },
        amount: '',
        loadMin: '',
        loadMax: '',
        selectBankId: '',
        selectBank: {
          bindingBank: '',
          accountNumber: '',
          bankCode: '',
          cardId: ''
        },
        phoneNumberMsg: '',
        phoneDisabled: true,
        phoneWaitTime: 300,
        phoneErrorWaitTime: 15,
        verPhoneWaitTime: 0,
        smsInterval: '',
        phoneCode: '',
        phone: '',
        phoneCodeMsg: ''
      };
    },

    onClick() {
      if (this.info.columns.length && !this.info.errorMsg) {
        this.submitRecharge();
      } else {
        let name = 'AddBankCard';
        if (['H3030', 'P3030'].includes(this.info.errorMessage['code'])) {
          // name = 'PhoneVerification';
        }
        if (['H7261', 'P7261'].includes(this.info.errorMessage['code'])) {
          name = 'PersonalInformation';
        }
        if (
          ![
            'H3030',
            'H7002',
            'H7261',
            'P7002',
            'P3030',
            'P7261',
            'H7020',
            'P7020',
            'P7022',
            'H7022'
          ].includes(this.info.errorMessage['code'])
        ) {
          ElMessage.error(this.info.errorMessage['message']);
          return;
        }
        this.$router.push({name});
      }
    },

    beforeCheckSms() {
      // console.log(this.$info.$_authMode, this.$_phoneNumber, this.)
      if (!this.userinfo.phoneNumber.verification && !this.phoneNumber) {
        this.info.phoneNumberMsg = 'ui_phone_not_entered';
        return false;
      } else {
        this.checkSMS();
      }
    },
    async checkback() {
      await GetUser.action();
      this.userinfo = App.getUserinfo();
      this.stepEnd = false;
    },
    async checkSMS() {
      if (this.userinfo?.phoneNumber.verification) {
        this.showWaitTime();
        //已認證
        const data = await phoneVerification.read<IMessage>({});
        if (data instanceof Error) {
          ElMessage({
            type: 'error',
            message: data.message || 'fail'
          });
        } else {
          ElMessage({
            type: 'success',
            message: data.message || 'success'
          });
        }
        return data;
      } else {
        //未認證
        const data = await this.addSMS();
        return data;
      }
    },
    async addSMS(phone: string) {
      !phone && (phone = this.phoneNumber);
      this.showWaitTime();
      return await phoneVerification
        .created({
          /* eslint-disable-next-line */
          phone_number: phone
        })
        .then((response: any) => {
          console.log('response: ', response);
          // this.$ElMessage.success(info.message)
          ElMessage({
            type: 'success',
            message: response?.message || 'success'
          });
          // this.changePhoneStatus(3);
        })
        .catch(error => {
          // this.$ElMessage.fail(error.message)
          ElMessage({
            type: 'error',
            message: error.message || 'fail'
          });
          this.info.verPhoneWaitTime = this.info.phoneErrorWaitTime;
          return error;
        });
    },

    showWaitTime() {
      this.info.verPhoneWaitTime = this.info.phoneWaitTime;
      this.info.smsInterval = setInterval(() => {
        this.info.verPhoneWaitTime = +this.info.verPhoneWaitTime - 1;
        if (+this.info.verPhoneWaitTime === 0) {
          clearInterval(this.info.smsInterval);
        }
      }, 1000);
    }
  },
  computed: {
    dollarIcon() {
      const code = this.config.currency ? this.config.currency.code : 'CNY';
      return require(`@/assets/wap/money-${code}.svg`);
    },
    moneyMessage() {
      const amount = this.info.amount;
      if (+amount !== (+amount | 0)) {
        return 'ui_can_only_claim_whole_amount'; // '仅可领取整数金额'
      }
      return (+amount >= +this.info.loadMin && +amount <= +this.info.loadMax) || amount === ''
        ? ''
        : `${this.$t('ui_hint_withdraw', [
            Format.formatNumber(this.info.loadMin, 0) || '--',
            Format.formatNumber(this.info.loadMax, 0) || '--'
          ]).replace('{dollar}', this.$t(unitMap.get(this.config.currency.code || 'CNY')))}`;
    },
    buttonSetting() {
      const setting = {
        icon: 'warning',
        type: 'default',
        disabled: true
      };
      if (this.info.errorMsg) {
        if (
          ['H3030', 'H7002', 'P3030', 'P7002'].includes(this.info.errorMessage['code']) ||
          /btn_go_to_bind_card/.test(this.info.buttonText)
        ) {
          setting.icon = 'cash-back-record';
          setting.type = 'info';
          setting.disabled = false;
          this.info.showButton = true;
        }
        if (['H7261', 'P7261'].includes(this.info.errorMessage['code'])) {
          setting.disabled = false;
          this.info.showButton = true;
        }
        //
      } else if (
        this.info.columns.length &&
        !this.info.errorMsg &&
        this.validated &&
        !this.info.loading
      ) {
        setting.icon = 'cash-back-record';
        setting.type = 'info';
        setting.disabled = false;
      } else if (!this.info.columns.length && !this.info.loading) {
        setting.icon = 'card';
        setting.type = 'info';
        setting.disabled = false;
      }
      return setting;
    },
    placeholder() {
      const msgMap = new Map([
        [
          'zh-CN',
          `${this.$t('ui_please_enter_the_withdrawal_amount')} ${this.$t('ui_scope_yuan', {
            dollar: this.$t(unitMap.get(this.config.currency.code || 'CNY'))
          })} ${this.info.loadMin ? Format.formatNumber(this.info.loadMin, 0) : '--'} - ${
            this.info.loadMax ? Format.formatNumber(this.info.loadMax, 0) : '--'
          }`
        ],
        [
          'th',
          `${this.$t('ui_please_enter_the_withdrawal_amount')} ${
            this.info.loadMin ? Format.formatNumber(this.info.loadMin, 0) : '--'
          } - ${this.info.loadMax ? Format.formatNumber(this.info.loadMax, 0) : '--'} ${this.$t(
            'ui_scope_yuan',
            {
              dollar: this.$t(unitMap.get(this.config.currency.code || 'CNY'))
            }
          )}`
        ],
        [
          'en',
          `${this.$t('ui_please_enter_the_withdrawal_amount')} ${this.$t('ui_scope_yuan', {
            0: `${this.info.loadMin ? Format.formatNumber(this.info.loadMin, 0) : '--'} - ${
              this.info.loadMax ? Format.formatNumber(this.info.loadMax, 0) : '--'
            }`,
            dollar: this.$t(unitMap.get(this.config.currency.code || 'CNY'))
          })}`
        ],
        [
          'vn',
          `${this.$t('ui_scope_yuan', {
            0: `${this.info.loadMin ? Format.formatNumber(this.info.loadMin, 0) : '--'} - ${
              this.info.loadMax ? Format.formatNumber(this.info.loadMax, 0) : '--'
            }`,
            dollar: this.$t(unitMap.get(this.config.currency.code || 'CNY'))
          })}`
        ]
      ]);
      console.log(this.nowLang, 'this.nowLang', unitMap.get(this.config.currency.code || 'CNY'));
      console.log(msgMap.get(this.nowLang), 'msgMap.get(this.nowLang);');
      return msgMap.get(this.nowLang);
    },
    validated() {
      if (this.info.bankId !== '' && this.info.amount !== '' && this.moneyMessage === '') {
        return true;
      }
      return false;
    }
  }
})
export default class SecurityCenter extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥'
  };
  upperCase = Format.upperCase;
  formatNumber = Format.formatNumber;
  userinfo = App.getUserinfo();
  verifyCode = false;
  cardNumber = 0;
  wallet: {
    centerWallet: string;
    gameBalance: string;
    lockBalance: string;
    savingBalance: string;
    sportBalance: string;
    totalAmount: string;
  } = {
    centerWallet: '',
    gameBalance: '',
    lockBalance: '',
    savingBalance: '',
    sportBalance: '',
    totalAmount: ''
  };
  stepEnd = false;
  buttonloading = false;
  info: {
    showBanks: boolean;
    columns: IBindingCard[];
    bankName: string;
    bankCode: string;
    bankId: string | number;
    realName: string;
    firstName: string;
    lastName: string;
    dayQuota?: string;
    initiatedQuota: string;
    dayFrequency: string;
    initiatedFrequency: string;
    showButton: boolean;
    errorMsg: string;
    buttonText: string;
    loading: boolean;
    loadingText: string;
    errorMessage: {
      code: string;
      message: string;
    };
    amount: string;
    loadMin: string;
    loadMax: string;
    selectBankId: string;
    selectBank: {
      bindingBank: string;
      accountNumber: string;
      bankCode: string;
      cardId: string;
    };
    phoneNumberMsg: string;
    phoneDisabled: boolean;
    phoneWaitTime: number;
    phoneErrorWaitTime: number;
    verPhoneWaitTime: number;
    smsInterval: string;
    phoneCode: string;
    phone: string;
    phoneCodeMsg: string;
  } = {
    showBanks: false,
    columns: [],
    bankName: '',
    bankCode: '',
    bankId: '',
    realName: '',
    firstName: '',
    lastName: '',
    dayQuota: '',
    initiatedQuota: '',
    initiatedFrequency: '',
    dayFrequency: '',
    showButton: false,
    errorMsg: '',
    buttonText: 'ui_loading',
    loading: false,
    loadingText: 'ui_loading',
    errorMessage: {
      code: '',
      message: ''
    },
    amount: '',
    loadMin: '',
    loadMax: '',
    selectBankId: '',
    selectBank: {
      bindingBank: '',
      accountNumber: '',
      bankCode: '',
      cardId: ''
    },
    phoneNumberMsg: '',
    phoneDisabled: true,
    phoneWaitTime: 300,
    phoneErrorWaitTime: 15,
    verPhoneWaitTime: 0,
    smsInterval: '',
    phoneCode: '',
    phone: '',
    phoneCodeMsg: ''
  };
  nowLang = localStorage.getItem('langrage');

  @Prop()
  passWallet!: IWallet;

  get promptPage() {
    if (!this.stepEnd) {
      this.info.loading = true;
    }
    if (Number(this.wallet.centerWallet.replace(/,/g, '')) < Number(this.info.loadMin)) {
      this.info.loading = false;
      return 'check5';
    }
    if (this.info.errorMessage.code && this.info.errorMessage.message) {
      this.info.loading = false;
      return 'check4';
    }
    if (+this.info.dayFrequency >= +this.info.initiatedFrequency && !this.stepEnd) {
      if (
        Object.values(this.info.selectBank).filter(v => v).length === 0 &&
        +this.info.dayFrequency !== +this.info.initiatedFrequency
      ) {
        this.changeBank(this.info.columns[0].cardId);
      }
      this.info.loading = false;
      return 'check7';
    }
    return null;
  }
  get frequencyNoteText() {
    return this.$t('ui_hint_withdraw_limit', [
      `<span class="highlight">${this.info.dayFrequency}</span>`,
      `<span class="highlight">${this.info.initiatedFrequency}</span>`
    ])
      .replace(/%n%/, `<span class="highlight">${this.info.dayFrequency}</span>`)
      .replace(/%n%/, `<span class="highlight">${this.info.initiatedFrequency}</span>`);
  }
  get noteText() {
    return this.$t('ui_hint_withdraw', [
      `<span class="highlight">${Format.formatNumber(this.info.loadMin, 0)}</span>`,
      `<span class="highlight">${Format.formatNumber(this.info.loadMax, 0)}</span>`
    ])
      .replace(/%n%/, `<span class="highlight">${Format.formatNumber(this.info.loadMin, 0)}</span>`)
      .replace(
        /%n%/,
        `<span class="highlight">${Format.formatNumber(this.info.loadMax, 0)}</span>`
      );
  }

  async mounted() {
    this.info = {
      ...this.info,
      showBanks: false,
      columns: [],
      bankName: '',
      bankCode: '',
      bankId: '',
      realName: '',
      firstName: '',
      lastName: '',
      dayQuota: '',
      initiatedQuota: '',
      initiatedFrequency: '',
      dayFrequency: '',
      showButton: false,
      errorMsg: '',
      buttonText: 'ui_loading',
      loading: false,
      loadingText: 'ui_loading',
      errorMessage: {
        code: '',
        message: ''
      },
      amount: '',
      loadMin: '',
      loadMax: '',
      selectBankId: '',
      selectBank: {
        bindingBank: '',
        accountNumber: '',
        bankCode: '',
        cardId: ''
      }
    };
    let userConfig: any = Storage.get('user_config');
    userConfig = userConfig ? JSON.parse(userConfig) : {};
    this.verifyCode = userConfig.withdrawals_check_phone_number === '1';
    await GetUser.action();
    this.userinfo = App.getUserinfo();
    this.nowLang = Storage.get('language') || 'zh-CN';
    console.log("Storage.get('language'): ", Storage.get('language'));
    this.info.loading = true;
    const [cardsInfo, takecashInfo] = await Promise.all([
      GetCards.read(),
      takecash.read<IWithdrawalsResult>()
    ]);
    if (cardsInfo instanceof Error) {
      this.info.errorMessage = {
        code: cardsInfo.name,
        message: cardsInfo.message
      };
    } else {
      this.cardNumber = cardsInfo.cards;
      this.info.columns = cardsInfo.list.map((v: IBindingCard) => {
        v.text = `${v.bindingBank} (${v.accountNumber})`;
        v.dom = `<span class="icon bank-img bank__icon-${v.bankCode}"></span><span>${v.bindingBank} - ${v.accountNumber}</span>`;
        return v;
      });
    }
    if (takecashInfo instanceof Error) {
      if (!this.info.errorMessage.code) {
        this.info.errorMessage = {
          code: takecashInfo.name,
          message: takecashInfo.message
        };
      }
    } else {
      console.log(takecashInfo, 'takecashInfo');
      this.info.initiatedQuota = takecashInfo.initiatedQuota;
      this.info.initiatedFrequency = takecashInfo.initiatedFrequency;
      this.info.dayFrequency = takecashInfo.dayFrequency;
      this.info.dayQuota = takecashInfo.dayQuota;
      this.info.loadMin = takecashInfo.loadMin;
      this.info.loadMax = takecashInfo.loadMax;
    }
    setTimeout(() => {
      this.info.loading = false;
    }, 1000);
    if (this.handleResponse([cardsInfo, takecashInfo])) {
      // setTimeout(this.init);
      this.wallet = {
        ...this.wallet,
        ...this.passWallet
      };
      if (this.wallet.centerWallet) {
        eventBus.bus$emit('fetchWallet', true);
      }
    }
  }
  handleResponse(responseList: any) {
    console.log('responseList: ', responseList);
    let state = true;
    if (responseList[0] instanceof Error || responseList[1] instanceof Error) {
      ElMessage({
        type: 'error',
        message: this.info.errorMessage.message
      });
    }
    if (responseList[0] && responseList[0].message) {
      this.info.errorMsg = responseList[0].message.replace(/^f_/, '');
      this.info.buttonText = 'btn_go_to_bind_card'; // 前往绑定银行卡
      this.info.showButton = true;
      state = false;
    } else if (responseList[1] && responseList[1].message) {
      this.info.errorMsg = this.info.buttonText = responseList[1].message.replace(/^f_/, ''); //其他錯誤訊息
      this.info.showButton = true;
      state = false;
    } else {
      this.info.buttonText = 'btn_withdraw_now'; // 立即取款
      // this.showButton = false
      state = true;
    }
    // document.title = this.$t('btn_withdraw')
    return state;
  }
  /**
   * router handler
   * name to route name
   */
  termClick(name: string) {
    this.$router.push({name});
  }
  inputClick() {
    this.info.showBanks = true;
  }

  changeBank(value: string) {
    this.info.selectBankId = value;
    this.info.bankId = value;
    const bank = this.info.columns.filter(v => v.cardId === value);
    this.info.selectBank =
      bank.length > 0 ? bank[0] : {bindingBank: '', accountNumber: '', bankCode: '', cardId: ''};
  }

  async goNextStep() {
    const opts = {
      confirmButtonText: this.$t('btn_confirm'),
      title: this.$t('ui_hint'),
      message: 'Error',
      zIndex: 3000
    };

    if (!this.info.amount) {
      ElMessageBox({
        ...opts,
        message: this.$t('ui_hint_withdraw_amount')
      });
      return;
    }
    if (+this.info.amount < +this.info.loadMin) {
      ElMessageBox({
        ...opts,
        message: this.$t('ui_text_0089')
      });
      return;
    }
    if (+this.info.amount > +this.info.loadMax) {
      ElMessageBox({
        ...opts,
        message: this.$t('ui_text_0088')
      });
      return;
    }
    if (+this.info.amount > +this.wallet.centerWallet) {
      ElMessageBox({
        ...opts,
        message: this.$t('tip_withdraw_not_enough')
      });
      return;
    }
    if (!this.info.selectBank.cardId) {
      ElMessageBox({
        ...opts,
        message: this.$t('sysmsg_bank_close')
      });
      return;
    }

    if (this.config.dollarSign !== '¥') {
      this.info.realName = this.info.firstName + ' ' + this.info.lastName;
    }
    // 通过配置验证短信验证码
    let data: any = true;
    if (this.verifyCode) {
      this.buttonloading = true;
      data = await this.bindSMS();
      this.buttonloading = false;
    }
    if (data) {
      this.stepEnd = true;
    } else {
      this.stepEnd = false;
    }
    // this.stepEnd = !!data;
  }

  async bindSMS() {
    // let number = phoneUtil.parseAndKeepRawInput(this.$info.$_phone, this.$info.$_countryCode)
    const data = await phoneVerification.updated({
      // phone_number: phoneUtil.format(number, PNF.E164),
      code: this.info.phoneCode
    });
    if (data instanceof Error) {
      ElMessage({
        type: 'error',
        message:
          this.$t('ui_verification_code_error') + '\r\n' + this.$t('ui_change_failed') || 'fail'
      });
      // console.log('info.message', info.message)
      // let message = this.$t('ui_verification_code_error') + '\r\n' + this.$t('ui_change_failed')
      // this.$ElMessage.fail(message || 'fail')
      return false;
    } else {
      // ElMessage({
      //   type: 'error',
      //   message:
      //     this.$t('ui_verification_code_is_correct') + '\r\n' + this.$t('ui_change_successful') ||
      //     'fail'
      // });
      //修改狀態
      await GetUser.action();
      this.userinfo = App.getUserinfo();
    }
    return data;
  }
  onIpnut() {
    timer && (clearTimeout(timer), (timer = null));
    this.info.showButton = false;
    timer = setTimeout(() => {
      this.info.showButton = true;
      const scrollTimer = setInterval(() => {
        clearInterval(scrollTimer);
      }, 10);
    }, 800);
  }
  goErrorStep() {
    // this.direct(this.withdrawalsStepInfo.error === 'H7002' ? 'SecurityCard' : 'UserInfo')
    // this.AccountTakeCash = ture ? this.AccountTakeCash = false : this.direct('AddCard')
    this.$router.push({name: 'AddCard'});
    this.closepop();
  }
  closepop() {
    this.$emit('close');
  }
  setMax() {
    let max = this.info.loadMax;
    if (parseInt(this.wallet.centerWallet.replace(/,/g, '')) < parseInt(this.info.loadMax)) {
      max = this.wallet.centerWallet;
    }
    console.log();
    this.info.amount = (parseInt(max) | 0) + '';
  }
  handleWallet(wallet: IWallet) {
    this.wallet = wallet;
  }
}
</script>
<style lang="scss" scoped>
.text-day {
  color: $cf;
}
.text-gold {
  color: $gold;
}
.text-darkgray {
  color: $c9;
}
.background-primary {
  background-color: $bg;
}
.background-day {
  background-color: $cf;
}
.wrapper-center {
  background-color: #f6f7f8;
  height: 100%;
  font-size: 16px /* 16/25 */;
  .nav-right {
    font-size: 14px;
    color: #333333;
    line-height: 14px;
    margin-right: 12px;
    @extend .clickAction;
  }
}
.wallet-card {
  @extend .flex;
  @extend .flex-column;
  @extend .flex-center;
  @extend .flex-justify-center;
  color: $cf;
  flex: 1;
  margin: 24px auto;
  height: 88px /* 88/25 */;
  .title {
    font-size: 12px /* 12/25 */;
    padding-bottom: 8px; /* 8/25 */
  }
  .count {
    font-size: 20px /* 20/25 */;
    font-weight: bold;
  }
  + .wallet-card {
    border-left: 1px /* 1/25 */ solid rgba(255, 255, 255, 0.2);
  }
}

.bank-img.icon {
  width: 22px /* 22/25 */;
  height: 22px /* 22/25 */;
  display: inline-flex;
}
.u_w--100p {
  width: 100%;
}
.u_fz--medium {
  font-size: 16px;
}
.u_fw--bold {
  font-weight: bold;
}
.panel {
  @extend .u_w--100p;
  > div {
    height: 2.04rem /* 51/25 */;
  }
}
.icon.money {
  width: 22px /* 22/25 */;
  height: 22px /* 22/25 */;
  line-height: 22px /* 22/25 */;
  text-align: center;
}
.main-btn {
  width: calc(100% - 50px);
  height: 48px;
  color: $cf;
  background-color: $bg;
  border-radius: 8px /* 8/25 */;
  font-size: 16px;
  border-width: 0;
  &.u_m-t38 {
    margin-top: 38px /* 38/25 */;
  }
}
.u_t--center {
  text-align: center;
}
.gy_list-desc {
  padding: 20px 0 10px;
}
.gy_list-desc__title {
  display: inline-block;
  line-height: 160%;
  max-width: calc(100% - 30px);
  padding: 7px 0;
}
.gy_list-detail__form {
  display: table;
  .gy_form__title {
    padding-right: 10px;
    white-space: nowrap;
    width: 80px;
    text-align: left;
  }
  .el-input {
    vertical-align: middle;
    width: 80%;
  }
  .gy_form__field {
    width: 820px;
    display: table-row;
    border-collapse: separate;
    border-spacing: 0 5px;
    > div {
      display: table-cell;
      vertical-align: middle;
      padding-top: 7px;
      padding-bottom: 7px;
      white-space: nowrap;
      &:not(.gy_form__title) {
        width: auto;
        max-width: 740px;
        word-break: break-word;
        white-space: break-spaces;
      }
    }
  }
}
.c_input--longest {
  width: 80%;
}
[class*='c_input'] {
  border-radius: 5px;
}
[class*='c_input'] button,
[class*='c_input'] > div,
[class*='c_input'] input,
[class*='c_input'] select,
[class*='c_input'] textarea,
[class*='c_input'] .c_input__inner {
  border-radius: inherit;
  width: inherit;
}

.el-button--info {
  min-width: 70px;
  font-size: 0.14rem;
  border-radius: 5px;
  background-color: #1f55f0 !important;
  border-color: #1f55f0 !important;
  &:hover {
    opacity: 0.8;
    background-color: #004cff;
    border-color: #004cff;
  }
}
.u_main--btn {
  height: 40px;
  line-height: 40px;
  padding: 0 10px !important;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    color: #ffffff;
    border-radius: 8px;
    background: #1f55f0;
    opacity: 0.5;
  }
}
.u_m--l20 {
  margin-left: 20px !important;
}

.c_input--longest :deep(.el-input__inner:valid) {
  border-color: transparent;
  color: #666666;
}
.c_input--longest :deep(.el-input__inner) {
  background-color: #f5f5f5;
  color: #cccccc;
}
.controller-btn {
  display: flex;
  justify-content: space-evenly;
  .main-btn {
    width: auto;
    flex: 1;
    margin: 10px;
    &.btn-bg--base {
      background: #e8e8e8;

      color: #999999;
    }
  }
}
</style>
