import Common from './common';
import Operation from './operation';
import Task from './task';
import SportsInformation from './sportsInformation';

export default {
  Common,
  Operation,
  Task,
  SportsInformation
};
