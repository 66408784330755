import doFetch from '../../fetch2';

/**
 * get leagues by pagination
 */
const getLeagues = async <T>(page: number, size: number) =>
  doFetch<T>(`/spider/league/getLeagueCodeName?page=${page}&size=${size}`, 'POST', undefined);

/**
 * get news by pagination
 */
const getNews = async <T>(data: any) =>
  doFetch<T>(`/spider/agentNews/getAgentNewsList`, 'GET', data);

/**
 * get news detail
 */
const getNewsDetail = async <T>(data: any) =>
  doFetch<T>(`/spider/agentNews/getAgentNewsDetail`, 'GET', data);

/**
 * set news top
 */
const setNewsTop = async <T>(data: any) =>
  doFetch<T>(`/spider/agentNews/getAgentNewsTop`, 'GET', data);

/**
 * get big v by pagination
 */
const getBigV = async <T>(data: any) =>
  doFetch<T>(`/spider/agentInformation/getAgentInformationList`, 'GET', data);

/**
 * get big v detail
 */
const getBigVDetail = async <T>(data: any) =>
  doFetch<T>(`/spider/agentInformation/getAgentInformationDetail`, 'GET', data);

/**
 * set big v top
 */
const setBigVTop = async <T>(data: any) =>
  doFetch<T>(`/spider/agentInformation/getAgentInformationTop`, 'GET', data);

/**
 * get leagues by pagination
 */
const getLeaguesList = async <T>(data: any) =>
  doFetch<T>(`/spider/league/getLeagueList`, 'GET', data);

/**
 * get matches by pagination
 */
const getMatchList = async <T>(data: any) => doFetch<T>(`/spider/match/getMatchList`, 'GET', data);

/**
 * get matches detail
 */
const getMatchDetail = async <T>(data: any) =>
  doFetch<T>(`/spider/match/getMatchDetails`, 'GET', data);

/**
 * set league top
 */
const setLeagueTop = async <T>(data: any) =>
  doFetch<T>(`/spider/league/getAgentLeagueTop`, 'GET', data);

/**
 * set match top
 */
const setMatchTop = async <T>(data: any) =>
  doFetch<T>(`/spider/match/getAgentMatchTop`, 'GET', data);

export default {
  getLeagues,
  getNews,
  getNewsDetail,
  getBigV,
  getBigVDetail,
  setBigVTop,
  setNewsTop,
  getLeaguesList,
  getMatchList,
  setLeagueTop,
  setMatchTop,
  getMatchDetail
};
