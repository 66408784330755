<template>
  <el-container class="main">
    <div class="bgs"></div>
    <el-header
      class="header disFlexb"
      :style="{background: `rgba(0, 0, 0, ${scroll > 0 ? 0.4 : 0.9})`, height: '80px'}"
    >
      <div class="header_m--auto">
        <div class="flex">
          <div class="logo">
            <img src="@/assets/web/aia.png" alt="" class="" />
          </div>

          <div class="Today ">{{ $t('today') }}</div>
          <div class="card">
            <div class="cardtitle">{{ $t('ui_new_registration') }}</div>
            <div class="cardbody">
              {{ result.newUser }}
            </div>
          </div>
          <div class="card">
            <div class="cardtitle">{{ $t('ui_text_012') }}</div>
            <div class="cardbody">
              {{ result.validUser }}
            </div>
          </div>
          <div class="card">
            <div class="cardtitle">{{ $t('ui_total_deposit') }}</div>
            <div class="cardbody">
              {{ result.deposit }}
            </div>
          </div>
          <div class="card">
            <div class="cardtitle">{{ $t('ui_total_withdrawal') }}</div>
            <div class="cardbody">
              {{ result.withdraw }}
            </div>
          </div>
          <div class="card">
            <div class="cardtitle">{{ $t('ui_text_013') }}</div>
            <div class="cardbody">
              {{ result.winLose }}
            </div>
          </div>
        </div>
        <div class="disFlex">
          <div class="flex u_m--r20 graybtn">
            {{ mconfig.dollarSign }}
            <div class="u_m--x10">
              <Wallet @finish-wallet="handleWallet" :isFetch="isFetch">
                <count-to
                  :duration="2000"
                  ref="counts"
                  :startVal="0 === computedAmount ? 100 : 0"
                  :endVal="computedAmount"
                  :decimals="2"
                ></count-to>
              </Wallet>
            </div>
            <div
              class="u_c--pointer"
              :class="[rotate ? 'go' : 'back']"
              @click.stop="reloadTotalAmount"
            >
              <i class="el-icon-refresh" />
            </div>
          </div>
          <el-badge :value="unread" :max="99" class="message" @click="$router.push('/msg')">
            <i class="el-icon-bell"></i>
          </el-badge>
          <div class="flex flex-column user">
            <span class="disFlex">
              <!-- <el-avatar src=""></el-avatar> -->
              <div class="user graybtn ">{{ userInfo.userAccount }}</div></span
            >
          </div>
          <div class="u_m--l1rem graybtn flex flex-center" style="padding: 0.5rem 1.25rem;">
            <Languague />
            <!-- <Languaguewap /> -->
          </div>
        </div>
      </div>
    </el-header>

    <el-container class="el-container-content">
      <el-aside width="245px">
        <Left />
      </el-aside>
      <el-main class="right-main">
        <transition name="el-fade-in-linear" mode="out-in">
          <router-view> </router-view>
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script lang="ts">
import {Server} from '@/services';
import {Options, Vue} from 'vue-class-component';
import Left from './left.vue';
import {ITodayOverview} from '@/model';
import {App, Format} from '@/services';
import Languague from '../languague/index.vue';
import eventBus from '@/web/eventBus';
import Wallet from '@/common/web/wallet/index.vue';
import {IWallet} from '@/model/transfer';
import countTo from '@/common/web/countTo/index.vue';

interface LoginParamsType {
  username: '';
  password: '';
}
@Options({
  components: {
    Left,
    Wallet,
    Languague,
    countTo
  }
})
export default class Login extends Vue {
  userInfo = App.getUserinfo();
  mconfig = App.getConfig() || {
    dollarSign: '¥'
  };
  unread = 0;
  scroll = 0;
  result = {};
  wallet: IWallet = {
    centerWallet: '',
    gameBalance: '',
    lockBalance: '',
    savingBalance: '',
    sportBalance: '',
    totalAmount: ''
  };
  rotate = false;
  isFetch = false;
  formatNumber = Format.formatNumber;
  handleWallet(wallet: IWallet) {
    this.wallet = wallet;
    this.isFetch = false;
  }
  get computedAmount() {
    return (
      Math.floor(
        (+this.wallet.totalAmount.replace(/,/g, '') +
          +this.wallet.savingBalance.replace(/,/g, '') +
          +this.wallet.lockBalance.replace(/,/g, '')) *
          100
      ) / 100
    );
  }
  reloadTotalAmount() {
    this.$nextTick(() => {
      eventBus.bus$emit('fetchWallet', true);
      this.isFetch = true;
    });
    this.rotate = !this.rotate;
  }
  created() {
    document.addEventListener('scroll', this.listenScroll);
    this.getUnRead();
    this.todayoverview();
  }
  async getUnRead() {
    const res = await Server.Web.Common.unread<any>();
    if (!(res instanceof Error)) {
      const result = res.list[0];
      this.unread = 0;
      for (const i in result) {
        this.unread += parseInt(result[i]);
      }
      eventBus.bus$emit('publish-unread', this.unread);
    }
  }
  async todayoverview() {
    const result = await Server.Web.Operation.getTodayOverview<ITodayOverview>();
    this.result = result;
  }
  listenScroll() {
    this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
  }
  beforeUnmount() {
    this.scroll = 0;
    window.removeEventListener('scroll', this.listenScroll);
  }
}
</script>

<style lang="scss" scoped>
.u_m--r20 {
  margin-right: 1.25rem;
}
.u_m--x10 {
  margin: 0 0.625rem;
}
.bgs {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #f9f9f9 url(../../assets/web/bglogin.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}
.main {
  ::v-deep(.el-main) {
    background: #fff;
  }
}
.header {
  transition: 1s;
  color: #c0c4cc;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100%;
  margin: 0 auto;
  height: 80px;
  .user {
    margin-left: 15px;
  }
}
.header_m--auto {
  display: flex;
  flex-direction: row;
  width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  height: 100%;
}
.message {
  font-size: 20px;
  cursor: pointer;
  ::v-deep(.el-badge__content--primary) {
    background-color: #0f47e6;
    border: 0;
  }
}
.el-container-content {
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  height: 100%;
}
.right-main {
  background: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.logo {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  img {
    width: 4rem;
    height: 2rem;
  }
}
.Today {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 1rem;
}
.card {
  border: 1px solid #fff;
  min-width: 88px;
  height: calc(100% - 4px);
  border-radius: 8px;
  text-align: center;
  margin: 4px 8px;
  .cardtitle {
    padding: 0 8px;
    width: 100%;
    height: 50%;
    background-color: #c5d5ff;
    border-radius: 6px 6px 0 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
  }
  .cardbody {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
    font-weight: 700;
    font-size: 24px;
  }
}
.u_m--l1rem {
  margin-left: 16px;
}
.back,
.go {
  transition: all 2s;
  cursor: pointer;
}
.go {
  transform: rotate(0deg);
}
.back {
  transform: rotate(180deg);
}
.graybtn {
  background: #3a3a3a;
  padding: 4px 20px;
  border-radius: 4px;
}
</style>
