import {Server} from '@/services';
import {IPage} from './app';

export interface IReport {
  id: number[];
  list: Array<IReportList>;
  message: string;
  calculate: {
    totalReward: string;
    pageReward: string;
  };
  pagination: IPage;
}
export interface IReportList {
  bet?: string | number;
  active: string | number;
  detail?: Array<IReportListDetail>;
  net_win_loss: string | number;
  platform_fee: string | number;
  register: string | number;
  win_loss: string | number;
  withdraw: string | number;
  topupNumber: string | number;
  topup: string | number;
  withdrawNumber: string | number;
}
export interface IReportListDetail {
  category_name: string;
  company_code: string;
  company_name: string;
  platform_fee: string;
  rate: string;
  win_loss: string;
}

export class Report {
  static getReportData<T>(page: number, type?: string) {
    const date = this.getLast(type);
    const params = {
      device: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      end_at: '',
      lang: 'zh_cn',
      page,
      // eslint-disable-next-line @typescript-eslint/camelcase
      page_limit: '10',
      // eslint-disable-next-line @typescript-eslint/camelcase
      start_at: '',
      ...date
    };

    return Server.Web.Common.getReport<T>(params);
  }
  static getWith<T>(page: number, type?: string) {
    const date = this.getLast(type);
    const params = {
      device: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      end_at: '',
      lang: 'zh_cn',
      page: page.toString(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      page_limit: '10',
      // eslint-disable-next-line @typescript-eslint/camelcase
      start_at: '',
      ...date
    };

    return Server.Web.Common.getWith<T>(params);
  }
  static getLast(type?: string) {
    const nowdays = new Date();
    let year = nowdays.getFullYear();
    let month: string | number = nowdays.getMonth();
    if (type === '2') {
      if (month == 0) {
        month = 12;
        year = year - 1;
      }
    } else {
      month = month + 1;
    }
    const myDate = new Date(year, month, 0);
    const m = month < 10 ? '0' + month : month;
    return {
      // eslint-disable-next-line @typescript-eslint/camelcase
      start_time: year + '-' + m + '-01',
      // eslint-disable-next-line @typescript-eslint/camelcase
      end_time: year + '-' + m + '-' + myDate.getDate()
    };
  }
}
