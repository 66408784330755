import doFetch from '../../fetch';
const ACCPATH = '/api/acc/front';

/**  8.1.1 發起提現訂單（步驟 1） */
// read: ApiFn('withdrawals/step'),
// 8.1.2 提交提現訂單（步驟 2）
// updated ApiFn('withdrawals/apply'),
/**
 *   8.1.1 發起提現訂單（步驟 1）
 *    8.1.2 提交提現訂單（步驟 2）
 * @param data
 */
const actions = {
  read: async <T>() => await doFetch<T>(`${ACCPATH}/v1/withdrawals/step`, 'POST', {}),
  updated: async <T>(data: object) =>
    await doFetch<T>(`${ACCPATH}/v1/withdrawals/apply`, 'POST', data)
};

export const takecash = actions;
