import {createI18n} from 'vue-i18n';
import zh from './zh/zh.json';

const language = localStorage.getItem('language');
const i18n = createI18n({
  locale: language || 'zh-CN',
  messages: {
    'zh-CN': zh
  },
  sync: true,
  silentTranslationWarn: true
});

const loadedLanguages = ['zh-CN']; // 我们的预装默认语言

const setI18nLanguage = (lang: string) => {
  i18n.global.locale = lang;
  // if (Array.isArray(document.querySelector('html'))) {
  //   document.querySelector('html').setAttribute('lang', lang);
  // }
  return lang;
};

export const loadLanguageAsync = (lang: string) => {
  if (!loadedLanguages.includes(lang) || !Object.keys(i18n.global.messages).includes(lang)) {
    return import(/* webpackChunkName: "lang-[request]" */ `./${lang}/${lang}.json`)
      .then(msgs => {
        console.log(i18n, msgs);
        i18n.global.setLocaleMessage(lang, msgs);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
      })
      .catch(err => {
        console.error(err, 'err');
      });
  }
  return Promise.resolve(setI18nLanguage(lang));
};

export default i18n;
