const gotoLogin = async () => {
  console.log('goto login');
};

const getUrlParameter = (rule: RegExp): string[] => {
  const path = window.location.pathname;
  const result = rule.exec(path);
  if (!result) {
    return [];
  }
  return result.slice(1);
};

export default {
  gotoLogin,
  getUrlParameter
};
