<template>
  <div>
    <div class="top cf pdc disFlexs">
      <div class="disFlexb">
        <span class="name" @click="toRoute('SecurityCenter')">{{ name }}</span>
        <span class="c-icons" @click="gotoMsgCenter">
          <span class="count fs">{{ msgcount > 99 ? '99+' : msgcount }}</span>
        </span>
      </div>
      <div class="notjice fs disFlex">
        <div class="news-box flex flex-row flex-center u_p-l24" id="menuHeadNewsBox">
          <div
            class="news-item over1"
            style="width: 12.24rem;"
            v-for="v in news"
            :key="v.msnd_id"
            @click="
              toRoute({
                name: 'MsgDetail',
                params: {
                  id: v.msnd_id,
                  type: 'announcement'
                }
              })
            "
          >
            {{ v.subject }}
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bg">
        <div class="sideList pdc">
          <div
            class="list fs c6 disFlex"
            v-for="(v, i) in jumpList"
            :key="i"
            @click="toRoute(v.url)"
            :class="{clickAction: v.url}"
          >
            <div class="disFlex list-column">
              <img :src="v.icon" alt="" class="icon" />
              {{ $t(v.name) }}
            </div>
            <div class="disFlex list-column">
              <span class="" v-if="v.value">
                {{ v.value }}
              </span>
              <span class="right-area disFlex" v-if="v.url">
                <span class="icon-right"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String
    },
    jumpList: {
      type: Array
    },
    msgcount: {
      type: String
    },
    news: {
      type: Array
    },
    fund: {
      type: Object
    }
  },
  data() {
    return {
      i: 0,
      timer: null
    };
  },
  // mounted() {
  //   const count = this.news.length;
  //   const ele = document.getElementById('menuHeadNewsBox');
  //   this.timer = setInterval(() => {
  //     if (ele) {
  //       if (this.i < count) {
  //         this.i = this.i + 1;
  //       } else {
  //         this.i = 0;
  //       }
  //       ele.style.transform = `translateY(${-this.i * 32}px)`;
  //     }
  //   }, 2000);
  // },
  unmounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  watch: {
    news: function() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      const count = this.news.length;
      const ele = document.getElementById('menuHeadNewsBox');
      const RootDOM = document.querySelector('html');
      const rootSize = window.getComputedStyle(RootDOM, null).getPropertyValue('font-size');
      console.log('rootSize: ', parseFloat(rootSize));
      this.timer = setInterval(() => {
        if (ele) {
          if (this.i < count - 1) {
            this.i = this.i + 1;
          } else {
            this.i = 0;
          }
          ele.style.transform = `translateX(calc(${-this.i * 12.24 * parseFloat(rootSize)}px))`;
        }
      }, 3000);
    }
  },
  methods: {
    gotoMsgCenter() {
      this.$router.push('/common/msgCenter');
    },
    toRoute(name) {
      if (name) {
        if (typeof name == 'string') {
          this.$router.push({name});
        } else {
          this.$router.push(name);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.top {
  background: $bg;
  height: 2.16rem;
  width: 12.24rem;
  padding-top: 2rem;
  font-size: 0.8rem;
  padding-bottom: 0.64rem;
  align-items: stretch;
  justify-content: space-between;
  .c-icons {
    background-image: url(../../../assets/wap/sidebar-notice.png);
    position: relative;
    .count {
      position: absolute;
      background: $rc;
      padding: 0.08rem 0.16rem;
      line-height: 1;
      border-radius: 100px;
      right: -0.32rem;
      top: -0.24rem;
    }
  }
  .disFlexb {
    width: 100%;
    height: 0.96rem;
  }
  .notjice {
    height: 0.96rem;
    background: rgba($color: #fff, $alpha: 0.2) url(../../../assets/wap/skew.png) no-repeat 0/0.96rem;
    border-radius: 0.16rem;
  }
}
.bottom {
  width: 12.24rem;
  padding: 0 $pdc 0.64rem;
  background-image: linear-gradient(#1f55f0, rgba($color: #1f55f0, $alpha: 0));
  .bg {
    border-radius: 0.48rem;
    overflow: hidden;
  }
  .sideList {
    background: $cf;
    border-bottom-left-radius: 0.48rem;
    border-bottom-right-radius: 0.48rem;
    .list {
      height: 1.28rem;
      justify-content: space-between;
      .icon {
        width: 1.28rem;
        height: 1.28rem;
        margin-right: 0.32rem;
      }
      .list-column {
        align-items: center;
      }
      &:not(:last-child) {
        margin-bottom: 0.32rem;
      }
      .right-area {
        flex-grow: 1;
        text-align: right;
        justify-content: flex-end;
        font-size: 0.56rem;
        margin-left: 0.16rem;
      }
      .icon-right {
        background: url(../../../assets/wap/right-allow.png) no-repeat 0/0.48rem;
        width: 0.48rem;
        height: 0.48rem;
        position: relative;
        bottom: -0.08rem;
      }
    }
  }
  .news {
    .left {
      width: 3.6rem;
      min-width: 3.6rem;
      text-align: center;
      color: #fff;
    }
    .right {
      line-height: 0.64rem;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      position: relative;
      color: rgba($color: #fff, $alpha: 0.6);
      height: 1.28rem;
      overflow: hidden;
      &::before {
        content: '';
        width: 1px;
        height: 0.96rem;
        background: rgba($color: #fff, $alpha: 0.2);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    height: 1.28rem;
    padding: 0.24rem 0;
  }
}

.news-box {
  transition: transform 0.2s ease-out;
  .news-item {
    height: 1.28rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
