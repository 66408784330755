
import {Options, Vue} from 'vue-class-component';
import {transfer} from '@/services/server/wap/transfer';
import {IWalletResult} from '@/model/transfer';
import {ElMessage} from 'element-plus';
import {Prop} from 'vue-property-decorator';
import {throttle} from 'throttle-debounce';
import eventBus from '@/web/eventBus';

@Options({})
export default class Wallet extends Vue {
  wallet = {};
  isloading = false;
  @Prop()
  isFetch!: boolean;

  handleRefreshHandleRefresh = () => {
    console.log('123');
  };
  mounted() {
    this.handleRefreshHandleRefresh = throttle(5000, true, this.handleRefresh);
    const getWallet = localStorage.getItem('getWallet') === 'false';
    eventBus.bus$on('fetchWallet', (val: boolean) => {
      if (val) {
        this.handleRefreshHandleRefresh();
      }
    });
    if (getWallet) {
      this.$nextTick(() => {
        this.handleRefreshHandleRefresh();
      });
    }
  }

  async handleRefresh() {
    //do Refresh
    this.isloading = true;
    localStorage.setItem('getWallet', String(this.isloading));
    const data = await transfer.read<IWalletResult>({
      // eslint-disable-next-line
      game_id: 0
    });
    this.isloading = false;
    localStorage.setItem('getWallet', String(this.isloading));
    if (data instanceof Error) {
      ElMessage({
        type: 'error',
        message: data.message
      });
    } else {
      this.wallet = data.list;
    }
    this.$emit('finish-wallet', this.wallet);
    return;
  }
}
