import doFetch from '../../fetch_mainland';

/**
 * /**
 * 36.2.1 任务列表

 * @param data
 */
const tasklist = async <T>(data: any) => await doFetch<T>('/api/Agent/agentTaskList', 'POST', data);
/**
 * 任务登陆
 * @param data
 */
const taskLogin = async <T>(data: any) => await doFetch<T>('/api/Common/agentLogin', 'POST', data);
/**
 * 任务修改
 * @param data
 */
const taskEdit = async <T>(data: any) => await doFetch<T>('/api/Agent/editAgentTask', 'POST', data);
/**
 * 任务新增
 * @param data
 */
const taskAdd = async <T>(data: any) =>
  await doFetch<T>('/api/Agent/releaseAgentTask', 'POST', data);

export default {
  tasklist,
  taskLogin,
  taskEdit,
  taskAdd
};
