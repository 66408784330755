<template>
  <div class="list">
    <div v-for="(v, i) in list" :key="i" @click="navClick(v)">
      <div
        :class="[
          'box disFlex',
          activeNames === v.url ? 'actived text-day background-primary' : 'c3'
        ]"
        v-if="!v.hide"
      >
        <img :src="v.icon" alt="" class="icon c-icons" />
        <div class="text">{{ $t(v.name) }}</div>
        <span class="right-icon" style="{}"></span>
      </div>
      <transition-group name="van-slide-down">
        <template v-for="(item, idx) in v.list">
          <div
            :class="['box disFlex c3 background-button u_m-l12 menu-chlid']"
            :style="computedStyle"
            :key="idx"
            v-show="getShow(activeNames === v.url)"
            @click="navClick(item)"
            v-if="!item.hide"
          >
            <img :src="item.icon" alt="" class="icon c-icons" />
            <div class="text">{{ $t(item.name) }}</div>
            <span class="right-icon"></span>
          </div>
        </template>
      </transition-group>
    </div>
    <div class="loginout" @click="logout">
      {{ $t('logout') }}
    </div>
  </div>
</template>

<script lang="ts">
import {App, Storage} from '@/services';
import {Options, Vue} from 'vue-class-component';
import {keyConst} from '@/config';
import {Collapse, CollapseItem} from 'vant';
@Options({
  components: {
    vanCollapse: Collapse,
    vanCollapseItem: CollapseItem
  }
})
export default class Menu extends Vue {
  activeNames = localStorage.getItem('activedMenu') || '';
  list = [
    {
      icon: require('../../../assets/wap/sidebar/memberList.png'),
      name: 'sideBar__memberList',
      url: '/memberList'
    },
    {
      icon: require('../../../assets/wap/sidebar/ui_report.svg'),
      name: 'ui_report',
      url: '/report',
      list: [
        {
          icon: require('../../../assets/wap/sidebar/sidebar4.png'),
          name: 'sideBar__financialStatements',
          url: '/financial/list'
        },
        {
          icon: require('../../../assets/wap/sidebar/transaction_record.svg'),
          name: 'ui_transaction_record_v2',
          url: '/report/TradeReport'
        },
        {
          icon: require('../../../assets/wap/sidebar/sidebar5.png'),
          name: 'sideBar__commissionReport',
          url: '/commission/list'
        }
      ]
    },
    {
      icon: require('../../../assets/wap/sidebar/askSpread.png'),
      name: 'sideBar__qaLuckyDrawPromotion',
      url: '/askSpread/topicList'
    },
    {
      icon: require('../../../assets/wap/sidebar/sportsInformation.svg'),
      name: 'nav__sportsInformation',
      url: '/sportsInformation'
    },
    {
      icon: require('../../../assets/wap/sidebar/promotionManagement.svg'),
      name: 'ui_text_001',
      url: '/promotionManagement',
      list: [
        {
          icon: require('../../../assets/wap/sidebar/promotionManagement.svg'),
          name: 'ui_mpromote',
          url: '/promotionManagement'
        },
        {
          icon: require('../../../assets/wap/sidebar/sidebar4.png'),
          name: 'agentActivity__title',
          url: '/proxy'
        },
        {
          icon: require('../../../assets/wap/sidebar/ui_group.svg'),
          name: 'ui_group',
          url: '',
          hide: true
        },
        {
          icon: require('../../../assets/wap/sidebar/sidebar7.png'),
          name: 'sideBar__newsManagement',
          url: '/promotion/newsManagement'
        },
        {
          icon: require('../../../assets/wap/sidebar/sidebar8.png'),
          name: 'sideBar__bigVManagement',
          url: '/promotion/bigVManagement'
        },
        {
          icon: require('../../../assets/wap/sidebar/sidebar9.png'),
          name: 'sideBar__matchManagement',
          url: '/promotion/matchManagement'
        },
        {
          icon: require('../../../assets/wap/sidebar/sidebar10.png'),
          name: 'sideBar__leagueManagement',
          url: '/promotion/leagueManagement'
        },
        {
          icon: require('../../../assets/wap/sidebar/picture.png'),
          name: 'promotional_picture_materials',
          url: '/promotion/picMaterial'
        }
      ]
    },
    {
      icon: require('../../../assets/wap/sidebar/sidebar6.png'),
      name: 'sideBar__contactUs',
      url: '/common/contactUs'
    }
  ];
  mounted() {
    this.triggerAnimate();
  }
  navClick(v: {url?: string; list?: any[]}) {
    if (v.list && v.list.length && v.url) {
      console.log(v, 'this.activeNames = v.url;');
      if (this.activeNames !== v.url) {
        this.activeNames = v.url;
        localStorage.setItem('activedMenu', v.url);
      } else {
        this.activeNames = '';
      }
      this.triggerAnimate();
    } else if (v.url) {
      this.$router.replace(v.url);
    }
    App.setIsRouterBack(true);
  }
  logout() {
    Storage.remove(keyConst.token);
    this.$router.replace('/login');
    localStorage.removeItem('activedMenu');
  }
  height = '0rem';
  triggerAnimate() {
    return setTimeout(() => {
      if (this.activeNames === '') {
        this.height = '0rem';
      } else {
        this.height = '2.24rem';
      }
    }, 100);
  }
  getShow(flag: boolean) {
    return flag;
  }
  get computedStyle() {
    return {
      height: this.coumptedHeight,
      visibility: this.activeNames === '' ? 'hidden' : 'visible'
    };
  }
  get coumptedHeight() {
    return this.height;
  }
}
</script>
<style lang="scss" scoped>
.text-day {
  color: $cf;
}
.background-day {
  background-color: $cf;
}
.list {
  padding-bottom: 2.4rem;
  background: $gray;
  .box {
    font-size: 0.64rem;
    padding: 0 0.48rem;
    height: 2.24rem;
    position: relative;
    @extend .clickAction;
    .c-icons {
      margin-right: 0.48rem;
    }
    &.actived {
      .right-icon {
        transform: rotate(90deg);
      }
    }
    &:not(:last-child)::after {
      content: '';
      width: calc(100% - 0.44rem);
      height: 1px;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: scaleY(0.5);
      background: #eee;
    }
    .text {
      flex-grow: 1;
    }
    .right-icon {
      width: 0.64rem;
      height: 0.64rem;
      background: url(../../../assets/wap/right-allow.png) no-repeat 0/0.64rem;
      transition: all 0.3s ease-in-out;
    }
  }
}
.loginout {
  height: 2.24rem;
  line-height: 2.24rem;
  color: $bg;
  font-size: 0.64rem;
  text-align: center;
  margin-top: 0.32rem;
  background: #fff;
}
.menu-chlid {
  transition: all 0.3s ease;
}
</style>
