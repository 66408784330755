
import {keyConst} from '@/config';
import {IUser, IUnread, IAnnoucement, ILastFundChange, ITotalAssets} from '@/model';
import {Server, Storage} from '@/services';
import {Options, Vue} from 'vue-class-component';
import MenuHead from './menuHead.vue';
import MenuList from './menuList.vue';

@Options({
  components: {
    MenuHead,
    MenuList
  }
})
export default class Menu extends Vue {
  jumpList = [
    {
      icon: require('@/assets/wap/money-package.png'),
      name: 'totalAmount',
      url: '',
      value: '-'
    },
    {
      icon: require('@/assets/wap/money.png'),
      name: 'withdrawal',
      url: 'TakeCash',
      value: '-'
    }
  ];
  userName = '';
  msgUnread = '';
  news: IAnnoucement[] = [];
  fund = {};

  async mounted() {
    const storageUser = Storage.get(keyConst.userInfo);
    if (storageUser) {
      const userInfo: IUser = JSON.parse(storageUser) as IUser;
      this.userName = userInfo.userAccount;
    }
    const [unread, runHorse, total, change] = await Promise.all([
      Server.Wap.Common.getMessageUnread<{list: IUnread[]}>(),
      Server.Wap.Common.getRunHorseLightMsg<{list: IAnnoucement[]}>(),
      Server.Wap.Common.getTotalAssets<{list: ITotalAssets}>({game_id: 0, device: 'mobile'}),
      Server.Wap.Common.getLastMoneyChange<{list: ILastFundChange[]}>()
    ]);
    if (!(unread instanceof Error)) {
      this.msgUnread = (
        parseInt(unread.list[0].mail_num) +
        parseInt(unread.list[0].notice_num) +
        parseInt(unread.list[0].news_num)
      ).toString();
    }
    if (!(runHorse instanceof Error)) {
      this.news = runHorse.list;
    }
    if (!(total instanceof Error)) {
      this.jumpList[0].value = parseFloat(total.list.totalAmount.replace(/,/g, '')).toFixed(2);
    }
    if (!(change instanceof Error)) {
      // this.jumpList[1].value = change.list[0].text;
      this.fund = change.list[0];
    }
  }
}
