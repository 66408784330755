import {
  ElTooltip,
  ElTag,
  ElTableColumn,
  ElTable,
  ElSubmenu,
  ElSkeleton,
  ElSelect,
  ElRow,
  ElRadioGroup,
  ElRadioButton,
  ElRadio,
  ElPopover,
  ElPagination,
  ElPageHeader,
  ElOption,
  ElMessage,
  ElMenuItemGroup,
  ElMenuItem,
  ElMenu,
  ElMain,
  ElLoading,
  ElInput,
  ElIcon,
  ElHeader,
  ElFormItem,
  ElForm,
  ElFooter,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElDialog,
  ElContainer,
  ElCol,
  ElCheckbox,
  ElCascaderPanel,
  ElCascader,
  ElCard,
  ElButton,
  ElBadge,
  ElAvatar,
  ElAside,
  ElAlert
} from 'element-plus';
import ElDatePicker from '@/common/web/el-date-picker';

const components = [
  ElTooltip,
  ElTag,
  ElTableColumn,
  ElTable,
  ElSubmenu,
  ElSkeleton,
  ElSelect,
  ElRow,
  ElRadioGroup,
  ElRadioButton,
  ElRadio,
  ElPopover,
  ElPagination,
  ElPageHeader,
  ElOption,
  ElMenuItemGroup,
  ElMenuItem,
  ElMenu,
  ElMain,
  ElInput,
  ElIcon,
  ElHeader,
  ElFormItem,
  ElForm,
  ElFooter,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElDialog,
  ElDatePicker,
  ElContainer,
  ElCol,
  ElCheckbox,
  ElCascaderPanel,
  ElCascader,
  ElCard,
  ElButton,
  ElBadge,
  ElAvatar,
  ElAside,
  ElAlert
];

export default (app: any) => {
  components.forEach(component => {
    app.component(component.name, component);
  });
  const plugins = [ElLoading, ElMessage];
  plugins.forEach(plugin => {
    app.use(plugin, {});
  });
};
