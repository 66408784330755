export interface INews {
  id: number;
  title: string;
  cover: string[];
  keywords: string[];
  content: string;
  abstract: string;
  clicks: number;
  created_at: string;
}

export interface IBigV {
  id: number;
  source_id: number;
  title: string;
  cover: string[];
  keywords: string[];
  content: string;
  abstract: string;
  clicks: number;
  created_at: string;
  language: string;
  league_name: string;
  match_name: string;
}

export interface INewsList {
  from: number;
  to: number;
  total: number;
  data: Array<INews>;
}

export class NewsData {
  static getInitNewsDetail() {
    return {
      id: 0,
      title: '',
      cover: [],
      keywords: [],
      content: '',
      abstract: '',
      clicks: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      created_at: ''
    };
  }
}
