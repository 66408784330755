import doFetch from '../../fetch';
import doFetch2 from '../../fetch2';

const ACCPATH = '/api/acc/front';
const Game = '/api/game/v1';
const Agent = '/api/agent/v1';

/**
 *  example
 * @param code example
 * @param lcode example
 */
const login = async <T>(data: {username: string; password: string}) =>
  doFetch<T>('/api/agent/login', 'POST', data, {
    needToken: false
  });
/**
 *  register
 * @param data
 */
const register = async <T>(data: any) =>
  await doFetch<T>(`${ACCPATH}/v1/Agent/register`, 'POST', data, {
    needToken: false
  });
/**
 * /**
 * 36.2.1 用戶列表

 * @param data
 */
const userlist = async <T>(data: any) => await doFetch<T>(`${Agent}/Users/user_list`, 'POST', data);
/**
 *  getUserinfo
 * @param data
 */
const getUserinfo = async <T>(data: any) =>
  await doFetch<T>(`${ACCPATH}/v1/users/get_user`, 'POST', data);

/**
 *  get platform Config
 * @param data
 */
const getConfig = async <T>(data: any) =>
  await doFetch<T>(`/api/game/v1/frontend_config/get_config`, 'POST', data, {
    needToken: false
  });

/**
 *  get announcement
 * @param data
 */
const getAnnouncement = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/News/get_news_list', 'POST', data);

/**
 *  get announcement detail
 * @param data
 */
const getAnnouncementDetail = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/News/get_news_detail', 'POST', data);

/**
 *  set announcement read
 * type 類型
 * 1:全部已讀 2:標記已讀
 * ids 指定公告id
 * type => 2時必填
 * @param data
 */
const setAnnouncementRead = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/News/set_news', 'POST', data);

/**
 *  get notice list
 * @param data
 */
const getNotice = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Message/notice_list', 'POST', data);

/**
 *  get notice detail
 * @param data
 */
const getNoticetDetail = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Message/notice_detail', 'POST', data);

/**
 *  set notice read
 * type 類型
 * 1:全部已讀 2:標記已讀 3:刪除
 * ids 指定公告id
 * type => 2 , 3 時必填
 * @param data
 */
const setNoticeRead = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Message/set_notice', 'POST', data);

/**
 *  get mail list
 * @param data
 */
const getMail = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Message/receive_send_list', 'POST', data);

/**
 *  get mail detail
 * @param data
 */
const getMailDetail = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Message/receive_send_detail', 'POST', data);

/**
 *  send mail
 * @param data
 */
const sendMail = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Message/send_message', 'POST', data);

/**
 *  get message unread count
 * @param data
 */
const getMessageUnread = async <T>() =>
  doFetch<T>('/api/game/v1/Message/unread', 'POST', undefined);

/**
 * 获取赛事数据列表
 * @param data
 */
const getLeagueList = async <T>(data: {
  page: string | number;
  size?: string | number;
  start_time?: string | number;
  end_time?: string | number;
}) =>
  doFetch2<T>(`/spider/league/getLeagueList`, 'GET', data, {
    needToken: false
  });
/**
 * 赛事筛选列表
 */
const getLeagueCodeName = async <T>() =>
  doFetch2<T>(
    `spider/league/getLeagueLinkage`,
    'GET',
    {},
    {
      needToken: false
    }
  );

/**
 * 获取比赛列表
 * @param data
 */
const getMatchList = async <T>(data: {
  league_code: string;
  page: string | number;
  size?: string | number;
}) =>
  doFetch2<T>(`/spider/match/getMatchList`, 'GET', data, {
    needToken: false
  });
/**
 * 获取比赛详情
 * @param data
 */
const getMatchDetail = async <T>(data: {code: string}) =>
  doFetch2<T>(`/spider/match/getMatchDetails`, 'GET', data, {
    needToken: false
  });
/**
 *
 * @param data 资讯列表
 */
const getAgentNewsList = async <T>(data: {size: number; page: number; league_code: string}) =>
  doFetch2<T>(`/spider/agentNews/getAgentNewsList`, 'GET', data, {
    needToken: false
  });
/**
 *
 * @param data 大v
 */
const getInfotmation = async <T>(data: {size: number; page: number; league_code: string}) =>
  doFetch2<T>(`/spider/agentInformation/getAgentInformationList`, 'GET', data, {
    needToken: false
  });
/**
 * 获取财务报表
 * @param data
 */
const getReport = async <T>(data: any) =>
  doFetch<T>(`/api/agent/v1/Finance/financial_overview`, 'POST', data);

/**
 *
 * 推广管理添加用户
 * @param data
 */
const setMemer = async <T>(data: any) =>
  doFetch<T>(`/api/acc/front/v1/Agent/create_user`, 'POST', data);
/**
 *
 * 未读讯息
 * @param data
 */
const unread = async <T>() => doFetch<T>(`/api/game/v1/Message/unread`, 'POST', {});
/**
 *
 * 红利反水
 * @param data
 */
const getWith = async <T>(data: any) =>
  doFetch<T>(`/api/agent/v1/Finance/get_reward_detail`, 'POST', data);
/**
 * * 红利反水
 * @param data
 */
const todayoverview = async <T>(data: any) =>
  doFetch<T>(`/api/agent/v1/Users/today_overview`, 'POST', data);

export default {
  login,
  register,
  userlist,
  getConfig,
  getUserinfo,
  getAnnouncement,
  getAnnouncementDetail,
  setAnnouncementRead,
  getNotice,
  getNoticetDetail,
  setNoticeRead,
  getMail,
  getMailDetail,
  sendMail,
  getMessageUnread,
  getLeagueList,
  getMatchList,
  getMatchDetail,
  getLeagueCodeName,
  getAgentNewsList,
  getInfotmation,
  getReport,
  setMemer,
  unread,
  getWith,
  todayoverview
};
