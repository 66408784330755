<script>
export default {
  name: 'prompt',
  render() {
    const item = this.$parent;
    const props = this.$attrs;
    const onClick = () => this.$emit('click');
    return (
      <div class="main-menu-header__service--animation" onClick={onClick}>
        {item.nowLang !== 'th' ? <span>{item.$t('ui_prompt')}:</span> : ''}
        <span>{item.$t(props.name)}</span>
      </div>
    );
  }
};
</script>
