
import {Options, Vue} from 'vue-class-component';
import {App} from '@/services';
import {Config} from '@/model';
import {GetUser} from '@/model/tokenGetUser';

import eventBus from '@/web/eventBus';

@Options({})
export default class AppRoot extends Vue {
  unitMap = new Map([
    ['USD', 'ui_dollar_usd'],
    ['VND', 'ui_dollar_vnd'],
    ['THB', 'ui_dollar_thb'],
    ['CNY', 'ui_dollar_cny']
  ]);
  config = {
    currency: {
      id: '',
      code: 'CNY',
      text: ''
    },
    dollarSign: '¥'
  };
  mounted() {
    this.init();
    eventBus.bus$on('langChange', () => {
      this.init();
    });
  }

  beforeDestroy() {
    eventBus.bus$off('langChange');
  }
  async init() {
    // 取得設定
    await Promise.all([
      Config.action({
        device: 'pc',
        keys: [
          'btn_help',
          'game_company',
          'ui_add_agent',
          'ui_cd_sys',
          'ui_contact_cs',
          'ui_dir_reg_recommended_id_mobile',
          'ui_dir_reg_recommended_id_web',
          'ui_direct_register_code',
          'ui_football_btn',
          'ui_login_no_verify_code',
          'ui_login_page',
          'ui_member_reg',
          'ui_msg_allow_c2b',
          'ui_register_no_verify_code',
          'ui_registration_contact_info',
          'ui_try_free',
          'ui_wap_page_guide_download_app_cover_page',
          'ui_withdraw_time',
          'withdrawals_check_email',
          'withdrawals_check_funds_password',
          'withdrawals_check_phone_number',
          'currency',
          'vip',
          'dollarSign'
        ]
      }),
      GetUser.action()
    ]);
    this.config = {...this.config, ...App.getConfig()};
  }
}
