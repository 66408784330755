import {createApp, defineComponent} from 'vue';
import mitt from 'mitt';
/** evenetBus
 *  @/ listen to an event
emitter.on('foo', e => console.log('foo', e) )

// listen to all events
emitter.on('*', (type, e) => console.log(type, e) )

// fire an event
emitter.emit('foo', { a: 'b' })

// working with handler references:
function onFoo() {}
emitter.on('foo', onFoo)   // listen
emitter.off('foo', onFoo)  // unlisten
 */
const emitter = mitt();
const bus: {
  bus$on: any;
  bus$off: any;
  bus$emit: any;
  bus$clear: any;
} = {
  bus$on: emitter.on,
  bus$off: emitter.off,
  bus$emit: emitter.emit,
  bus$clear: emitter.all.clear
};

const eventBus = defineComponent({...bus});

export default eventBus;
