import doFetch from '../../fetch';
const ACCPATH = '/api/acc/front';
const Game = '/api/game/v1';
const Agent = '/api/agent/v1';

/**
 *  login
 * @param data
 */
const login = async <T>(data: any) =>
  doFetch<T>(`${ACCPATH}/v1/Agent/login`, 'POST', data, {
    needToken: false
  });
/**
 *  register
 * @param data
 */
const register = async <T>(data: any) =>
  await doFetch<T>(`${ACCPATH}/v1/Agent/register`, 'POST', data, {
    needToken: false
  });
/**
 * /**
 *  forgetps
 * 忘記帳號 - 輸入手機號

 * @param data
 */
const sendaforgetacc = async <T>(data: any) =>
  await doFetch<T>(`${Game}verification/send_forget_acc`, 'POST', data);
/**
/**
 * /**
 *  forgetps
 * 忘记密码 - 发送验证码

 * @param data
 */
const forgetps = async <T>(data: any) =>
  await doFetch<T>(`${Agent}/verification/send_forget_password`, 'POST', data);
/**
 * /**
 *  checkforgetps
 * 忘记密码 - 确认验证码

 * @param data
 */
const checkforgetps = async <T>(data: any) =>
  await doFetch<T>(`${Game}/verification/check_forget_password`, 'POST', data);
/**
 * /**
 * 36.2.1 用戶列表

 * @param data
 */
const userlist = async <T>(data: any) => await doFetch<T>(`${Agent}/Users/user_list`, 'POST', data);
/**
/**
 *  get announcement detail
 * @param data
 */
const getreportlist = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Lucky_money/get_report_list', 'POST', data);

/**
 *  getUserinfo
 * @param data
 */
const getUserinfo = async <T>(data: any) =>
  await doFetch<T>(`${ACCPATH}/v1/users/get_user`, 'POST', data);

/**
 *  editUserinfo
 * @param data
 */
const editUserinfo = async <T>(data: any) =>
  await doFetch<T>(`${ACCPATH}/v1/users/edit_user_info`, 'POST', data);

/**
 *  get platform Config
 * @param data
 */
const getConfig = async <T>(data: any) =>
  doFetch<T>(`/api/game/v1/frontend_config/get_config`, 'POST', data, {
    needToken: false
  });

/**
 *  get announcement
 * @param data
 */
const getAnnouncement = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/News/get_news_list', 'POST', data);

/**
 *  get announcement detail
 * @param data
 */
const getAnnouncementDetail = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/News/get_news_detail', 'POST', data);

/**
 *  set announcement read
 * type 類型
 * 1:全部已讀 2:標記已讀
 * ids 指定公告id
 * type => 2時必填
 * @param data
 */
const setAnnouncementRead = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/News/set_news', 'POST', data);

/**
 *  get notice list
 * @param data
 */
const getNotice = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Message/notice_list', 'POST', data);

/**
 *  get notice detail
 * @param data
 */
const getNoticetDetail = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Message/notice_detail', 'POST', data);

/**
 *  set notice read
 * type 類型
 * 1:全部已讀 2:標記已讀 3:刪除
 * ids 指定公告id
 * type => 2 , 3 時必填
 * @param data
 */
const setNoticeRead = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Message/set_notice', 'POST', data);

/**
 *  get mail list
 * @param data
 */
const getMail = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Message/receive_send_list', 'POST', data);

/**
 *  get mail detail
 * @param data
 */
const getMailDetail = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Message/receive_send_detail', 'POST', data);

/**
 *  send mail
 * @param data
 */
const sendMail = async <T>(data: any) =>
  doFetch<T>('/api/game/v1/Message/send_message', 'POST', data);

/**
 *  get message unread count
 * @param data
 */
const getMessageUnread = async <T>() =>
  doFetch<T>('/api/game/v1/Message/unread', 'POST', undefined);

/**
 *  get run horse light message
 * @param data
 */
const getRunHorseLightMsg = async <T>() =>
  doFetch<T>('/api/game/v1/News/get_news_list', 'POST', undefined);

/**
 *  get total assets
 * @param data
 */
const getTotalAssets = async <T>(data: any) =>
  doFetch<T>('/api/acc/front/v1/game/get_user_specific_game_wallet', 'POST', data);

/**
 *  get last money change
 * @param data
 */
const getLastMoneyChange = async <T>() =>
  doFetch<T>('/api/acc/front/v1/fund/get_last_fund_orders', 'POST', undefined);

export default {
  login,
  register,
  sendaforgetacc,
  forgetps,
  checkforgetps,
  userlist,
  getConfig,
  getUserinfo,
  editUserinfo,
  getAnnouncement,
  getAnnouncementDetail,
  setAnnouncementRead,
  getNotice,
  getNoticetDetail,
  setNoticeRead,
  getMail,
  getMailDetail,
  sendMail,
  getreportlist,
  getMessageUnread,
  getRunHorseLightMsg,
  getTotalAssets,
  getLastMoneyChange
};
