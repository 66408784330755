declare const PROCESS_DEV_ENV: boolean;

const environment =
  process.env.NODE_ENV === 'production'
    ? {
        mainlandUrl: 'https://www.craw248000.com',
        '918Url': 'https://en.aia918.com',
        '998Url': 'https://zht-admin.aia998.com'
      }
    : {
        mainlandUrl: 'https://www.craw248000.com',
        '918Url': 'https://en.aia918.com',
        '998Url': 'http://192.168.1.6:9605'
      };
const basic = {
  socketUrl() {
    const host = window.location.hostname;
    const {port} = window.location;
    const {protocol} = window.location;
    const socketUrl = `${protocol === 'https' ? 'wss' : 'ws'}://${host}:${port}`;
    return PROCESS_DEV_ENV ? 'ws://192.168.10.188:7801' : socketUrl;
  },
  version: '1.0.0',
  baseUrl: '',
  appKey: '',
  ...environment
};

export default basic;
