/* eslint-disable @typescript-eslint/no-explicit-any */
import base64 from 'hi-base64';
import {basic} from '@/config';
import i18n from '@i18n';
import App from './app';
import Navi from './navi';

export interface IServerResult<T = any> {
  status: number;
  msg: string;
  data: T;
}

export interface IPageServerResult<T> {
  current_page: number;
  data: T;
  from: number;
  to: number;
  total: number;
  per_page: number;
}

interface IFetchOptions {
  timeout?: number;
  needToken?: boolean;
  needSign?: boolean;
}

/**
 * jwt 編碼
 * @param {string} str 要編碼的字串
 */
export const jwtEncode = (str: string) =>
  encodeURIComponent(base64.encode(encodeURIComponent(JSON.stringify(str))));

const typeDict: {[k: string]: string} = {
  GET: 'GET',
  POST: 'POST',
  'POST-JSON': 'POST'
};

const doFetch = async <T>(
  url = '',
  type: 'POST' | 'GET' | 'POST-JSON' = 'POST',
  dataParam: any = {},
  options: IFetchOptions = {}
): Promise<T | Error> => {
  const timeout = options && options.timeout !== undefined ? options.timeout : 10000;
  const needToken = options && options.needToken !== undefined ? options.needToken : true;

  const data: any = Object.assign(dataParam, {
    platform: App.Platform.OS(),
    version: basic.version
  });

  const requestConfigObject: RequestInit = {
    credentials: 'omit',
    method: typeDict[type],
    mode: 'cors',
    headers: {}
  };

  let token = '';
  if (needToken) {
    const user = App.getUserinfo();

    if (user) {
      token = user.token;
    } else {
      await Navi.gotoLogin();
      return new Error('unauthorized');
    }
    requestConfigObject.headers = Object.assign(requestConfigObject.headers, {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    });
  }

  let newUrl = `${basic.baseUrl}${url}`;

  const language = i18n.global.locale;
  requestConfigObject.headers = Object.assign(requestConfigObject.headers, {
    'Accept-Language': language
  });

  if (type === 'GET') {
    if (data) {
      const dataKeys = Object.keys(data);
      const queryStr = dataKeys.map(key => `${key}=${data[key]}`).join('&');
      if (queryStr && queryStr.length > 0) {
        if (newUrl.indexOf('?') > -1) {
          newUrl = `${newUrl}&${queryStr}`;
        } else {
          newUrl = `${newUrl}?${queryStr}`;
        }
      }
    }
  } else if (type === 'POST') {
    const formData = new FormData();
    const dataKeys = Object.keys(data);
    for (let i = 0; i < dataKeys.length; i += 1) {
      const key = dataKeys[i];
      const value = data[key].toString();
      formData.append(key, value);
    }
    requestConfigObject.body = formData;
    requestConfigObject.headers = Object.assign(requestConfigObject.headers, {
      'content-type': 'multipart/form-data'
    });
  } else if (type === 'POST-JSON') {
    requestConfigObject.body = data ? JSON.stringify(data) : data;
    requestConfigObject.headers = Object.assign(requestConfigObject.headers, {
      'content-type': 'application/json'
    });
  }

  try {
    const result: Response = (await Promise.race([
      fetch(newUrl, requestConfigObject),
      new Promise((resolve, reject) => {
        setTimeout(
          () => {
            reject(new Error('timeout'));
          },
          timeout === 0 ? 8000 : timeout
        );
      })
    ])) as Response;

    const t = await result.text();

    if (result.status !== 200) {
      console.log('HTTP ERROR:', result.status, t);
      if (result.status === 502) {
        return new Error('server 502');
      }
      return new Error('server unexpected');
    }
    const json = JSON.parse(t) as IServerResult;
    if (json.status !== 200) {
      if (json.status === 10053) {
        // login time out
        setTimeout(async () => {
          Navi.gotoLogin();
        }, 500);
        return new Error(json.msg || i18n.global.t('tip_hint_timeout'));
      }

      return new Error(json.data.message || 'Server Error');
    }
    return json.data as T;
  } catch (error) {
    console.log('ERROR:', error);

    if (error.message === 'request_timeout') {
      return error;
    }
    return new Error('Server Exception');
  }
};

const fetchWraper = async <T>(
  url = '',
  type: 'POST' | 'GET' | 'POST-JSON' = 'POST',
  dataParam: any = {},
  options: IFetchOptions = {}
): Promise<T | Error> => {
  const result = await doFetch<T>(url, type, dataParam, options);
  // console.log('HTTP ', url, result);
  return result;
};

export default fetchWraper;
