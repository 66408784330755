const set = async (key: string, value: string | unknown) => {
  try {
    const str: string = typeof value === 'string' ? value : JSON.stringify(value);
    await localStorage.setItem(key, str);
    return true;
  } catch (e) {
    console.error('saving error:', e);

    return false;
  }
};

const get = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error('error reading value:', e);

    return null;
  }
};

const clear = () => {
  localStorage.clear();
};
const remove = (key: string) => {
  localStorage.removeItem(key);
};

export default {
  set,
  get,
  clear,
  remove
};
