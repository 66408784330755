import {IUser, IConfig} from '@/model';
import {keyConst} from '@/config';
import Storage from './storage';

const global: {
  isLogedIn: boolean;
  userinfo: IUser | undefined;
  // register: Iregister | undefined;
  /**
   * used to detect whether user is pressing back button
   * so that we can apply appropriate animation
   */
  isRouterBack: boolean;
  userConfig: IConfig | undefined;
} = {
  isLogedIn: false,
  userinfo: undefined,
  isRouterBack: false,
  userConfig: undefined
};

const updateGlobal = () => {
  const userinfoStr = Storage.get(keyConst.userInfo);
  const userConfigStr = Storage.get(keyConst.userConfig);
  if (userConfigStr) {
    global.userConfig = {...JSON.parse(userConfigStr)};
  } else {
    global.userConfig = undefined;
  }
  if (userinfoStr === null) {
    global.isLogedIn = Storage.get('token') ? true : false;
    global.userinfo = undefined;
  } else {
    global.isLogedIn = true;
    global.userinfo = JSON.parse(userinfoStr);
  }
};

const getIsLogedIn = () => global.isLogedIn;

// const register = () => global.register;

const getConfig = () => global.userConfig;

const getUserinfo = () => global.userinfo;

const getIsRouterBack = () => global.isRouterBack;

const setIsRouterBack = (b: boolean) => (global.isRouterBack = b);

const Platform = {
  OS() {
    if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
      return 'iOS';
    }
    if (navigator.userAgent.match(/android/i)) {
      return 'Android';
    }
    return 'unknown';
  }
};

export default {
  updateGlobal,
  getIsLogedIn,
  getConfig,
  getUserinfo,
  getIsRouterBack,
  setIsRouterBack,
  // register,
  Platform
};
