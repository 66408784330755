<template>
  <div v-loading="walletLoading">
    <div class="gy_transfer__info gy_list-detail__form">
      <el-row
        :gutter="20"
        type="flex"
        align="center"
        :class="['j_bg--primary j_txt--base u_p--l10 u_p--r10', $attrs.class]"
        style="border-radius: 8px"
      >
        <el-col :span="hide.includes('lock') ? 20 : 10" v-if="!hide.includes('center')">
          <div class="gy_form__field u_p--b10">
            <div class="gy_form__title">{{ $t('ui_center_wallet') }} :</div>
            <div class="gy_transfer__info__text--account">
              <count-to
                :duration="2000"
                ref="counts"
                :startVal="0 === +wallet.centerWallet ? 100 : 0"
                :endVal="Math.floor(+wallet.centerWallet * 100) / 100"
                :decimals="decimals"
                :class="['j_txt--base', $attrs.class]"
                style="font-size: 30px"
              ></count-to>
            </div>
          </div>
        </el-col>
        <el-col :span="hide.includes('center') ? 20 : 10" v-if="!hide.includes('lock')">
          <div class="gy_form__field u_p--b10">
            <div class="gy_form__title u_m--r5">{{ $t('ui_lock_wallet') }} :</div>
            <div class="gy_transfer__info__text--account">
              <count-to
                :duration="2000"
                ref="counts"
                :startVal="0 === +wallet.lockBalance ? 100 : 0"
                :endVal="Math.floor(+wallet.lockBalance * 100) / 100"
                :decimals="decimals"
                class="j_txt--base"
                style="font-size: 30px"
              ></count-to>
            </div>
          </div>
        </el-col>
        <el-col :span="4" class="u_p--t10 u_p--b10">
          <div class="one_click_recycling u_c-pointer">
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 16 16"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              @click="retrieve"
              v-if="showIcon"
            >
              <g id="icon/16/刷新" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g
                  id="编组"
                  mask="url(#mask-2)"
                  :stroke="iconColor || '#FFFFFF'"
                  stroke-linecap="round"
                  stroke-width="1.5"
                >
                  <g
                    transform="translate(8.500000, 7.500000) rotate(-45.000000) translate(-8.500000, -7.500000) translate(1.000000, 1.000000)"
                  >
                    <path
                      d="M12.9545455,6.47727273 C12.9545455,2.89997378 10.0545717,0 6.47727273,0 C2.89997378,0 0,2.89997378 0,6.47727273 C0,10.0545717 2.89997378,12.9545455 6.47727273,12.9545455 C8.17540146,12.9545455 9.72090439,12.3010767 10.8760335,11.2318872"
                      id="路径"
                    ></path>
                    <polyline
                      id="路径-22"
                      stroke-linejoin="round"
                      transform="translate(12.954545, 6.136364) rotate(-315.000000) translate(-12.954545, -6.136364) "
                      points="11.9318182 7.15909091 13.9772727 7.15909091 13.9772727 5.11363636"
                    ></polyline>
                  </g>
                </g>
              </g>
            </svg>
            <button
              v-else
              type="button"
              class="c_button--recover j_btn-txt--base btnbox3"
              @click="retrieve"
              v-loading="buttonLoading && isRetrieve"
              :disabled="buttonLoading && isRetrieve"
            >
              {{ $t('btn_refresh_v2') }}
            </button>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {transfer} from '@/services/server/wap/transfer';
import {IWalletResult} from '@/model/transfer';
import {throttle} from 'throttle-debounce';
import {IWallet} from '@/model/transfer';
import countTo from '@/common/web/countTo/index.vue';
import {Prop} from 'vue-property-decorator';
import eventBus from '@/web/eventBus';
import {ElRow, ElCol, ElMessage} from 'element-plus';

@Options({
  components: {
    countTo,
    ElRow,
    ElCol
  }
})
export default class WalletView extends Vue {
  money = '';
  isTransfer = false;
  isRetrieve = false;
  blockLoading = false;
  decimals = 2;
  btnLoading = false;
  walletLoading = false;

  wallet = {};

  isloading = false;
  @Prop()
  isFetch!: boolean;
  @Prop({default: []})
  hide!: string[];
  @Prop()
  showIcon!: boolean;
  @Prop()
  iconColor!: string;

  handleWallet(wallet: IWallet) {
    this.wallet = wallet;
  }
  async retrieve() {
    this.isRetrieve = true;
    this.isTransfer = false;
    this.btnLoading = true;
    this.$nextTick(() => {
      this.handleRefreshHandleRefresh();
    });
    setTimeout(() => {
      this.btnLoading = false;
      this.isRetrieve = false;
    }, 1000);
  }

  handleRefreshHandleRefresh = () => {
    console.log('123');
  };
  mounted() {
    this.handleRefreshHandleRefresh = throttle(5000, true, this.handleRefresh);
    const getWallet = localStorage.getItem('getWallet') === 'false';
    eventBus.bus$on('fetchWallet', (val: boolean) => {
      if (val) {
        this.handleRefreshHandleRefresh();
      }
    });
    if (getWallet) {
      this.handleRefreshHandleRefresh();
    }
    if (!this.showIcon) {
      this.showIcon = false;
    }
  }

  async handleRefresh() {
    //do Refresh
    this.isloading = true;
    localStorage.setItem('getWallet', String(this.isloading));
    const data = await transfer.read<IWalletResult>({
      // eslint-disable-next-line
      game_id: 0
    });
    this.isloading = false;
    localStorage.setItem('getWallet', String(this.isloading));
    if (data instanceof Error) {
      ElMessage({
        type: 'error',
        message: data.message
      });
    } else {
      this.wallet = data.list;
    }
    this.$emit('finish-wallet', this.wallet);
    return;
  }
}
</script>

<style lang="scss" scoped>
.gy_transfer__info {
  // padding: 0 10px;
  // 多语后会挤压变形
}
.j_bg--primary {
  background-color: #1f55f0;
}
.j_txt--base {
  color: #fff;
}
.u_p--l10 {
  padding-left: 10px;
}
.u_p--r10 {
  padding-right: 10px;
}
.u_m--r5 {
  margin-right: 5px;
}
.gy_list-detail__form .gy_form__field {
  display: table-row;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.gy_list-detail__form .gy_form__title {
  padding-right: 20px;
  font-size: 14px;
  width: 80px;
  text-align: left;
}
.gy_list-detail__form .gy_form__field {
  > div {
    display: table-cell;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 7px;
    white-space: nowrap;
  }
  > div:not(.gy_form__title) {
    width: 100%;
  }
}
.one_click_recycling {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.gy_transfer__info [class*='c_button'][class*='--recover'] {
  background-color: #1f55f0;
  width: 140px;

  cursor: pointer;
}
.btnbox3 {
  border-radius: 5px;
  height: 40px;
  border: 1px solid #fff;
  margin: 0 auto;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}
.u_c-pointer {
  cursor: pointer;
}
</style>
