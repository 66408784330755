
import {Options, Vue} from 'vue-class-component';
import {transfer} from '@/services/server/wap/transfer';
import {IWalletResult} from '@/model/transfer';
import {throttle} from 'throttle-debounce';
import {IWallet} from '@/model/transfer';
import countTo from '@/common/web/countTo/index.vue';
import {Prop} from 'vue-property-decorator';
import eventBus from '@/web/eventBus';
import {ElRow, ElCol, ElMessage} from 'element-plus';

@Options({
  components: {
    countTo,
    ElRow,
    ElCol
  }
})
export default class WalletView extends Vue {
  money = '';
  isTransfer = false;
  isRetrieve = false;
  blockLoading = false;
  decimals = 2;
  btnLoading = false;
  walletLoading = false;

  wallet = {};

  isloading = false;
  @Prop()
  isFetch!: boolean;
  @Prop({default: []})
  hide!: string[];
  @Prop()
  showIcon!: boolean;
  @Prop()
  iconColor!: string;

  handleWallet(wallet: IWallet) {
    this.wallet = wallet;
  }
  async retrieve() {
    this.isRetrieve = true;
    this.isTransfer = false;
    this.btnLoading = true;
    this.$nextTick(() => {
      this.handleRefreshHandleRefresh();
    });
    setTimeout(() => {
      this.btnLoading = false;
      this.isRetrieve = false;
    }, 1000);
  }

  handleRefreshHandleRefresh = () => {
    console.log('123');
  };
  mounted() {
    this.handleRefreshHandleRefresh = throttle(5000, true, this.handleRefresh);
    const getWallet = localStorage.getItem('getWallet') === 'false';
    eventBus.bus$on('fetchWallet', (val: boolean) => {
      if (val) {
        this.handleRefreshHandleRefresh();
      }
    });
    if (getWallet) {
      this.handleRefreshHandleRefresh();
    }
    if (!this.showIcon) {
      this.showIcon = false;
    }
  }

  async handleRefresh() {
    //do Refresh
    this.isloading = true;
    localStorage.setItem('getWallet', String(this.isloading));
    const data = await transfer.read<IWalletResult>({
      // eslint-disable-next-line
      game_id: 0
    });
    this.isloading = false;
    localStorage.setItem('getWallet', String(this.isloading));
    if (data instanceof Error) {
      ElMessage({
        type: 'error',
        message: data.message
      });
    } else {
      this.wallet = data.list;
    }
    this.$emit('finish-wallet', this.wallet);
    return;
  }
}
