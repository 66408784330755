
import {App, Storage} from '@/services';
import {Options, Vue} from 'vue-class-component';
import {keyConst} from '@/config';
import {Collapse, CollapseItem} from 'vant';
@Options({
  components: {
    vanCollapse: Collapse,
    vanCollapseItem: CollapseItem
  }
})
export default class Menu extends Vue {
  activeNames = localStorage.getItem('activedMenu') || '';
  list = [
    {
      icon: require('../../../assets/wap/sidebar/memberList.png'),
      name: 'sideBar__memberList',
      url: '/memberList'
    },
    {
      icon: require('../../../assets/wap/sidebar/ui_report.svg'),
      name: 'ui_report',
      url: '/report',
      list: [
        {
          icon: require('../../../assets/wap/sidebar/sidebar4.png'),
          name: 'sideBar__financialStatements',
          url: '/financial/list'
        },
        {
          icon: require('../../../assets/wap/sidebar/transaction_record.svg'),
          name: 'ui_transaction_record_v2',
          url: '/report/TradeReport'
        },
        {
          icon: require('../../../assets/wap/sidebar/sidebar5.png'),
          name: 'sideBar__commissionReport',
          url: '/commission/list'
        }
      ]
    },
    {
      icon: require('../../../assets/wap/sidebar/askSpread.png'),
      name: 'sideBar__qaLuckyDrawPromotion',
      url: '/askSpread/topicList'
    },
    {
      icon: require('../../../assets/wap/sidebar/sportsInformation.svg'),
      name: 'nav__sportsInformation',
      url: '/sportsInformation'
    },
    {
      icon: require('../../../assets/wap/sidebar/promotionManagement.svg'),
      name: 'ui_text_001',
      url: '/promotionManagement',
      list: [
        {
          icon: require('../../../assets/wap/sidebar/promotionManagement.svg'),
          name: 'ui_mpromote',
          url: '/promotionManagement'
        },
        {
          icon: require('../../../assets/wap/sidebar/sidebar4.png'),
          name: 'agentActivity__title',
          url: '/proxy'
        },
        {
          icon: require('../../../assets/wap/sidebar/ui_group.svg'),
          name: 'ui_group',
          url: '',
          hide: true
        },
        {
          icon: require('../../../assets/wap/sidebar/sidebar7.png'),
          name: 'sideBar__newsManagement',
          url: '/promotion/newsManagement'
        },
        {
          icon: require('../../../assets/wap/sidebar/sidebar8.png'),
          name: 'sideBar__bigVManagement',
          url: '/promotion/bigVManagement'
        },
        {
          icon: require('../../../assets/wap/sidebar/sidebar9.png'),
          name: 'sideBar__matchManagement',
          url: '/promotion/matchManagement'
        },
        {
          icon: require('../../../assets/wap/sidebar/sidebar10.png'),
          name: 'sideBar__leagueManagement',
          url: '/promotion/leagueManagement'
        },
        {
          icon: require('../../../assets/wap/sidebar/picture.png'),
          name: 'promotional_picture_materials',
          url: '/promotion/picMaterial'
        }
      ]
    },
    {
      icon: require('../../../assets/wap/sidebar/sidebar6.png'),
      name: 'sideBar__contactUs',
      url: '/common/contactUs'
    }
  ];
  mounted() {
    this.triggerAnimate();
  }
  navClick(v: {url?: string; list?: any[]}) {
    if (v.list && v.list.length && v.url) {
      console.log(v, 'this.activeNames = v.url;');
      if (this.activeNames !== v.url) {
        this.activeNames = v.url;
        localStorage.setItem('activedMenu', v.url);
      } else {
        this.activeNames = '';
      }
      this.triggerAnimate();
    } else if (v.url) {
      this.$router.replace(v.url);
    }
    App.setIsRouterBack(true);
  }
  logout() {
    Storage.remove(keyConst.token);
    this.$router.replace('/login');
    localStorage.removeItem('activedMenu');
  }
  height = '0rem';
  triggerAnimate() {
    return setTimeout(() => {
      if (this.activeNames === '') {
        this.height = '0rem';
      } else {
        this.height = '2.24rem';
      }
    }, 100);
  }
  getShow(flag: boolean) {
    return flag;
  }
  get computedStyle() {
    return {
      height: this.coumptedHeight,
      visibility: this.activeNames === '' ? 'hidden' : 'visible'
    };
  }
  get coumptedHeight() {
    return this.height;
  }
}
