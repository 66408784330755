import doFetch from '../../fetch';
const ACCPATH = '/api/acc/front';

/** 7.2.8 取得用戶遊戲餘額 */
// read: ApiFn('game/get_user_specific_game_wallet'),
/**
 *   7.2.8 取得用戶遊戲餘額
 * @param data
 */
const actions = {
  read: async <T>(data: object) =>
    await doFetch<T>(`${ACCPATH}/v1/game/get_user_specific_game_wallet`, 'POST', data)
};

export const transfer = actions;
