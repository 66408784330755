/* eslint-disable @typescript-eslint/no-explicit-any */
import base64 from 'hi-base64';
import {basic, router} from '@/config';
import i18n from '@i18n';
import App from './app';
import Navi from './navi';
import {IFetchOptions, IServerResult} from '@/model';

/**
 * jwt 編碼
 * @param {string} str 要編碼的字串
 */
export const jwtEncode = (str: string) =>
  encodeURIComponent(base64.encode(encodeURIComponent(JSON.stringify(str))));

const isMobile = () => {
  const mobileDevices = [
    'Android',
    'webOS',
    'iPhone',
    'iPad',
    'iPod',
    'BlackBerry',
    'Windows Phone'
  ];
  return mobileDevices.some(e => navigator.userAgent.match(e));
};

const encryptData = (data: any = {}, token = '') => {
  const datas = [];
  const lang = localStorage.getItem('language') || window.defaultLangCode;
  const LANGMAP = new Map([
    ['zh', 'zh_cn'],
    ['zh-CN', 'zh_cn'],
    ['en', 'en_us'],
    ['vn', 'vn'],
    ['th', 'th_th']
  ]);
  const cacheUserInfo = JSON.parse(localStorage.getItem('user_info') || '{}');
  // const config = store.state.server.config
  data.lang = LANGMAP.get(lang || 'th');
  data['platform_code'] = cacheUserInfo.platform_code;

  data.device = typeof data.device != 'undefined' ? data.device : isMobile() ? '4' : '0';
  console.log(data.device);

  if (token.length > 0) {
    datas.push(token);
  }
  datas.push(jwtEncode(data));
  return datas.join('.');
};

const typeDict: {[k: string]: string} = {
  GET: 'GET',
  POST: 'POST',
  'POST-JSON': 'POST'
};

const doFetch = async <T>(
  url = '',
  type: 'POST' | 'GET' | 'POST-JSON' = 'POST',
  dataParam: any = {},
  options: IFetchOptions = {}
): Promise<T | Error> => {
  const timeout = options && options.timeout !== undefined ? options.timeout : 10000;
  const needToken = options && options.needToken !== undefined ? options.needToken : true;
  console.log('action url:', url, 'params:', dataParam);

  const data: any = Object.assign(dataParam, {});

  const requestConfigObject: RequestInit = {
    credentials: 'omit',
    method: typeDict[type],
    mode: 'cors',
    headers: {}
  };

  let token = '';
  if (needToken) {
    const user = App.getUserinfo() || {token: localStorage.getItem('token') || ''};

    if (user) {
      token = user.token;
    } else {
      await Navi.gotoLogin();
      return new Error('unauthorized');
    }
  }

  let newUrl = `${basic.baseUrl}${url}`;

  const language = i18n.global.locale;
  requestConfigObject.headers = Object.assign(requestConfigObject.headers, {
    'Accept-Language': language
  });

  if (type === 'GET') {
    if (data) {
      requestConfigObject.headers = Object.assign(requestConfigObject.headers, {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      });
      const dataKeys = Object.keys(data);
      const queryStr = dataKeys.map(key => `${key}=${data[key]}`).join('&');
      if (queryStr && queryStr.length > 0) {
        if (newUrl.indexOf('?') > -1) {
          newUrl = `${newUrl}&${queryStr}`;
        } else {
          newUrl = `${newUrl}?${queryStr}`;
        }
      }
    }
  } else if (type === 'POST') {
    requestConfigObject.body = `data=${encryptData(data, token)}`;
    requestConfigObject.headers = {
      'content-type': 'application/x-www-form-urlencoded'
    };
  } else if (type === 'POST-JSON') {
    requestConfigObject.body = data ? JSON.stringify(data) : data;
    requestConfigObject.headers = {
      'content-type': 'application/json'
    };
  }

  try {
    const result: Response = (await Promise.race([
      fetch(newUrl, requestConfigObject),
      new Promise((resolve, reject) => {
        setTimeout(
          () => {
            reject(new Error('timeout'));
          },
          timeout === 0 ? 8000 : timeout
        );
      })
    ])) as Response;

    const t = await result.text();

    if (result.status !== 200) {
      console.log('HTTP ERROR:', result.status, t);
      if (result.status === 502) {
        return new Error('server 502');
      }
      return new Error('server unexpected');
    }
    const json = JSON.parse(t) as IServerResult;
    if (json.result !== 0) {
      const logTimeoutCode = ['X1004', 'T1004'];
      if (json.result === 1 && logTimeoutCode.indexOf(json.data.error) > -1) {
        // login time out
        router.replace('/login');
        console.log('router: ', router);
        return new Error(json.msg || i18n.global.t('tip_hint_timeout'));
      }
      const e = new Error(json.data.message || 'Server Error');
      e.name = json.data.error;
      return e;
    }
    if (json.pagination) {
      json.data.pagination = json.pagination;
    }
    return json.data as T;
  } catch (error) {
    console.log('ERROR:', error);

    if (error.message === 'request_timeout') {
      return error;
    }
    return new Error('Server Exception');
  }
};

const fetchWraper = async <T>(
  url = '',
  type: 'POST' | 'GET' | 'POST-JSON' = 'POST',
  dataParam: any = {},
  options: IFetchOptions = {}
): Promise<T | Error> => {
  const result = await doFetch<T>(url, type, dataParam, options);
  // console.log('HTTP ', url, result);
  return result;
};

export default fetchWraper;
