
import {Options, Vue} from 'vue-class-component';
import Popup from '../popup/index.vue';
import Menu from './menu.vue';

@Options({
  props: {
    title: {
      type: String,
      default: ''
    },
    /**
     * blue or white
     */
    theme: {
      type: String,
      default: 'blue'
    },
    /**
     * back or menu
     */
    left: {
      type: String,
      default: 'back'
    },
    right: {
      type: String,
      default: 'back'
    }
  },
  components: {
    Popup,
    Menu
  },
  methods: {
    asdas() {
      console.log(123);
    }
  }
})
export default class NavBar extends Vue {
  text = '123121233';

  showMenu = false;

  goBack() {
    this.$router.back();
  }
  goHome() {
    this.$router.push({path: '/'});
  }
}
