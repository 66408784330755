import {userCard} from '@/services/server/wap/bank';
export interface ICard {
  cardId: string;
  userAccount: string;
  bindingBank: string;
  bankCode: string;
  accountName: string;
  accountNumber: string;
  account_name: string;
  account_number: string;
  status: string;
  bindingTime: string;
  updateTime: string;
}

export interface IBankCard {
  bankCode: string;
  bankId: string;
  bankName: string;
  text?: string;
  dom?: string;
}

export interface IBindingCard {
  bindingBank: string;
  accountNumber: string;
  bankCode: string;
  text?: string;
  dom?: string;
  cardId: string;
}

export interface IMessage {
  message: string;
  error?: string;
}

export interface ICardListResult {
  list: Array<IBankCard>;
}

export interface ICardResult {
  cards: number;
  list: Array<ICard>;
  message: string;
}

export class GetCards {
  static async read() {
    const res = await userCard.read<ICardResult>({});
    console.log('res: ', res);
    if (res instanceof Error) {
      return res;
    }
    return res;
  }
}
